import React, { Fragment } from 'react';
import moment from 'moment';
import _ from 'lodash';
// import cloneDeep from 'lodash.clonedeep';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Tag, Form, DatePicker, Select } from 'antd';
import { StyledForm, StyledButton } from '../../../../AMM/CSS/style';
import themeSettings from '../../../../AMM/Utils/themeSettings.json';
import { getTranslationCache } from '../../../../../selectors/language';
import { ErrorComponent, NoRecordComponent } from '../../Utils';
import ReactEcharts from '../../Echarts/Echarts';
import localStorage from '../../../../../utils/localStorage';
import { makeSelectThemeing } from '../../../../../selectors/theme';
import { defaultRefreshTime } from '../../constant.json';
import { getCurrentTimezone } from '../../../../../selectors/layout';
import axios from 'axios';
const { Option } = Select;
const { RangePicker } = DatePicker;
const layout = {
  wrapperCol: {
    offset: 9
  }
};

class LineWithSelection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      option: this.getOption(),
      data: [],
      translationCache: props.translationCache || [],
      refreshDateTime: moment(),
      errorInAPI: false,
      noDataInDB: false,
      checked: false,
      graphTypeList: [
        { Code: 'line', Name: 'Line' },
        { Code: 'scatter', Name: 'Scatter' }
      ],
      formObject: {},
      selectedDate: {
        startDate: moment('2023-11-04 13:00:00').format('YYYY-MM-DD HH:mm:ss'),
        endDate: moment('2023-11-04 13:30:00').format('YYYY-MM-DD HH:mm:ss')
      }
    };
  }
  timeTicket = null;
  requestCheck = true;
  abortController = new window.AbortController();
  LineRef = React.createRef();

  async componentDidMount() {
    if (this.timeTicket) {
      clearInterval(this.timeTicket);
    }
    this.getDeviceData(this.props);

    let payload = {
      FromDate: '2023-11-08 07:05:19',
      GraphType: 'line',
      Interval: '1m',
      Operator: 'AVG',
      ParameterList: [
        {
          DeviceAlias: '21_FRM_1_TRBN_1',
          ParameterCode: 'Power_Generation'
        },
        {
          DeviceAlias: '21_FRM_1_TRBN_1',
          ParameterCode: 'Operation_Hours'
        }
      ],
      SiteId: '21',
      ToDate: '2023-11-09 07:05:22',
      Type: 'historic',
      ViewType: 'Single'
    };
    let json = await this.getGraphData(payload);

    this.setState({ json });
    let refreshTime = '';
    if (this.props.graphprops.refreshTime) {
      refreshTime = this.props.graphprops.refreshTime * 60000;
    } else {
      refreshTime = defaultRefreshTime;
    }
    this.timeTicket = setInterval(() => {
      if (this.requestCheck) {
        // this.getData(this.props, this.props.selectedThemeing);
      }
    }, refreshTime);
  }

  componentWillUnmount() {
    this.abortController.abort();
    clearInterval(this.timeTicket);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.graphprops !== this.props.graphprops ||
      prevProps.selectionDate !== this.props.selectionDate ||
      prevProps.selectionDateLive !== this.props.selectionDateLive ||
      prevProps.selectionMonth !== this.props.selectionMonth ||
      prevProps.selectionWeek !== this.props.selectionWeek ||
      prevProps.DeviceCode !== this.props.DeviceCode ||
      prevProps.shift !== this.props.shift ||
      prevProps.selectionDateRange !== this.props.selectionDateRange ||
      prevProps.dayMonthYear !== this.props.dayMonthYear ||
      prevProps.dropdownSelection !== this.props.dropdownSelection ||
      prevProps.dashboardPayload !== this.props.dashboardPayload ||
      prevProps.AssetCode !== this.props.AssetCode ||
      prevProps.translationCache !== this.props.translationCache
    ) {
      this.getDeviceData(this.props);
    }
  }
  getDeviceData = async () => {
    let { dropdownSelection } = this.props;
    let { graphTypeList, selectedDate } = this.state;
    let deviceCode =
      dropdownSelection && dropdownSelection.DeviceCode ? dropdownSelection.DeviceCode : '';

    let deviceList = await this.getList(deviceCode);
    this.setState({ deviceList, deviceCode });

    let param1 =
      deviceList && Array.isArray(deviceList) && deviceList[0] && deviceList[0].ParameterCode
        ? deviceList[0].ParameterCode
        : '';

    let param2 =
      deviceList && Array.isArray(deviceList) && deviceList[1] && deviceList[1].ParameterCode
        ? deviceList[1].ParameterCode
        : '';

    let graph =
      graphTypeList && Array.isArray(graphTypeList) && graphTypeList[0] && graphTypeList[0].Code
        ? graphTypeList[0].Code
        : '';

    let payload = {
      FromDate: moment(selectedDate.startDate).utc().format('YYYY-MM-DD HH:mm:ss'),
      GraphType: graph,
      Interval: '1m',
      Operator: 'AVG',
      ParameterList: [
        {
          DeviceAlias: deviceCode,
          ParameterCode: param1
        },
        {
          DeviceAlias: deviceCode,
          ParameterCode: param2
        }
      ],
      SiteId: '21',
      ToDate: moment(selectedDate.endDate).utc().format('YYYY-MM-DD HH:mm:ss'),
      Type: 'historic',
      ViewType: 'Single'
    };
    this.getData(payload, graph);
    let formObject = {
      xAxis: param1,
      yAxis: param2,
      graphType: graph,
      Date: [moment(selectedDate.startDate), moment(selectedDate.endDate)]
    };
    this.LineRef.current.setFieldsValue(formObject);
    let json = await this.getGraphData(payload);
    this.setState({ json, formObject });
  };
  getList = (deviceCode) => {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    const ParameterTypeObject = {
      method: 'POST',
      url: `/api/device/getDeviceParamsByType?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: {
        Device: deviceCode,
        ParameterType: 'Operational'
      }
    };
    return axios(ParameterTypeObject)
      .then((response) => {
        return response.data;
      })
      .catch(function () {});
  };
  getGraphData = (payload) => {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    const ParameterTypeObject = {
      method: 'POST',
      url: `/api/trends/list?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: payload
    };
    return axios(ParameterTypeObject)
      .then((response) => {
        return response.data;
      })
      .catch(function () {});
  };

  getData = async (payload, graphType) => {
    let { props } = this;
    let ArryList = [];
    this.requestCheck = false;
    let { errorInAPI, noDataInDB } = this.state;
    errorInAPI = false;
    noDataInDB = false;
    let tagList = [];

    let { json } = this.state;
    let { graphprops } = this.props;
    if (props.graphprops.graph === 'scatterBar' && props.graphprops.currentDateData !== undefined) {
      json = props.graphprops.currentDateData;
    } else {
      json = await this.getGraphData(payload);
      // json = [
      //     {
      //         "DateTime": "2023-11-01 11:39:00",
      //         "Name": "Chemical injection P operation Boiler water supply flow rate@@P (Water Treatment)",
      //         "Value": 81,
      //         "Description": "Chemical injection P operation Boiler water supply flow rate@@P (Water Treatment)",
      //         "DeviceName": "Water Treatment",
      //         "ParameterName": "Chemical injection P operation Boiler water supply flow rate@@P"
      //     },
      //     {
      //         "DateTime": "2023-11-01 11:40:00",
      //         "Name": "Chemical injection P operation Boiler water supply flow rate@@P (Water Treatment)",
      //         "Value": 35,
      //         "Description": "Chemical injection P operation Boiler water supply flow rate@@P (Water Treatment)",
      //         "DeviceName": "Water Treatment",
      //         "ParameterName": "Chemical injection P operation Boiler water supply flow rate@@P"
      //     },
      //     {
      //         "DateTime": "2023-11-01 11:42:00",
      //         "Name": "Chemical injection P operation Boiler water supply flow rate@@P (Water Treatment)",
      //         "Value": 18,
      //         "Description": "Chemical injection P operation Boiler water supply flow rate@@P (Water Treatment)",
      //         "DeviceName": "Water Treatment",
      //         "ParameterName": "Chemical injection P operation Boiler water supply flow rate@@P"
      //     },
      //     {
      //         "DateTime": "2023-11-01 11:39:00",
      //         "Name": "Pure water equipment Water flow@@PV (Water Treatment)",
      //         "Value": 38,
      //         "Description": "Pure water equipment Water flow@@PV (Water Treatment)",
      //         "DeviceName": "Water Treatment",
      //         "ParameterName": "Pure water equipment Water flow@@PV"
      //     },
      //     {
      //         "DateTime": "2023-11-01 11:40:00",
      //         "Name": "Pure water equipment Water flow@@PV (Water Treatment)",
      //         "Value": 80,
      //         "Description": "Pure water equipment Water flow@@PV (Water Treatment)",
      //         "DeviceName": "Water Treatment",
      //         "ParameterName": "Pure water equipment Water flow@@PV"
      //     },
      //     {
      //         "DateTime": "2023-11-01 11:42:00",
      //         "Name": "Pure water equipment Water flow@@PV (Water Treatment)",
      //         "Value": 141,
      //         "Description": "Pure water equipment Water flow@@PV (Water Treatment)",
      //         "DeviceName": "Water Treatment",
      //         "ParameterName": "Pure water equipment Water flow@@PV"
      //     }
      // ]

      // let tagList = ['Chemical injection P operation Boiler water supply flow rate@@P', 'Pure water equipment Water flow@@PV']

      // let Dates = ['2023-11-01 11:39:00', '2023-11-01 11:40:00', '2023-11-01 11:42:00']

      tagList = _.chain(json).map('ParameterName').uniq().value();
      let Dates = _.chain(json).map('DateTime').uniq().value();
      ArryList =
        Dates &&
        Array.isArray(Dates) &&
        Dates.map((date) => {
          let filterobj = json.filter((d) => {
            return d.DateTime === date;
          });
          return [filterobj[0].Value, filterobj[1].Value, date];
        });
    }
    if (
      (json && json.message) ||
      (Array.isArray(json) && json.length === 0 && graphprops.enableMock !== true)
    ) {
      if (json.message) {
        errorInAPI = true;
      } else if (json.length === 0) {
        noDataInDB = true;
      }
    } else {
      const option = {
        xAxis: {
          axisLine: {
            show: true,
            lineStyle: {
              color:
                this.props.graphprops &&
                this.props.graphprops.line &&
                this.props.graphprops.line.Linecolor &&
                this.props.graphprops.line.Linecolor.length > 0
                  ? this.props.graphprops.line.Linecolor[0]
                  : 'white'
            }
          }
        },
        yAxis: {
          axisLine: {
            show: true,
            lineStyle: {
              color:
                this.props.graphprops &&
                this.props.graphprops.line &&
                this.props.graphprops.line.Linecolor &&
                this.props.graphprops.line.Linecolor.length > 0
                  ? this.props.graphprops.line.Linecolor[0]
                  : 'white'
            }
          }
        },
        tooltip: {
          trigger: 'axis',
          formatter: (params) => {
            let rez =
              '<span>' +
              params[0].data[2] +
              '</br>' +
              tagList[0] +
              ':' +
              params[0].data[0] +
              '</br>' +
              tagList[1] +
              ':' +
              params[0].data[1] +
              '</span>';
            return rez;
          }
        },
        series: [
          {
            symbolSize: graphType && graphType === 'scatter' ? 10 : 2,
            data: ArryList,
            type: graphType
          }
        ]
      };

      this.setState({
        option,
        refreshDateTime: moment()
      });
    }
    this.setState({
      errorInAPI,
      noDataInDB
    });
  };

  // onChange = (value, dateString) => {
  //   let dateString0 =
  //     dateString && dateString[0] ? moment(dateString[0]).format('YYYY-MM-DD 00:00:00') : null;
  //   let dateString1 =
  //     dateString && dateString[1] ? moment(dateString[1]).format('YYYY-MM-DD 23:59:59') : null;
  //   let startDate = dateString0 ? moment(dateString0).utc().format('YYYY-MM-DD HH:mm:ss') : '';
  //   let endDate =
  //     dateString && dateString[1] ? moment(dateString1).utc().format('YYYY-MM-DD HH:mm:ss') : '';

  //   this.setState({
  //     range: [startDate, endDate],
  //     momentRange: [moment(dateString[0]), moment(dateString[1])]
  //   });
  // };
  onChange = (value) => {
    this.setState({
      selectedDate: {
        startDate: moment.utc(value[0]).format('YYYY-MM-DD HH:mm'),
        endDate: moment.utc(value[1]).format('YYYY-MM-DD HH:mm')
      }
    });
  };
  onFormsubmit = async (values) => {
    let { deviceCode, selectedDate } = this.state;
    let { graphType, xAxis, yAxis } = values;
    const siteId = localStorage.get('currentSite');
    let payload = {
      FromDate: moment.utc(selectedDate.startDate).format('YYYY-MM-DD HH:mm:ss'),
      GraphType: 'line',
      Interval: '1m',
      Operator: 'AVG',
      ParameterList: [
        {
          DeviceAlias: deviceCode,
          ParameterCode: xAxis
        },
        {
          DeviceAlias: deviceCode,
          ParameterCode: yAxis
        }
      ],
      SiteId: siteId,
      ToDate: moment.utc(selectedDate.endDate).format('YYYY-MM-DD HH:mm:ss'),
      Type: 'historic',
      ViewType: 'Single'
    };
    this.getData(payload, graphType);
  };

  handleChange = async (value, key) => {
    let formObject = this.LineRef.current.getFieldsValue();
    formObject = {
      ...formObject,
      [key]: value
    };
    // this.setState({ selectParameter: value });
    if (key === 'graphType') {
      this.setState({ selectedGraph: value });
    }
    // if (key === 'DeviceId') {
    //   let selectedDevice = deviceList.filter((item) => item.Id === value);
    //   let location = await getLocationByAsset(selectedDevice[0]);
    //   formObject = {
    //     ...formObject,
    //     [key]: value,
    //     LocationId: location.data.Name
    //   };
    //   // this.LineRef.current.setFieldsValue({"LocationId":location["Name"]})
    // }
    if (this.LineRef.current) this.LineRef.current.setFieldsValue(formObject);
    this.setState({ [key]: value, formObject });
  };
  render() {
    const {
      errorInAPI,
      noDataInDB,
      refreshDateTime,
      deviceList,
      formObject,
      graphTypeList,
      selectedDate
    } = this.state;
    let { graphprops } = this.props;
    return (
      <Fragment>
        <div style={{ float: 'left', width: '45%', height: '90%', marginLeft: '10px' }}>
          <StyledForm
            name="Item"
            theme={themeSettings}
            ref={this.LineRef}
            initialValues={formObject}
            onFinish={this.onFormsubmit}
            // onKeyDown={(e) => {graphTypeList
            //     e.key === 'Enter' && e.preventDefault();
            // }}
            labelCol={{ span: 9 }}
            wrapperCol={{ span: 20 }}
            layout="horizontal"
          >
            <Form.Item
              style={{
                // width: '50%',
                marginTop: '15%'
              }}
              name="xAxis"
              label="Select X-Axis"
              rules={[{ required: true }]}
            >
              <Select
                // style={{ width: '70%' }}
                placeholder={'Select XAxis'}
                onChange={(e) => this.handleChange(e, 'xAxis')}
              >
                {deviceList && Array.isArray(deviceList)
                  ? deviceList.map((deviceType, index) => {
                      return (
                        <Option key={index} value={deviceType.ParameterCode}>
                          {deviceType.ParameterName}
                        </Option>
                      );
                    })
                  : null}
              </Select>
            </Form.Item>

            <Form.Item
              // style={{
              //     width: '50%',
              //     marginTop: '10%'
              // }}
              label="Select Y-Axis"
              name="yAxis"
              rules={[{ required: true }]}
            >
              <Select
                // style={{ width: '70%' }}
                placeholder={'Select YAxis'}
                onChange={(e) => this.handleChange(e, 'yAxis')}
              >
                {deviceList && Array.isArray(deviceList)
                  ? deviceList.map((deviceType, index) => {
                      return (
                        <Option key={index} value={deviceType.ParameterCode}>
                          {deviceType.ParameterName}
                        </Option>
                      );
                    })
                  : null}
              </Select>
            </Form.Item>
            <Form.Item
              // style={{
              //     width: '50%',
              //     marginTop: "5%"
              // }}
              label="Select DateRange"
              name="Date"
              rules={[{ required: true, message: 'Please Select Date Selection' }]}
            >
              <RangePicker
                showTime={{ format: 'HH:mm' }}
                format="YYYY-MM-DD HH:mm"
                placeholder={['Start Date', 'End Date']}
                // value={this.state.momentRange}
                value={[moment(selectedDate.startDate), moment(selectedDate.endDate)]}
                onChange={this.onChange}
              />
            </Form.Item>
            <Form.Item
              // style={{
              //   width: '50%',
              //   marginTop: '5%'
              // }}
              name="graphType"
              label="Select GraphType"
              rules={[{ required: true }]}
            >
              <Select
                // style={{ width: '70%' }}
                placeholder={'Select GraphType'}
                // defaultValue="line"
                onChange={(e) => this.handleChange(e, 'graphType')}
                // disabled={
                //     formObject && formObject.EquipmentFlag && formObject.EquipmentFlag === true
                //         ? true
                //         : false
                // }
              >
                {graphTypeList && Array.isArray(graphTypeList)
                  ? graphTypeList.map((graphType, index) => (
                      <Option key={index} value={graphType.Code}>
                        {graphType.Name}
                      </Option>
                    ))
                  : null}
              </Select>
            </Form.Item>
            {/* <Form.Item style={{ width: '100%', flex: '50%' }}>
              <Input style={{ display: 'none' }} />
            </Form.Item> */}
            <Form.Item {...layout}>
              <StyledButton theme={themeSettings} htmlType="submit">
                {'Submit'}
              </StyledButton>
            </Form.Item>
            {/* </div> */}
          </StyledForm>
        </div>
        <div
          style={{ float: 'right', width: '50%', height: '100%', borderLeft: '1px dashed white' }}
        >
          {graphprops && graphprops.checked === true ? (
            <Tag className="graphTag" color="blue">
              {refreshDateTime ? moment(refreshDateTime).format('YYYY-MM-DD HH:mm:ss') : ''}
            </Tag>
          ) : null}
          {noDataInDB === true || errorInAPI === true ? (
            noDataInDB === true ? (
              <NoRecordComponent />
            ) : (
              <ErrorComponent />
            )
          ) : (
            <ReactEcharts
              option={this.state.option}
              notMerge={true}
              lazyUpdate={true}
              style={{ height: '80%', width: '100%' }}
              className="react_for_echarts"
            />
          )}
        </div>
      </Fragment>
    );
  }

  getOption = () => {
    let theme = this.props.selectedThemeing;
    let option = {
      legend: {
        textStyle: { color: theme === 'lightTheme' ? 'black' : 'white' },
        data: [
          {
            name: 'test1',
            icon: 'roundRect'
            // textStyle: {
            //   fontSize: 18,
            // },
          }
        ]
      },
      tooltip: {
        trigger: 'axis'
        // textStyle: {
        //   fontSize: 15,
        // },
      },
      toolbox: {
        showTitle: false,
        itemSize: 25,
        feature: {
          saveAsImage: {
            backgroundColor: theme === 'lightTheme' ? 'black' : 'black',
            title: 'download',
            name: this.props.graphprops.title,
            iconStyle: {
              borderColor: '#8c8c8c',
              borderWidth: 2
            }
          }
        }
      },
      xAxis: {
        type: 'category',
        data: this.props.graphprops,
        axisLine: {
          lineStyle: {
            color: theme === 'lightTheme' ? 'black' : 'white'
          }
        },
        axisTick: {
          lineStyle: {
            color: theme === 'lightTheme' ? 'black' : 'white'
          }
        },
        axisLabel: {
          color: theme === 'lightTheme' ? 'black' : 'white'
          // fontSize: 15,
        }
      },
      yAxis: {
        //type: "value",
        axisLine: {
          lineStyle: {
            // color: "white",
            color: theme === 'lightTheme' ? 'black' : 'white'
          }
        }
      },
      dataZoom: [
        {
          type: 'slider',
          show: true,
          start: 0,
          end: 100,

          backgroundColor: '#9a9191',
          textStyle: {
            color: 'white'
          }
        },
        {
          type: 'inside',
          start: 0,
          end: 100
        }
      ]
    };
    return option;
  };
}

const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache(),
  selectedThemeing: makeSelectThemeing(),
  currentTimeZone: getCurrentTimezone()
});

export default connect(mapStateToProps)(LineWithSelection);
