import React from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';

// import { constant } from '../Utils/constants';
import AllGraphView from './AllGraphView';

class AllGraphComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      AllFilterData: []
    };
  }

  componentDidUpdate(prevprops) {
    if (prevprops.AllFilterData !== this.props.AllFilterData) {
      this.setState({ AllFilterData: this.props.AllFilterData });
    }
  }

  render() {
    let { AllFilterData } = this.state;
    return (
      <div style={{}}>
        {AllFilterData &&
          Array.isArray(AllFilterData) &&
          AllFilterData.map((filter, index) => {
            return (
              <div style={{ marginTop: '25px', height: '400px' }} key={index}>
                {/* <div style={{ margin: '5px 0px', display: 'flex', justifyContent: 'flex-end' }}>
                  {constant &&
                    Array.isArray(constant.operatorList) &&
                    constant.operatorList &&
                    constant.operatorList.map((operator, index) => {
                      return <Checkbox key={index}>{operator}</Checkbox>;
                    })}
                </div> */}
                <AllGraphView payload={filter} />
              </div>
            );
          })}
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  // userProfile: getUserProfile(),
  // translationCache: getTranslationCache(),
  // currentTimeZone: getCurrentTimezone()
});
export default connect(mapStateToProps)(AllGraphComponent);
