import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Button, Popconfirm, Tooltip, Input } from 'antd';
import {
  EditOutlined,
  DeleteOutlined,
  QuestionCircleOutlined,
  SearchOutlined
} from '@ant-design/icons';

import Highlighter from 'react-highlight-words';
import ListView from '../../../Utils/ListView';
import { constant } from '../../../Utils/constants';
import { getUserProfile } from '../../../../../selectors/layout';
import { deleteById, getUsersList } from '../../../Utils/FormCalls';
import { StyledComponent } from '../../../CSS/style';
import themeSettings from '../../../Utils/themeSettings.json';
import localStorage from '../../../../../utils/localStorage';
import translation from '../../../Utils/translation';
import { getTranslationCache } from '../../../../../selectors/language';
import { getWFList } from '../../../Utils/FormCalls';
import { encode as base64_encode } from 'base-64';
import history from '../../../../../commons/history';
class WorkFlowList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSource: [],
      userProfile: props && props.userProfile ? props.userProfile : {},
      userList: [],
      config: {
        viewTypes: ['add', 'card', 'table'],
        defaultViewType: 'table',

        filterType: 'ByAttributeValue',
        attributeList: ['WorkFlowName'],

        firstTitle: 'WorkFlowName',
        secondTitle: 'CreatedBy',
        thirdTitle: 'CreatedTime',

        actions: ['edit', 'delete'],
        edit: {
          byAttribute: 'Id',
          redirectURL: '/rubus/AMM/WorkFlowDetails'
        },
        deletee: {
          byAttribute: 'Id'
        }
      }
    };
  }

  componentDidMount = async () => {
    this._getDataSource();
  };

  componentDidUpdate(prevprops) {
    if (this.props.userProfile !== prevprops.userProfile) {
      this.setState({ userProfile: this.props.userProfile });
    }
  }

  editWorkOrder = async (record) => {
    let { config } = this.state;
    history.push({ pathname: `${config.edit.redirectURL}/${base64_encode(record.Id)}` });
  };

  _getUserList = async () => {
    let userList = await getUsersList();
    this.setState({ userList });
  };

  _getDataSource = async () => {
    let dataSource = await getWFList();
    this.setState({
      dataSource: dataSource.data
    });
  };

  _deleteById = async (id) => {
    const siteId = localStorage.get('currentSite');
    let payload = {
      url: `/api/masterWorkFlow/delete?Id=${id}&SiteId=${siteId}`,
      method: 'POST'
    };
    await deleteById(payload);
    await this._getDataSource();
  };
  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
          //maxLength={30}
        />
        <SearchOutlined
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          size="small"
          style={{ width: 90, marginRight: 8, fontSize: '130%' }}
        >
          Search
        </SearchOutlined>
        <Button
          onClick={() => this.handleReset(clearFilters, confirm)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined, fontSize: '130%' }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      )
  });
  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex
    });
  };
  handleReset = (clearFilters, confirm) => {
    clearFilters();
    this.handleSearch([], confirm);
  };
  render() {
    const { dataSource, userList } = this.state;
    let { translationCache } = this.props;
    let columns = [
      {
        title: translation(translationCache, constant.workFlowName),
        dataIndex: 'WorkFlowName',
        key: 'WorkFlowName',
        // filter: true,
        ...this.getColumnSearchProps('WorkFlowName')
      },
      {
        title: translation(translationCache, constant.workType),
        dataIndex: 'WorkType',
        key: 'WorkType',
        ...this.getColumnSearchProps('WorkType')
      },
      {
        title: translation(translationCache, constant.site),
        dataIndex: 'SiteName',
        key: 'SiteName',
        filter: true
      },
      {
        title: translation(translationCache, constant.action),
        width: '600',
        render: (text, record) => (
          <>
            <Button
              disabled={record.Status === 'Inactive' ? true : false}
              style={{ marginRight: '5px' }}
              className="ant-btn"
              onClick={() => this.editWorkOrder(record)}
              size="middle"
            >
              <Tooltip title={translation(translationCache, 'Edit')}>
                <EditOutlined />
              </Tooltip>
            </Button>
            <Popconfirm
              title={translation(
                translationCache,
                'Are you sure that you want to delete the Work Flow'
              )}
              icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
              onConfirm={() => this._deleteById(record.Id)}
              okText="Yes"
              cancelText="No"
            >
              <Button className="ant-btn" size="middle">
                <span style={{ color: '#ff4d4f' }}>
                  {' '}
                  <Tooltip title={translation(translationCache, 'Delete')}>
                    <DeleteOutlined />
                  </Tooltip>
                </span>
              </Button>
            </Popconfirm>
          </>
        )
      }
    ];

    let config = {
      title: translation(translationCache, 'Work Flows'),

      viewTypes: ['add', 'card', 'table'],
      defaultViewType: 'table',

      filterType: 'ByAttributeValue',
      attributeList: ['WorkFlowName'],

      firstTitle: 'WorkFlowName',
      secondTitle: 'CreatedBy',
      thirdTitle: 'CreatedTime',

      actions: ['edit', 'delete'],
      edit: {
        byAttribute: 'Id',
        redirectURL: '/rubus/AMM/WorkFlowDetails'
      },
      deletee: {
        byAttribute: 'Id'
      }
    };
    return (
      <StyledComponent theme={themeSettings} style={{ minHeight: window.innerHeight - 143 }}>
        <ListView
          columns={columns}
          dataSource={dataSource}
          userList={userList}
          config={config}
          delete={this._deleteById}
        />
      </StyledComponent>
    );
  }
}
const mapStateToProps = createStructuredSelector({
  userProfile: getUserProfile(),
  translationCache: getTranslationCache()
});
export default connect(mapStateToProps)(WorkFlowList);
