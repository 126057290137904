import React, { Component } from 'react';
import { Tree } from 'antd';
import { DownOutlined } from '@ant-design/icons';

class HierarchyModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      optionList: [],
      value: [],
      autoExpandParent: true.valueOf,
      expandedKeys: [],
      datasource: [],
      checkedKeys: [],
      treeData: this.props && this.props.treeData ? this.props.treeData : [],
      selectedKeys: this.props && this.props.selectedDevice ? this.props.selectedDevice : []
    };
  }
  componentDidUpdate(prevProps) {
    if (prevProps.selectedDevice !== this.props.selectedDevice) {
      this.setState({
        checkedKeys: this.props.selectedDevice
      });
    }
  }

  translation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] && translationCache[keyword]
      ? translationCache[keyword]
      : keyword;
  };

  onExpand = (expandedKeys) => {
    this.setState({
      expandedKeys,
      autoExpandParent: false
    });
  };

  onCheck = (checkedKeys, info) => {
    this.setState({ checkedKeys });
    this.props.handleDropdownChange(checkedKeys, info.checkedNodes);
  };

  render() {
    let { treeData, checkedKeys } = this.state;
    return (
      <div
        style={{
          width: '100%',
          flex: '35%',
          overflowY: 'auto',
          height: window.innerHeight - 250
        }}
      >
        <Tree
          checkable
          style={{ color: 'white', backgroundColor: '#1a3652' }}
          showLine
          // defaultExpandAll={true}
          switcherIcon={<DownOutlined />}
          onCheck={this.onCheck}
          checkedKeys={checkedKeys}
          treeData={treeData}
        ></Tree>
      </div>
    );
  }
}

export default HierarchyModal;
