import React from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
// import localStorage from '../../utils/localStorage';
import moment from 'moment';
import { constant, constants } from './Utils/constants';
import HierarchyModal from './Utils/HierarchyModal';
import { getTranslationCache } from '../../selectors/language';
import { getCurrentTimezone, getUserProfile } from '../../selectors/layout';
import localStorage from '../../utils/localStorage';
import { Button, Form, Select, Modal, DatePicker, Col, Row, message } from 'antd';
import {
  // DownOutlined,
  // UpOutlined,
  // DeleteOutlined,
  // DownOutlined,
  // EditOutlined,
  // EyeOutlined,
  UpSquareOutlined
} from '@ant-design/icons';
import {
  getParameterList,
  // getDeviceData,
  // CreateTrend,
  getBookmarkList,
  // UpdateTrends,
  getOperationList,
  // DeleteTrend,
  getDefaultSiteName
} from './ChartCalls';
import './Utils/CSS/style.css';
const { RangePicker } = DatePicker;
const { Option } = Select;

class DataComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Module: '',

      EquipmentList: [],
      ParameterList: {},
      OperationalList: {},
      ParameterListByType: [],
      IntervalList: constants && constants.frequencyList ? constants.frequencyList : [],
      treeVisible: false,

      loading: false,
      CurrentTab: 'day',
      GraphType: props.GraphType || 'line',
      ViewType: 'Single',

      userProfile: props.userProfile,
      translationCache: props.translationCache || [],
      expand: false
    };
  }
  formHeader = React.createRef();
  async componentDidMount() {
    let Module =
      this.props && this.props.match && this.props.match.params && this.props.match.params.module;
    this._getEquipmentData(Module);

    let BookmarkList = await getBookmarkList(Module);

    this.setState({ Module, BookmarkList });
  }
  _getEquipmentData = async () => {
    let EquipmentList = await getDefaultSiteName();
    this.setState({ EquipmentList: [EquipmentList] });
  };
  componentDidUpdate(prevProps) {
    if (
      prevProps.translationCache !== this.props.translationCache ||
      prevProps.userProfile !== this.props.userProfile
    ) {
      this.setState({
        translationCache: this.props.translationCache,
        userProfile: this.props.userProfile
      });
    }
  }
  generateData = (tunedData, deviceList) => {
    const siteId = localStorage.get('currentSite');
    let { GraphType } = this.state;
    // let allData = [
    //   {
    //     ...tunedData,
    //     SiteId: siteId,
    //     GraphType
    //   }
    // ],
    let allData = [
        {
          ...tunedData,
          SiteId: siteId,
          GraphType,
          Operator: 'AVG'
        }
      ],
      data =
        tunedData && tunedData.ParameterList && Array.isArray(tunedData.ParameterList)
          ? tunedData.ParameterList.map((param) => {
              return {
                ...tunedData,
                ParameterList: [param],
                Operator: 'AVG',
                ParameterName:
                  deviceList && Array.isArray(deviceList)
                    ? deviceList
                        .filter((d) => param.ParameterCode === d.ParameterCode)
                        .map((dev) => {
                          return dev.ParameterName;
                        })
                    : '',
                SiteId: siteId,
                GraphType,
                DeviceName:
                  deviceList && Array.isArray(deviceList)
                    ? deviceList
                        .filter((d) => param.ParameterCode === d.ParameterCode)
                        .map((dev) => {
                          return dev.DeviceName;
                        })
                    : ''
              };
            })
          : '';
    this.props.generateData(data, allData);
  };

  trendAdapter() {
    const {
      type,
      selectedFromDate_ToDate,
      selectedFromDate_ToDate1,
      selectedDateRangeComparision,
      selectedInterval,
      ViewType,
      CurrentTab,
      selectedParameter,
      selectedOperator,
      selectedParameterType
    } = this.state;
    let { GraphType } = this.props;
    let { FromDate, ToDate } = this.state;
    try {
      let data = selectedParameter.map((deviceParameter) => {
        let spliteData = deviceParameter.split('@@');
        return {
          DeviceCode: spliteData[0],
          ParameterCode: spliteData[1]
        };
      });
      let payload = {
        Type: type,
        ViewType,
        ParameterList: data
        // GraphType
        // Operator :selectedOperator
      };
      if (selectedParameterType === 'Operational' && type !== 'live') {
        payload.Operator = selectedOperator;
        payload.GraphType = GraphType;
      }
      if (
        selectedParameterType === 'Status' &&
        type !== 'live' &&
        (selectedOperator === 'FIRST' || 'LAST' || 'RAW')
      ) {
        payload.Operator = selectedOperator;
        payload.GraphType = 'Table';
      }
      if (type === 'live') {
        payload.GraphType = GraphType;
      }
      if (type === 'historic') {
        payload.Interval = selectedInterval;
        payload.FromDate = FromDate;
        payload.ToDate = ToDate;
        // payload.FromDate = moment(FromDate).utc().format('YYYY-MM-DD HH:mm:ss'); //conversion to utc
        // payload.ToDate = moment(ToDate).utc().format('YYYY-MM-DD HH:mm:ss'); //conversion to utc
      } else if (type === 'timeRange') {
        payload.FromDate1 = moment(selectedFromDate_ToDate[0]).utc().format('YYYY-MM-DD HH:mm:ss');
        payload.ToDate1 = moment(selectedFromDate_ToDate[1]).utc().format('YYYY-MM-DD HH:mm:ss');
        payload.FromDate2 = moment(selectedDateRangeComparision[0])
          .utc()
          .format('YYYY-MM-DD HH:mm:ss');
        payload.ToDate2 = moment(selectedDateRangeComparision[1])
          .utc()
          .format('YYYY-MM-DD HH:mm:ss');
        payload.Interval = selectedInterval;
      } else if (type === 'timeWithSelectionType') {
        payload.FromDate = moment(selectedFromDate_ToDate1[0]).utc().format('YYYY-MM-DD HH:mm:ss'); //conversion to utc
        payload.ToDate = moment(selectedFromDate_ToDate1[1]).utc().format('YYYY-MM-DD HH:mm:ss'); //conversion to utc
        payload.Interval = selectedInterval;
        payload.SelectionType = CurrentTab;
      }
      return payload;
    } catch (error) {
      return {};
    }
  }
  OnGenerateTrendData = async () => {
    let { selectedParameter, ParameterListByType } = this.state;
    this.formHeader.current
      .validateFields()
      .then(async () => {
        const tunedData = this.trendAdapter();
        let filteredDeviceList = ParameterListByType.filter((a) =>
          tunedData.ParameterList.some((s) => s.ParameterCode === a.ParameterCode)
        );
        selectedParameter.map((deviceParameter) => {
          let spliteData = deviceParameter.split('@@');
          return {
            DeviceCode: spliteData[0],
            ParameterCode: spliteData[1]
          };
        });
        this.generateData(tunedData, filteredDeviceList);
        this.setState({
          tunedData
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  onModalsOk = (e) => {
    let { checkedValue } = this.state;
    e.preventDefault();
    this.OnDeviceChange(checkedValue);
    this.setState({
      treeVisible: false
    });
  };
  onModalCancel = () => {
    this.setState({
      treeVisible: false
    });
  };
  OnDeviceChange = async (value) => {
    let val = [];
    val.push(value);
    let ParameterList = {};
    if (value.length === 6) {
      value.shift();
      ParameterList = await getParameterList(val);
    } else {
      ParameterList = await getParameterList(val);
    }
    this.setState({
      selectedDevice: value,
      ParameterList,
      update: false,
      ParameterListByType: [],
      selectedParameterType: undefined,
      selectedParameter: [],
      selectedOperator: undefined
    });
    this.formHeader.current.setFieldsValue({
      selectedDevice: value,
      ParameterList,
      update: false,
      ParameterListByType: [],
      selectedParameterType: undefined,
      selectedParameter: [],
      selectedOperator: undefined
    });
  };
  hierarchyDropdownChange = (value) => {
    this.setState({ checkedValue: value });
  };
  OnParameterChange = (value) => {
    if (this.state.type === 'timeRange' && value.length === 2) {
      value.shift();
      this.setState({ selectedParameter: value, update: false });
    } else if (this.state.type === 'timeWithSelectionType' && value.length === 2) {
      value.shift();
      this.setState({ selectedParameter: value, update: false });
    } else if (value.length === 11) {
      value.shift();
      this.setState({ selectedParameter: value, update: false });
    } else {
      this.setState({ selectedParameter: value, update: false });
    }
  };
  OnOperatorChange = (value) => {
    this.setState({ selectedOperator: value });
  };
  OnParameterTypeChange = async (selectedParameterType) => {
    let { ParameterList, OperationalList, selectedOperator, type, GraphType } = this.state;
    if (
      selectedParameterType !== 'Operational' ||
      (selectedParameterType === 'Status' && type === 'live')
    ) {
      this.setState({
        fieldVisible: false,
        selectedOperator: ''
      });
    } else if (selectedParameterType === 'Operational' && type !== 'live') {
      OperationalList = await getOperationList(selectedParameterType, GraphType);
      this.setState({
        fieldVisible: true,
        OperationalList,
        selectedOperator
      });
    }
    this.setState({
      ParameterListByType: ParameterList[selectedParameterType],
      selectedParameterType,
      selectedParameter: [],
      selectedOperator: undefined
    });
    this.formHeader.current.setFieldsValue({
      selectedParameterType,
      selectedParameter: [],
      selectedOperator: undefined
    });
  };
  OnTypeChange = (value) => {
    let ViewType =
      value && (value === 'timeRange' || value === 'timeWithSelectionType') ? 'Multiple' : 'Single';
    this.setState({
      type: value,
      trendData: [],
      tunedData: {},
      loading: false,
      currentSelectionTab: 'day',
      GraphType: 'line',
      ViewType,
      selectedDevice: [],
      selectedParameter: [],
      selectedParameterType: [],
      selectedInterval: '',
      selectedFromDate_ToDate: [],
      selectedFromDate_ToDate1: [],
      selectedDateRangeComparision: []
    });
    this.formHeader.current.setFieldsValue({
      type: value,
      ViewType,
      selectedDevice: [],
      selectedParameter: [],
      selectedParameterType: [],
      selectedInterval: '',
      selectedFromDate_ToDate: [],
      selectedFromDate_ToDate1: [],
      selectedDateRangeComparision: []
    });
  };
  OnResetFields = () => {
    this.formHeader.current.setFieldsValue({
      type: 'historic',
      selectedDevice: [],
      selectedParameter: [],
      selectedOperator: undefined,
      selectedParameterType: [],
      selectedInterval: undefined,
      selectedFromDate_ToDate: [],
      selectedFromDate_ToDate1: [],
      selectedDateRangeComparision: [],
      ViewType: 'Single',
      fieldVisible: true,
      GraphType: 'line'
    });
    this.setState({
      type: 'historic',
      selectedDevice: [],
      selectedParameter: [],
      selectedOperator: undefined,
      selectedParameterType: [],
      selectedInterval: undefined,
      selectedFromDate_ToDate: [],
      selectedFromDate_ToDate1: [],
      selectedDateRangeComparision: [],
      BookmarkName: undefined,
      Id: undefined,
      ViewType: 'Single',
      fieldVisible: true,
      GraphType: 'line'
    });
    this.props.generateData({}, {});
  };
  translation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };
  onChangeFromDate_ToDate1 = (value, selectedFromDate_ToDate1tring) => {
    // let { tunedData, filterObject, source } = this.state;
    let selectedFromDate_ToDate1 = [
      moment(selectedFromDate_ToDate1tring[0]).format('YYYY-MM-DD HH:mm:ss'),
      moment(selectedFromDate_ToDate1tring[1]).format('YYYY-MM-DD HH:mm:ss')
    ];
    let ms = moment(selectedFromDate_ToDate1[1], 'YYYY-MM-DD HH:mm').diff(
      moment(selectedFromDate_ToDate1[0], 'YYYY-MM-DD HH:mm')
    );
    let d = moment.duration(ms);
    let diffinDays = Math.floor(d.asDays());
    //   ...tunedData,
    if (diffinDays && diffinDays > 45) {
      message.error('please select days with in 45 days');

      let FromDate = moment(selectedFromDate_ToDate1[0]).utc().format('YYYY-MM-DD HH:mm:ss'); //conversion to utc
      let ToDate = moment(selectedFromDate_ToDate1[1]).utc().format('YYYY-MM-DD HH:mm:ss');
      this.setState({
        FromDate,
        ToDate,
        selectedFromDate_ToDate1: [
          moment(selectedFromDate_ToDate1tring[0]).format('YYYY-MM-DD HH:mm:ss'),
          null
        ]
      });
    } else {
      let FromDate = moment(selectedFromDate_ToDate1[0]).utc().format('YYYY-MM-DD HH:mm:ss'); //conversion to utc
      let ToDate = moment(selectedFromDate_ToDate1[1]).utc().format('YYYY-MM-DD HH:mm:ss');
      // };
      this.setState({
        FromDate,
        ToDate
      });
    }
  };
  disabledDate = (current) => {
    let { selectedDate } = this.state;
    if (!selectedDate) {
      return false;
    }
    let after = selectedDate[0] && current.diff(selectedDate[0], 'days') > 45;
    let before = selectedDate[1] && selectedDate[1].diff(current, 'days') > 45;
    return !!before || !!after;
  };

  // const disabledDate = (current) => {
  //   if (!selectedFromDate_ToDate1) {
  //     return false;
  //   }
  //   const after = selectedFromDate_ToDate1[0] && current.diff(selectedFromDate_ToDate1[0], 'days') > 7;
  //   const before = selectedFromDate_ToDate1[1] && selectedFromDate_ToDate1[1].diff(current, 'days') > 7;
  //   return !!before || !!after;
  // };

  onCalendarChange = (val) => {
    this.setState({ selectedDate: val });
  };
  renderFormSwitch(name) {
    const {
      EquipmentList,
      selectedDevice,
      selectedParameter,
      selectedInterval,
      ParameterList,
      ParameterListByType,
      selectedParameterType,
      IntervalList
    } = this.state;

    switch (name) {
      case 'type':
        return (
          <Form.Item
            name={'type'}
            label={this.translation(constant.Select_Trend_Type)}
            rules={[{ required: false, message: this.translation('Please Select TrendType!') }]}
          >
            <Select
              placeholder={this.translation(constant.Select_Trend_Type)}
              onChange={this.OnTypeChange}
              style={{ width: '330px' }}
            >
              {/* <Option value="live">{this.translation('Live Trend')}</Option> */}
              <Option value="historic">{this.translation('Historical Trend')}</Option>
            </Select>
          </Form.Item>
        );
      case 'selectedDevice':
        return (
          <Form.Item
            name={'selectedDevice'}
            label={this.translation(constant.Select_Device)}
            rules={[{ required: false, message: this.translation('Please Select Equipment!') }]}
          >
            <span
              style={{
                display: 'flex',
                justifyContent: 'flex-start'
              }}
            >
              <Select
                style={{ width: '284px' }}
                value={selectedDevice}
                mode="multiple"
                placeholder={this.translation(constant.Select_Device)}
                size="default"
                disabled={true}
              >
                {EquipmentList &&
                  Array.isArray(Object.keys(EquipmentList)) &&
                  Object.keys(EquipmentList).map((device, index) => {
                    return (
                      <Option key={index} value={EquipmentList[device].DeviceCode}>
                        {EquipmentList[device].Name}
                      </Option>
                    );
                  })}
              </Select>
              <Button
                type="primary"
                onClick={() => {
                  this.setState({ treeVisible: true });
                }}
              >
                <UpSquareOutlined />
              </Button>
            </span>
          </Form.Item>
        );
      case 'selectedParameterType':
        return (
          <Form.Item
            name={'selectedParameterType'}
            label={this.translation(constant.Select_ParameterType)}
            rules={[
              { required: false, message: this.translation('Please Select Parameter Type!') }
            ]}
          >
            <Select
              style={{ width: '330px' }}
              value={selectedParameterType}
              placeholder={this.translation(constant.Select_ParameterType)}
              onChange={this.OnParameterTypeChange}
            >
              {ParameterList &&
                Array.isArray(Object.keys(ParameterList)) &&
                Object.keys(ParameterList).map((parameterType) => {
                  return <Option key={parameterType}>{parameterType}</Option>;
                })}
            </Select>
          </Form.Item>
        );
      case 'selectedParameter':
        return (
          <Form.Item
            name={'selectedParameter'}
            label={this.translation(constant.Select_Parameter)}
            rules={[{ required: false, message: this.translation('Please Select Parameter!') }]}
          >
            <Select
              style={{ width: '330px' }}
              showSearch
              mode="multiple"
              value={selectedParameter}
              onChange={this.OnParameterChange}
              placeholder={this.translation(constant.Select_Parameter)}
            >
              {ParameterListByType &&
                Array.isArray(ParameterListByType) &&
                ParameterListByType.map((param, index) => {
                  return (
                    <Option
                      key={index}
                      value={`${param.Device}@@${param.ParameterCode}`}
                      disabled={
                        this.formHeader &&
                        this.formHeader.current &&
                        this.formHeader.current.getFieldsValue() &&
                        this.formHeader.current.getFieldsValue().selectedParameter &&
                        this.formHeader.current.getFieldValue().selectedParameter.length === 3
                          ? this.formHeader.current
                              .getFieldValue()
                              .selectedParameter.includes(`${param.Device}@@${param.ParameterCode}`)
                            ? false
                            : true
                          : false
                      }
                    >
                      {`${param.DeviceName}=>${param.ParameterDescription}`}
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>
        );
      case 'selectedFromDate_ToDate1':
        return (
          <Form.Item
            name={'selectedFromDate_ToDate1'}
            label={this.translation(constant.Select_Range)}
            rules={[
              {
                required: false,
                message: this.translation('Please Select DateRanges!')
              }
            ]}
          >
            <RangePicker
              showTime={{ format: 'HH:mm' }}
              format="YYYY-MM-DD HH:mm"
              style={{ width: '330px' }}
              placeholder={[this.translation('Start DateTime'), this.translation('End DateTime')]}
              value={this.state.selectedFromDate_ToDate1}
              // value={[]}
              onChange={this.onChangeFromDate_ToDate1}
              onCalendarChange={(val) => this.onCalendarChange(val)}
              disabledDate={(e) => this.disabledDate(e)}
              changeOnBlur={true}
            />
          </Form.Item>
        );
      case 'selectedInterval':
        return (
          <Form.Item
            name={'selectedInterval'}
            label={this.translation(constant.Interval)}
            rules={[{ required: false, message: this.translation('Please Select Interval!') }]}
          >
            <Select
              placeholder={this.translation(constant.Interval)}
              value={selectedInterval}
              onChange={(value) => {
                this.setState({ selectedInterval: value });
              }}
              style={{ width: '330px' }}
            >
              {IntervalList &&
                Array.isArray(IntervalList) &&
                IntervalList.map((item, index) => {
                  return (
                    <Option key={index} value={item.value}>
                      {item.label}
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>
        );
      default:
        return <div>{name}</div>;
    }
  }

  render() {
    const { EquipmentList, selectedDevice, userProfile, type } = this.state;
    let permissionsArray = [];
    userProfile &&
      userProfile['belongsTo'] &&
      userProfile['belongsTo']['sites'] &&
      userProfile['belongsTo']['sites'][localStorage.get('currentSite')] &&
      userProfile['belongsTo']['sites'][localStorage.get('currentSite')]['role'] &&
      userProfile['belongsTo']['sites'][localStorage.get('currentSite')]['role']['Permission']
        .filter((item) => item.resource === 'trends')
        .map((name) => permissionsArray.push(name.action));
    const getFields = () => {
      // const count = expand ? 10 : 4;
      let children = [];
      let fields = [
        'type',
        'selectedDevice',
        'selectedParameterType',
        'selectedParameter',
        ...(type && type === 'historic' ? ['selectedFromDate_ToDate1', 'selectedInterval'] : [])
      ];
      children =
        fields &&
        fields.map((colName) => {
          return (
            <div style={{ width: '350px' }} key={colName}>
              {this.renderFormSwitch(colName)}
            </div>
          );
        });
      return children;
    };

    return (
      <div style={{ background: 'rgb(38, 60, 83)', padding: '30px', marginTop: '8px' }}>
        <Form
          ref={this.formHeader}
          initialValues={this.state}
          layout={'vertical'}
          className="FormDarkMode"
          onFinish={this.OnGenerateTrendData}
        >
          <div style={{ display: 'flex', flexFlow: 'row wrap' }}>{getFields()}</div>
          <Row>
            <Col
              span={24}
              style={{
                textAlign: 'right'
              }}
            >
              <Button type="primary" htmlType="submit">
                Generate
              </Button>
              <Button
                style={{
                  margin: '0 8px'
                }}
                onClick={() => {
                  this.OnResetFields();
                }}
              >
                Clear
              </Button>
              {/* <a
                style={{
                  fontSize: 12
                }}
                onClick={() => {
                  this.setState({ expand: !expand });
                }}
              >
                {expand ? <UpOutlined /> : <DownOutlined />} Collapse
              </a> */}
            </Col>
          </Row>
        </Form>

        {/* <Button onClick={this.generateData}>Generate Data</Button> */}

        <Modal
          open={this.state.treeVisible}
          width={800}
          closable
          title={'Select Equipment'}
          okText={'Add'}
          onOk={this.onModalsOk}
          onCancel={this.onModalCancel}
        >
          <HierarchyModal
            selectedDevice={selectedDevice ? selectedDevice : []}
            treeData={Object.values(EquipmentList)}
            Key="Trends"
            handleDropdownChange={this.hierarchyDropdownChange}
          />
        </Modal>

        {/* 
        {
                        <>
                          {ViewType && ViewType === 'Single' ? (
                            <>
                              {GraphType !== 'Table' ? (
                                <Line
                                  graphType={GraphType}
                                  panelData={{
                                    graph: 'trends',
                                    trendData: trendData,
                                    colors: color,
                                    linetype: '',
                                    update: this.state.update,
                                    Type,
                                    tunedData
                                  }}
                                  currentTimeZone={this.props.currentTimeZone}
                                />
                              ) : (
                                <div style={{ height: '55vh' }}>
                                  <Table
                                    panelData={{
                                      graph: 'trends',
                                      trendData: trendData,
                                      colors: color,
                                      linetype: '',
                                      update: this.state.update,
                                      Type,
                                      tunedData
                                    }}
                                    currentTimeZone={this.props.currentTimeZone}
                                    className="trendTable"
                                    dataSource={trendData}
                                    translationCache={this.props.translationCache}
                                  />
                                </div>
                              )}
                            </>
                          ) : (
                            <>
                              {GraphType !== 'Table' ? (
                                <span
                                  style={{
                                    overflowY: 'auto',
                                    height: window.innerHeight - 215
                                  }}
                                >
                                  {trendData &&
                                    Array.isArray(trendData) &&
                                    trendData.map((graphData, i, tunedData) => {
                                      return (
                                        <div
                                          key={i}
                                          style={{
                                            height: '48vh',
                                            marginTop: '1%'
                                          }}
                                        >
                                          {graphData &&
                                          Array.isArray(graphData) &&
                                          graphData.length > 0 ? (
                                            <Line
                                              graphType={GraphType}
                                              panelData={{
                                                graph: 'trends',
                                                trendData: graphData,
                                                tunedData: tunedData,
                                                colors: [color[i]],
                                                linetype: '',
                                                update: this.state.update,
                                                Type
                                              }}
                                              currentTimeZone={this.props.currentTimeZone}
                                            />
                                          ) : (
                                            <div
                                              style={{
                                                width: '100%',
                                                height: '48vh',
                                                backgroundColor: 'white'
                                              }}
                                            >
                                              <NoRecordComponent />
                                            </div>
                                          )}
                                        </div>
                                      );
                                    })}
                                </span>
                              ) : (
                                <span
                                  style={{
                                    overflowY: 'auto',
                                    height: window.innerHeight - 215
                                  }}
                                >
                                  {trendData &&
                                    Array.isArray(trendData) &&
                                    trendData.map((trend, index) => {
                                      return (
                                        <div
                                          key={index}
                                          style={{
                                            marginTop: '40px'
                                          }}
                                        >
                                          <Table
                                            panelData={{
                                              graph: 'trends',
                                              trendData: trend,
                                              colors: color,
                                              update: this.state.update,
                                              Type
                                            }}
                                            currentTimeZone={this.props.currentTimeZone}
                                            className="trendTable"
                                            dataSource={trendData}
                                          />
                                        </div>
                                      );
                                    })}
                                </span>
                              )}
                            </>
                          )}
                        </>
                      } */}
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  userProfile: getUserProfile(),
  translationCache: getTranslationCache(),
  currentTimeZone: getCurrentTimezone()
});
export default connect(mapStateToProps)(DataComponent);

// let allData11 = [
//   {
//     FromDate: '2023-09-12 01:14:00',
//     GraphType: 'line',
//     Interval: '1h',
//     Operator: 'MIN',
//     ParameterList: [
//       {
//         DeviceCode: '131',
//         ParameterCode: 'Main_Engine_Temperature'
//       },
//       {
//         DeviceCode: '131',
//         ParameterCode: 'Boom_Outstretch_Length'
//       }
//     ],
//     SiteId: '1',
//     ToDate: '2023-09-19 01:14:00',
//     Type: 'historic',
//     ViewType: 'Single'
//   }
// ];
// let data = [
//   {
//     FromDate: '2023-08-31 14:19:00',
//     GraphType: 'line',
//     Interval: '1d',
//     Operator: 'MAX',
//     ParameterList: [
//       {
//         DeviceCode: '131',
//         ParameterCode: 'Main_Engine_Temperature'
//       }
//     ],
//     DeviceName: 'Ship Loader',
//     ParameterName: 'Main_Engine_Temperature',
//     SiteId: '1',
//     ToDate: '2023-09-16 14:19:00',
//     Type: 'historic',
//     ViewType: 'Single'
//   },
//   {
//     FromDate: '2023-09-12 04:56:00',
//     GraphType: 'line',
//     Interval: '1h',
//     Operator: 'AVG',
//     ParameterList: [
//       {
//         DeviceCode: '131',
//         ParameterCode: 'Main_Engine_Temperature'
//       }
//     ],
//     DeviceName: 'Ship Loader',
//     ParameterName: 'Main_Engine_Temperature',
//     SiteId: '1',
//     ToDate: '2023-09-16 10:23:15',
//     Type: 'historic',
//     ViewType: 'Single'
//   },
//   {
//     FromDate: '2023-09-12 04:56:00',
//     GraphType: 'line',
//     Interval: '1h',
//     Operator: 'AVG',
//     ParameterList: [
//       {
//         DeviceCode: '131',
//         ParameterCode: 'Main_Engine_Temperature'
//       }
//     ],
//     DeviceName: 'Ship Loader',
//     ParameterName: 'Main_Engine_Temperature',
//     SiteId: '1',
//     ToDate: '2023-09-16 10:23:15',
//     Type: 'historic',
//     ViewType: 'Single'
//   }
// ]
