/**
 * @author Anvesh B
 * @description This Component is used for EAM Landing page
 */
import React, { Component } from 'react';
import axios from 'axios';
import { Button, Row, Col, Card, Avatar, Space, Table, message, Tag } from 'antd';
import {
  EditOutlined,
  MailOutlined,
  UserOutlined,
  AlertOutlined,
  AimOutlined
} from '@ant-design/icons';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { encode as base64_encode } from 'base-64';
import moment from 'moment';

import localStorage from '../../../utils/localStorage';
import history from '../../../commons/history';
import translation from '../Utils/translation';
// import ReactEcharts from './Echarts/Echarts';
import { getLandingPageCount, getPendingList, updateStatus } from '../Utils/FormCalls';
import { getTranslationCache } from '../../../selectors/language';
import { getUserDetails } from '../../../selectors/layout';
import { StyledDashboard } from '../../Settings/Masters/styles';

import '../CSS/EAM.css';
const { Meta } = Card;
class EAMLandingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [],
      dataSource: [],
      activeKey: '1',
      form: {},
      listData: {},
      userId: this.props.userDeatils,
      pendingList: [],
      data: []
    };
  }

  componentDidMount = async () => {
    this.getWorkOrderList();
    this.getListData();
    // this.getGraphData();
  };
  componentDidUpdate(prevProps) {
    if (
      prevProps.UserDetails !== this.props.UserDetails ||
      prevProps.translationCache !== this.props.translationCache
    ) {
      this.getListData();
      this.setState({
        userId: this.props.UserDetails
      });
    }
  }

  getGraphData = () => {
    let UserId =
      this.props.UserDetails && this.props.UserDetails !== undefined && this.props.UserDetails.Id
        ? this.props.UserDetails.Id
        : '';
    const accessToken = localStorage.get('accessToken');
    const siteId = localStorage.get('currentSite');
    const roleId = localStorage.get('roleId');
    const ParameterTypeObject = {
      method: 'POST',
      url: `/api/dashboard/getDashboard?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: {
        Name: 'WorkOrders',
        PanelName: 'StatusCounts',
        Id: 1,
        dashboardType: 'static',
        type: 'historic',
        siteId: siteId,
        timeBucket: '1h',
        date: moment().format('YYYY-MM-DD HH:mm:ss'),
        RoleId: roleId,
        UserId: UserId || 1
      }
    };
    return axios(ParameterTypeObject)
      .then((response) => {
        let data = response.data.map((dataObject) => {
          return {
            name: dataObject.Name,
            value: dataObject.Value
          };
        });
        this.setState({
          data
        });
      })
      .catch(function () {});
  };

  getListData = async () => {
    let payload = {
      UserId:
        this.props.UserDetails && this.props.UserDetails !== undefined && this.props.UserDetails.Id
          ? this.props.UserDetails.Id
          : '',
      UserName:
        this.props.UserDetails &&
        this.props.UserDetails !== undefined &&
        this.props.UserDetails.UserName
          ? this.props.UserDetails.UserName
          : ''
    };
    if (payload && payload.UserId && payload.UserId !== '') {
      try {
        let listData = await getLandingPageCount(payload);
        this.setState({ listData });
      } catch (error) {
        console.log(error);
      }
    }
  };
  getWorkOrderList = async () => {
    try {
      let list = await getPendingList();
      this.setState({ pendingList: list.data });
    } catch (error) {
      message.error(`${error.response.data.message}`);
    }
  };
  updateWorkStatus = async (record, status) => {
    try {
      await updateStatus(record, status);
      // this.setState({pendingList:checkStatus.data})
      let list = await getPendingList();
      this.setState({ pendingList: list.data });
    } catch (error) {
      message.error(`${error.response.data.message}`);
    }
  };
  render() {
    let { listData, pendingList } = this.state;
    let { translationCache } = this.props;
    const columns = [
      {
        title: translation(translationCache, 'Created Date & Time'),
        dataIndex: 'CreatedTime',
        key: 'CreatedTime',
        render: (text) => (
          <a href="window.location">{moment(text).utc().format('YYYY-MM-DD HH:mm:ss')}</a>
        )
      },
      {
        title: translation(translationCache, 'Work Order'),
        dataIndex: 'WorkOrder',
        key: 'WorkOrder'
      },
      {
        title: translation(translationCache, 'Status'),
        dataIndex: 'Status',
        key: 'Status'
      },
      {
        title: translation(translationCache, 'Action'),
        key: 'action',
        render: (_, record) => (
          <Space size="middle">
            {record.futureStatusActions &&
              record.futureStatusActions.map((item, i) => {
                return (
                  <Tag
                    style={{ cursor: 'pointer' }}
                    color={'#87d068'}
                    key={i}
                    onClick={() => {
                      this.updateWorkStatus(record, item.ConnectionStatus);
                    }}
                  >
                    {item.ConnectionStatus}
                  </Tag>
                );
              })}
            <Button
              style={{ marginRight: '5px' }}
              className="ant-btn"
              onClick={() => {
                history.push(`/rubus/AMM/WorkOrderDetails/${base64_encode(record.Id)}`);
              }}
              size="middle"
            >
              <EditOutlined />
            </Button>
          </Space>
        )
      }
    ];
    return (
      <StyledDashboard className="EAMLAND" style={{ minHeight: window.innerHeight - 73 }}>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col className="gutter-row" span={6}>
            <Card className="notify">
              <Meta
                avatar={
                  <Avatar
                    className="notifyCard"
                    style={{ background: '#007bff' }}
                    icon={<UserOutlined />}
                  />
                }
                title={
                  listData &&
                  Object.keys(listData).length !== 0 &&
                  listData.OpenWorkOrders !== undefined
                    ? String(listData.OpenWorkOrders)
                    : ''
                }
                description={translation(translationCache, 'Open Work Orders')}
              />
            </Card>
          </Col>
          <Col className="gutter-row" span={6}>
            <Card className="notify">
              <Meta
                avatar={
                  <Avatar
                    className="notifyCard"
                    style={{ background: '#faad14' }}
                    icon={<MailOutlined />}
                  />
                }
                title={
                  listData &&
                  Object.keys(listData).length !== 0 &&
                  listData.Notifications !== undefined
                    ? String(listData.Notifications)
                    : ''
                }
                description={translation(translationCache, 'Notifications')}
              />
            </Card>
          </Col>
          <Col className="gutter-row" span={6}>
            <Card className="notify">
              <Meta
                avatar={
                  <Avatar
                    className="notifyCard"
                    style={{ background: '#f5222d' }}
                    icon={<AimOutlined />}
                  />
                }
                title={
                  listData &&
                  Object.keys(listData).length !== 0 &&
                  listData.PendingWorkOrders !== undefined
                    ? String(listData.PendingWorkOrders)
                    : ''
                }
                description={translation(translationCache, 'Pending Work Orders')}
              />
            </Card>
          </Col>
          <Col className="gutter-row" span={6}>
            <Card className="notify">
              <Meta
                avatar={
                  <Avatar
                    className="notifyCard"
                    style={{ background: '#faad14' }}
                    icon={<AlertOutlined />}
                  />
                }
                title={
                  listData &&
                  Object.keys(listData).length !== 0 &&
                  listData.ClosedWorkOrders !== undefined
                    ? String(listData.ClosedWorkOrders)
                    : ''
                }
                description={translation(translationCache, 'Complete Work Order')}
              />
            </Card>
          </Col>
        </Row>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col className="gutter-row eamCard" span={24}>
            <Card title={translation(translationCache, 'Pending Approvals')} className="">
              <Table columns={columns} dataSource={pendingList} scroll={{ x: 1000, y: 200 }} />
            </Card>
          </Col>
        </Row>
        {/* <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col className="gutter-row eamCard" span={24}>
            <Card title="Work Order Status Info" className="">
              <ReactEcharts
                option={{
                  tooltip: {
                    trigger: 'item'
                  },
                  legend: {
                    top: '5%',
                    left: 'center'
                  },
                  series: [
                    {
                      name: '',
                      type: 'pie',
                      radius: ['40%', '70%'],
                      avoidLabelOverlap: false,
                      itemStyle: {
                        borderRadius: 10,
                        borderColor: '#fff',
                        borderWidth: 2
                      },
                      label: {
                        show: false,
                        position: 'center'
                      },
                      emphasis: {
                        label: {
                          show: true,
                          fontSize: 40,
                          fontWeight: 'bold'
                        }
                      },
                      labelLine: {
                        show: false
                      },
                      data
                    }
                  ]
                }}
              />
            </Card>
          </Col>
        </Row> */}
      </StyledDashboard>
    );
  }
}
const mapStateToProps = createStructuredSelector({
  UserDetails: getUserDetails(),
  translationCache: getTranslationCache()
});
export default connect(mapStateToProps)(EAMLandingPage);
