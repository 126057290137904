/**
 * @author Swathi
 * @description This Component is mainly for Creating Jobplans and Task
 * @View Table with crud operations of Jobplanes
 */
import React, { Component } from 'react';
import { Form, message, Select, DatePicker, Input, Button } from 'antd';
import moment from 'moment';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import _ from 'lodash';
import history from '../../../../../commons/history';
import {
  getDeviceList,
  getSitesList,
  getWorkTypes,
  getSRByID,
  getUsersList,
  getStatus,
  getLocationByAsset,
  getServiceReqId,
  submitSR
  // submitWorkOrder
} from '../../../Utils/FormCalls';
import axios from 'axios';
import Highlighter from 'react-highlight-words';
import { DownOutlined, SearchOutlined } from '@ant-design/icons';
import { constant } from '../../../Utils/constants';
import translation from '../../../Utils/translation';
import themeSettings from '../../../Utils/themeSettings.json';
import TableSelect from '../../../Widgets/TableSelect';
import { getTranslationCache } from '../../../../../selectors/language';
import {
  StyledBox,
  StyledBoxLabel,
  StyledButton,
  StyledComponent,
  StyledForm,
  StyledModal
} from '../../../CSS/style';
import InputComponent from '../../../Widgets/InputComponent';
import { decode as base64_decode } from 'base-64';
import localStorage from '../../../../../utils/localStorage';
const { Option } = Select;
const { TextArea } = Input;
class ServiceRequestForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deviceList: [],
      organisationsList: [],
      sitesList: [],
      statusList: [],
      usersList: [],
      tableData: [],
      workTypesList: [],
      visible: false,
      viewCM: false,
      viewInspection: false,
      workorderdisabled: true,
      formObject:
        props.formObject && Object.keys(props.formObject).length > 0 ? props.formObject : {}
    };
  }
  formHeader = React.createRef();
  componentDidMount = async () => {
    this._getDataById();
    let sitesList = await getSitesList();
    let formObject = this.formHeader.current.getFieldsValue();
    formObject = {
      ...formObject,
      WorkType: formObject && !formObject.id ? 5 : formObject.WorkType,
      SiteId: sitesList && sitesList[0] && sitesList[0].Id ? sitesList[0].Id : ''
    };
    this.formHeader.current.setFieldsValue(formObject);
    this.setState({ sitesList, formObject });
    try {
      let deviceList = await getDeviceList();
      this.setState({ deviceList });
    } catch (error) {
      message.warn(`${error.message}`);
    }
    try {
      let sitesList = await getSitesList();
      this.setState({ sitesList });
    } catch (error) {
      message.warn(`${error.message}`);
    }
    try {
      let usersList = await getUsersList();
      this.setState({ usersList: usersList });
    } catch (error) {
      message.warn(`${error.message}`);
    }
    let workTypesList = await getWorkTypes();
    this.setState({ workTypesList });

    try {
      let statusList = await getStatus();
      this.setState({ statusList });
      let WAPPRStatus =
        statusList && Array.isArray(statusList)
          ? statusList.filter((status) => status.Status === 'WAPPR')
          : [];
      let StatusId = WAPPRStatus && WAPPRStatus[0] ? WAPPRStatus[0].Id : undefined;
      if (this.state.formObject && !this.state.formObject.Id) {
        let workOrderName = await getServiceReqId();
        this.handleChange(workOrderName['SR'], 'SRId');
        this.handleChange(StatusId, 'StatusId');
      }
    } catch (error) {
      message.warn(`${error.message}`);
    }
  };
  componentDidUpdate(prevProps) {
    if (this.props.history.location !== prevProps.history.location) {
      this.onReset();
      this._getDataById();
    }
  }
  handleChange = async (value, key) => {
    let { deviceList, usersList } = this.state;
    let formObject = this.formHeader.current && this.formHeader.current.getFieldsValue();
    if (key === 'Device') {
      let selectedDevice = deviceList.filter((item) => item.AliasCode === value);
      let location = await getLocationByAsset(selectedDevice[0] && selectedDevice[0].Asset);
      formObject = {
        ...formObject,
        [key]: value,
        EquipmentDescription: selectedDevice[0].AliasCode,
        Location: location.AssetCode,
        LocationDescription: location.Name
      };
      this.formHeader.current.setFieldsValue(formObject);
      this.setState({ formObject });
    }
    if (key === 'CreatedBy') {
      let selectedUser = usersList.filter((item) => item.Id === value);
      formObject = {
        ...formObject,
        [key]: value,
        Name: selectedUser[0]['UserName'],
        Phone: selectedUser[0]['MobileNumber'],
        Email: selectedUser[0]['Email']
      };
    } else if (
      key === 'TargetStart' ||
      key === 'TargetFinish' ||
      key === 'ActualStart' ||
      key === 'ActualFinish' ||
      key === 'RecordDate'
    ) {
      formObject = {
        ...formObject,
        [key]: moment(value).format('YYYY-MM-DD')
      };
    } else if (key === 'WorkType') {
      if (value === 2) {
        this.setState({
          viewCM: true,
          viewInspection: false
        });
        formObject = {
          ...formObject,
          [key]: value
        };
      } else if (value === 3) {
        this.setState({
          viewInspection: true,
          viewCM: false
        });
        formObject = {
          ...formObject,
          [key]: value
        };
      }
    } else {
      formObject = {
        ...formObject,
        [key]: value
      };
    }
    this.formHeader.current.setFieldsValue(formObject);
    this.setState({ [key]: value, formObject });
  };
  componentDidUpdate = async (prevProps) => {
    if (
      this.props.formObject &&
      Object.keys(this.props.formObject).length === 0 &&
      Object.keys(this.state.formObject).length === 0
    ) {
      this.onReset();
    }
    if (this.props.formObject && Object.keys(this.props.formObject).length === 0) {
      this.formHeader.current.setFieldsValue(this.props.formObject);
    }
    if (prevProps.formObject !== this.props.formObject) {
      this.setState({
        formObject: this.props.formObject
      });
    }
    if (prevProps.translationCache !== this.props.translationCache) {
      this.setState({
        translationCache: this.props.translationCache
      });
    }
  };
  onReset = () => {
    this.formHeader.current.resetFields();
  };
  onFinish = async (values) => {
    let { formObject } = this.state;
    let payload = {
      ...formObject,
      ...values
    };
    let statusResponse = await submitSR(payload);

    if (statusResponse && statusResponse.message) {
      this._getDataById(statusResponse.message);
      if (formObject && formObject.Id) {
        message.success('Service Request updated successfully');
      } else {
        message.success('Service Request submitted successfully');
      }

      // this.onReset();
      this.enableWorkOrder();
    } else if (statusResponse && statusResponse.Error) {
      message.error(statusResponse.Error);
    }
  };
  enableWorkOrder = () => {
    this.setState({
      workorderdisabled: false
    });
  };
  createWorkOrder = async () => {
    message.warn('Development inprogress');
  };

  fileupload = async (values, key) => {
    let { form } = this.state;

    form = {
      ...form,
      [key]: values
    };

    this.setState({ form });
  };
  _getDataById = async (SRId) => {
    let formObject = {};
    let sitesList = await getSitesList();
    let path = history.location.pathname.split('/rubus/AMM/SRDetails/');
    let formId = SRId ? SRId : base64_decode(path[1]);
    if (formId !== 'new') {
      let data = await getSRByID(formId);
      if (data && data.WorkType && data.WorkType === 2) {
        this.setState({ viewCM: true });
      } else if (data && data.WorkType && data.WorkType === 3) {
        this.setState({ viewInspection: true });
      } else {
        this.setState({
          viewCM: false,
          viewInspection: false
        });
      }
      formObject = data && data !== undefined ? data : {};
      _.cloneDeepWith(data, (value) => {
        return value && value !== null
          ? {
              ...value,
              ...(value['RecordDate'] = value.RecordDate
                ? moment.utc(value.RecordDate, 'YYYY-MM-DD HH:mm:ss')
                : null),
              ...(value['CreatedTime'] = moment
                .utc(value.CreatedTime, 'YYYY-MM-DD HH:mm:ss')
                .local()),
              ...(value['TargetStart'] = value.TargetStart
                ? moment.utc(value.TargetStart, 'YYYY-MM-DD')
                : null),
              ...(value['TargetFinish'] = value.TargetFinish
                ? moment.utc(value.TargetFinish, 'YYYY-MM-DD')
                : null)
            }
          : _.noop();
      });
      try {
        formObject = {
          ...formObject,
          SiteId: localStorage.get('currentSite') ? parseInt(localStorage.get('currentSite')) : ''
        };
        this.formHeader.current.setFieldsValue(formObject);
        this.setState({ sitesList: sitesList.data, formObject });
      } catch (error) {
        message.warn(`${error.message}`);
      }
      try {
        let statusList = await getStatus();
        this.setState({ statusList });
        if (formObject && !formObject.Id) {
          let WAPPRStatus =
            statusList && Array.isArray(statusList)
              ? statusList.filter((status) => status.Status === 'WAPPR')
              : [];
          let StatusId = WAPPRStatus && WAPPRStatus[0] ? WAPPRStatus[0].Id : undefined;
          this.handleChange(formObject['SR'], 'SRId');
          this.handleChange(StatusId, 'StatusId');
        }
      } catch (error) {
        message.warn(`${error.message}`);
      }
    }
  };
  getValueFromList = (list, compareKey, compareValue, resultKey) => {
    let filterObject = [];
    if (list && Array.isArray(list)) {
      filterObject = list.filter((listObject) => listObject[compareKey] === compareValue);
    }
    return filterObject && filterObject[0] && filterObject[0][resultKey]
      ? filterObject[0][resultKey]
      : '';
  };
  TableDropdownChange = async (value, modal) => {
    let { deviceList } = this.state;
    let formObject = this.formHeader.current.getFieldsValue();
    let selectedDevice = deviceList.filter((item) => item.AliasCode === value.AliasCode);
    let location = await getLocationByAsset(selectedDevice[0] && selectedDevice[0].Asset);
    formObject = {
      ...formObject,
      Device: value.AliasCode,
      DisplayCode: value.DisplayCode,
      DeviceCode: value.DeviceCode,
      EquipmentDescription: value.Name,
      Location: location.AssetCode,
      LocationDescription: location.Name
    };
    this.formHeader.current.setFieldsValue(formObject);
    this.setState({
      formObject,
      [modal]: false
    });
  };
  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };
  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: '' });
  };
  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={translation(this.props.translationCache, `Search`)}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          {translation(this.props.translationCache, `Search`)}
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          {translation(this.props.translationCache, `Reset`)}
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined, fontSize: '130%' }} />
    ),
    onFilter: (value, record) =>
      record &&
      record[dataIndex] &&
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text && text.toString()}
      />
    )
  });
  getDeviceData = (key) => {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    let headers = {
      method: 'GET',
      url: `/api/device/list?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    axios(headers)
      .then(({ data }) => {
        this.setState({
          tableData: data,
          [key]: true,
          formObject: this.formHeader.current.getFieldsValue()
        });
      })
      .catch(function () {});
  };
  onModalCancel = (e) => {
    if (e.target.tagName === 'DIV') {
      e.preventDefault();
      message.error('Please Click On Cancel');
    } else {
      this.setState({
        visible: false
      });
    }
  };
  onModalOk = () => {
    this.setState({
      visible: false
    });
  };

  render() {
    let { translationCache } = this.props;
    let {
      sitesList,
      formObject,
      usersList,
      statusList,
      workTypesList,
      viewCM,
      viewInspection
      // workorderdisabled
    } = this.state;
    return (
      <StyledComponent
        theme={themeSettings}
        style={{ padding: '0px 30px', minHeight: window.innerHeight - 143 }}
      >
        <StyledForm
          name="SR"
          theme={themeSettings}
          ref={this.formHeader}
          onFinish={this.onFinish}
          initialValues={formObject}
          layout="horizontal"
          labelCol={{ flex: '130px' }}
          labelAlign="right"
          labelWrap
          wrapperCol={{ flex: 1 }}
        >
          <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
            <StyledButton
              style={{ marginLeft: '5px' }}
              theme={themeSettings}
              htmlType="submit"
              // onClick={this.enableWorkOrder}
            >
              {translation(translationCache, constant.submit)}
            </StyledButton>
            <StyledButton
              // disabled={workorderdisabled}
              disabled={true}
              theme={themeSettings}
              htmlType="submit"
              onClick={this.createWorkOrder}
            >
              {translation(translationCache, constant.createWorkOrder)}
            </StyledButton>
          </div>
          <StyledBox>
            <StyledBoxLabel>
              {translation(translationCache, 'Service Request Details')}
            </StyledBoxLabel>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              <Form.Item name="Device"></Form.Item>
              <Form.Item
                style={{
                  width: '100%',
                  flex: '50%',
                  marginTop: '50px'
                }}
                label={translation(translationCache, constant.serviceRequestID)}
                name="SRId"
                rules={[{ required: true, message: 'Please Input JobPlan!' }]}
              >
                <InputComponent
                  style={{ width: '70%' }}
                  placeholder={translation(translationCache, 'ID')}
                  disabled={true}
                  // disabled={formObject && formObject.Id ? true : false}
                  value={formObject.JobPlanName}
                  onChange={(e) => this.handleChange(e.target.value, 'SRId')}
                />
              </Form.Item>
              <Form.Item
                style={{ width: '100%', flex: '50%', marginTop: '50px' }}
                label={translation(translationCache, constant.description)}
                name="Description"
                rules={[
                  { required: true, message: translation(translationCache, 'Input Description!') }
                ]}
              >
                <InputComponent
                  style={{ width: '70%' }}
                  placeholder={translation(translationCache, 'Input Description')}
                  onChange={(e) => this.handleChange(e.target.value, 'Description')}
                />
              </Form.Item>

              <Form.Item
                style={{ width: '100%', flex: '50%' }}
                label={translation(translationCache, constant.equipment)}
                name="DisplayCode"
                rules={[
                  { required: true, message: translation(translationCache, 'Select Equipment') }
                ]}
              >
                <Input
                  style={{
                    width: '70%',
                    flex: '50%'
                  }}
                  placeholder={translation(translationCache, 'Select Equipment')}
                  disabled={true}
                  suffix={
                    <DownOutlined
                      style={{
                        color: formObject && formObject.Id ? null : '#1a3b5c',
                        cursor: formObject && formObject.Id ? 'not-allowed' : null,
                        opacity: formObject && formObject.Id ? '0.8' : null
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        if (formObject && !formObject.Id) {
                          this.getDeviceData('visible');
                        }
                      }}
                    />
                  }
                />
              </Form.Item>
              <Form.Item
                style={{
                  width: '100%',
                  flex: '50%'
                  // marginLeft:'30px',
                  // display: formObject && formObject.Id ? 'none' : true
                }}
                label={translation(translationCache, constant.equipmentDescription)}
                name="EquipmentDescription"
                rules={[
                  {
                    required: true,
                    message: translation(
                      translationCache,
                      'Autopopulate based on equipment selection'
                    )
                  }
                ]}
              >
                <InputComponent
                  style={{ width: '70%' }}
                  //maxLength={150}
                  disabled
                  placeholder={translation(
                    translationCache,
                    'Autopopulate based on equipment selection'
                  )}
                />
              </Form.Item>
              <Form.Item
                style={{ width: '100%', flex: '50%' }}
                label={translation(translationCache, constant.Location)}
                name="Location"
                rules={[
                  {
                    required: true,
                    message: translation(
                      translationCache,
                      'Autopopulate based on equipment selection'
                    )
                  }
                ]}
              >
                <InputComponent
                  style={{ width: '70%' }}
                  placeholder={translation(
                    translationCache,
                    'Autopopulate based on equipment selection'
                  )}
                  disabled={true}
                />
              </Form.Item>
              <Form.Item
                style={{
                  width: '100%',
                  flex: '50%'
                  // display: formObject && formObject.Id ? 'none' : true
                }}
                label={translation(translationCache, constant.locationDescription)}
                name="LocationDescription"
                rules={[
                  {
                    required: true,
                    message: translation(
                      translationCache,
                      'Autopopulate based on equipment selection'
                    )
                  }
                ]}
              >
                <InputComponent
                  style={{ width: '70%' }}
                  placeholder={translation(
                    translationCache,
                    'Autopopulate based on equipment selection'
                  )}
                  disabled={true}
                />
              </Form.Item>

              <Form.Item
                style={{ width: '100%', flex: '50%' }}
                label={translation(translationCache, constant.status)}
                name="StatusId"
                rules={[{ required: true, message: 'Please Select Status' }]}
              >
                <Select
                  disabled={true}
                  placeholder={translation(translationCache, 'Select Status')}
                  onChange={(value) => this.handleChange(value, 'StatusId')}
                  style={{ width: '70%' }}
                >
                  {statusList &&
                    Array.isArray(statusList) &&
                    statusList.map((status, index) => {
                      return (
                        <Option key={index} value={status.Id}>
                          {translation(translationCache, status.StatusDescription)}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>

              <Form.Item
                style={{
                  width: '100%',
                  flex: '50%'
                  // display: formObject && formObject.Id ? 'none' : true
                }}
                label={translation(translationCache, constant.site)}
                name="SiteId"
                rules={[{ required: true, message: 'Please Select Site' }]}
              >
                <Select
                  placeholder={translation(translationCache, 'Select Site')}
                  onChange={(value) => this.handleChange(value, 'SiteId')}
                  style={{ width: '70%' }}
                >
                  {sitesList &&
                    Array.isArray(sitesList) &&
                    sitesList.map((site, index) => {
                      return (
                        <Option key={index} value={site.Id}>
                          {' '}
                          {site.Name}{' '}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>

              <Form.Item
                style={{ width: '100%', flex: '50%' }}
                label={translation(translationCache, constant.recordDate)}
                name="RecordDate"
                rules={[{ required: false, message: 'Select Record Date' }]}
              >
                <DatePicker
                  style={{ width: '70%' }}
                  placeholder={translation(translationCache, 'Select Record Date')}
                  // onChange={(value) => this.handleChange(value, 'RecordDate')}
                  format="YYYY-MM-DD"
                  // showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                  // disabledDate={(d) =>
                  //   //     this.formHeader.current.getFieldValue("EndDate")
                  //   //                 ? moment(this.formHeader.current.getFieldValue("EndDate")).add(1, "day").format("YYYY-MM-DD") : ""
                  //   //         ))
                  //   !d ||
                  //   d.isAfter(
                  //     this.formHeader.current.getFieldValue() &&
                  //       this.formHeader.current.getFieldValue()['RecordDate']
                  //       ? moment(this.formHeader.current.getFieldValue()['RecordDate'])
                  //         .add(1, 'day')
                  //         .format('YYYY-MM-DD')
                  //       : ''
                  //   ) ||
                  //   d.isBefore(
                  //     this.formHeader.current.getFieldValue() &&
                  //       this.formHeader.current.getFieldValue()['RecordDate'] &&
                  //       this.formHeader.current.getFieldValue()['RecordDate']
                  //       ? moment(this.formHeader.current.getFieldValue()['RecordDate']).format(
                  //         'YYYY-MM-DD'
                  //       )
                  //       : ''
                  //   ) ||
                  //   d.isAfter(moment().add(1, 'day').format('YYYY-MM-DD'))
                  // }
                />
              </Form.Item>
              <Form.Item
                style={{ width: '100%', flex: '50%' }}
                label={translation(translationCache, constant.createdBy)}
                name="CreatedBy"
                rules={[{ required: false, message: 'Select Created By' }]}
              >
                <Select
                  style={{ width: '70%' }}
                  disabled={true}
                  placeholder={translation(translationCache, 'Created By')}
                  onChange={(value) => this.handleChange(value, 'CreatedBy')}
                >
                  {usersList &&
                    Array.isArray(usersList) &&
                    usersList &&
                    usersList.map((users, index) => {
                      return (
                        <Option key={index} value={users.Id}>
                          {' '}
                          {users.FirstName}{' '}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>

              <Form.Item
                style={{ width: '100%', flex: '50%' }}
                label={translation(translationCache, constant.summary)}
                name="Summary"
                rules={[{ required: false, message: 'Please Input Field!' }]}
              >
                <TextArea
                  rows={3}
                  style={{ width: '70%' }}
                  placeholder={translation(translationCache, 'Input Summary')}
                />
              </Form.Item>

              <Form.Item
                style={{ width: '100%', flex: '50%' }}
                label={translation(translationCache, constant.CreatedDate)}
                name="CreatedTime"
                rules={[{ required: false, message: 'Created Date' }]}
              >
                <DatePicker
                  format="YYYY-MM-DD HH:mm:ss"
                  style={{ width: '70%' }}
                  disabled={true}
                  placeholder={translation(translationCache, 'Created Date')}
                />
              </Form.Item>
              <Form.Item style={{ width: '100%', flex: '50%' }}>
                <InputComponent style={{ display: 'none' }} />
              </Form.Item>
            </div>
          </StyledBox>
          {/* <StyledBox>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              <Form.Item
                label={translation(translationCache, constant.conditionPoint)}
                name="ConditionPoint"
                style={{ width: '100%', flex: '50%', marginTop: '40px' }}
                rules={[{ required: false }]}
              >
                <InputComponent
                  style={{ width: '70%' }}
                  placeholder={translation(translationCache, 'Autopopulate based on Location')}
                  disabled={true}
                  //maxLength={150}
                />
              </Form.Item>

              <Form.Item
                style={{ width: '100%', flex: '50%', marginTop: '40px' }}
                label={translation(translationCache, constant.inspectionArea)}
                name="InspectionArea"
              >
                <InputComponent
                  style={{ width: '70%' }}
                  placeholder={translation(translationCache, 'Autopopulate based on Location')}
                  disabled={true}
                  //maxLength={150}
                />
              </Form.Item>
            </div>
          </StyledBox> */}
          <StyledBox>
            <div style={{ margin: '10px' }}>
              {/* <StyledSubHeading>{translation(translationCache, constant.dates)}</StyledSubHeading> */}

              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                <Form.Item
                  label={translation(translationCache, constant.workType)}
                  name="WorkType"
                  style={{ width: '100%', flex: '50%', marginTop: '40px' }}
                  rules={[{ required: false }]}
                >
                  <Select
                    placeholder={translation(translationCache, 'Select Work Type')}
                    onChange={(value) => this.handleChange(value, 'WorkType')}
                    style={{ width: '70%' }}
                  >
                    {workTypesList.map((workType, index) => {
                      return (
                        <Option
                          key={index}
                          value={workType.Id}
                          disabled={
                            formObject &&
                            !formObject.id &&
                            workType.Id === 5 &&
                            workType.WorkType === 'Common Work'
                              ? false
                              : true
                          }
                        >
                          {translation(translationCache, workType.WorkType)}{' '}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>

                <Form.Item
                  style={{ width: '100%', flex: '50%', marginTop: '40px' }}
                  label={translation(translationCache, constant.TaskType)}
                  name="TaskType"
                >
                  <Select
                    style={{ width: '70%' }}
                    placeholder={translation(translationCache, 'Select Task')}
                    multiple
                    // defaultValue={selectedTask}
                    // disabled={formObject && formObject.Id ? true : false}
                    // onChange={(value) => this.onTaskSelection(value)}
                    notFoundContent={'Please Select Equipment For Tasks'}
                  >
                    <option value="observationType">
                      {translation(translationCache, constant.observationType)}
                    </option>
                    <option value="fieldDataType">
                      {translation(translationCache, constant.fieldDataType)}
                    </option>
                  </Select>
                </Form.Item>
                {viewCM === true ? (
                  <Form.Item
                    label={translation(translationCache, constant.conditionPoint)}
                    name="ConditionPoint"
                    style={{ width: '100%', flex: '50%' }}
                    rules={[{ required: false }]}
                  >
                    <InputComponent
                      style={{ width: '70%' }}
                      disabled={true}
                      placeholder={translation(translationCache, 'Input conditionPoint')}
                      // disabled={true}
                      //maxLength={150}
                    />
                  </Form.Item>
                ) : null}

                {viewInspection === true ? (
                  <Form.Item
                    style={{ width: '100%', flex: '50%' }}
                    label={translation(translationCache, constant.inspectionArea)}
                    name="InspectionArea"
                  >
                    <InputComponent
                      style={{ width: '70%' }}
                      placeholder={translation(
                        translationCache,
                        'Autopopulate based on Inspection'
                      )}
                      disabled={true}
                      //maxLength={150}
                    />
                  </Form.Item>
                ) : null}

                <Form.Item
                  style={{ width: '100%', flex: '50%' }}
                  label={translation(translationCache, 'Target Start')}
                  name="TargetStart"
                  rules={[{ required: false, message: 'Please Input TargetStart!' }]}
                >
                  <DatePicker
                    style={{ width: '70%' }}
                    placeholder={translation(translationCache, 'Select Date')}
                    // onChange={(value) => this.handleChange(value, 'TargetStart')}
                    format="YYYY-MM-DD"
                    // showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                    disabledDate={(d) =>
                      // //     this.formHeader.current.getFieldValue("EndDate")
                      // //                 ? moment(this.formHeader.current.getFieldValue("EndDate")).add(1, "day").format("YYYY-MM-DD") : ""
                      // //         ))
                      // !d ||
                      // d.isAfter(
                      //   this.formHeader.current.getFieldValue() &&
                      //     this.formHeader.current.getFieldValue()['TargetFinish']
                      //     ? moment(this.formHeader.current.getFieldValue()['TargetFinish'])
                      //         .add(1, 'day')
                      //         .format('YYYY-MM-DD')
                      //     : ''
                      // ) ||
                      // d.isBefore(
                      //   this.formHeader.current.getFieldValue() &&
                      //     this.formHeader.current.getFieldValue()['TargetStart'] &&
                      //     this.formHeader.current.getFieldValue()['TargetStart']
                      //     ? moment(this.formHeader.current.getFieldValue()['TargetStart']).format(
                      //         'YYYY-MM-DD'
                      //       )
                      //     : ''
                      // ) ||
                      // d.isAfter(moment().add(1, 'day').format('YYYY-MM-DD'))

                      !d ||
                      d.isAfter(
                        this.formHeader.current.getFieldValue() &&
                          this.formHeader.current.getFieldValue('TargetFinish')
                          ? moment(this.formHeader.current.getFieldValue('TargetFinish'))
                              .add(1, 'day')
                              .format('YYYY-MM-DD')
                          : ''
                      )
                    }
                  />
                </Form.Item>

                <Form.Item
                  style={{ width: '100%', flex: '50%' }}
                  label={translation(translationCache, 'Target Finish')}
                  name="TargetFinish"
                  rules={[{ required: false, message: 'Please Input Target Finish!' }]}
                >
                  <DatePicker
                    style={{ width: '70%' }}
                    placeholder={translation(translationCache, 'Please Select Date')}
                    // onChange={(value) => this.handleChange(value, 'TargetFinish')}
                    format="YYYY-MM-DD"
                    // showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                    disabledDate={(d) =>
                      //     this.formHeader.current.getFieldValue("EndDate")
                      //                 ? moment(this.formHeader.current.getFieldValue("EndDate")).add(1, "day").format("YYYY-MM-DD") : ""
                      //         ))
                      // !d ||
                      // d.isAfter(
                      //   this.formHeader.current &&
                      //     this.formHeader.current !== null &&
                      //     this.formHeader.current.getFieldValue() &&
                      //     this.formHeader.current.getFieldValue()['EndDate']
                      //     ? moment(
                      //         this.formHeader.current.getFieldValue() &&
                      //           this.formHeader.current.getFieldValue()['EndDate'] &&
                      //           this.formHeader.current.getFieldValue()['EndDate']
                      //       )
                      //         .add(1, 'day')
                      //         .format('YYYY-MM-DD')
                      //     : ''
                      // ) ||
                      // d.isBefore(
                      //   this.formHeader.current &&
                      //     this.formHeader.current !== null &&
                      //     this.formHeader.current.getFieldValue() &&
                      //     this.formHeader.current.getFieldValue()['TargetStart']
                      //     ? moment(
                      //         this.formHeader.current.getFieldValue() &&
                      //           this.formHeader.current.getFieldValue()['TargetStart'] &&
                      //           this.formHeader.current.getFieldValue()['TargetStart']
                      //       ).format('YYYY-MM-DD')
                      //     : ''
                      // ) ||
                      // d.isAfter(moment().add(1, 'day').format('YYYY-MM-DD'))

                      !d ||
                      d.isBefore(
                        this.formHeader.current &&
                          this.formHeader.current !== null &&
                          this.formHeader.current.getFieldValue() &&
                          this.formHeader.current.getFieldValue('TargetStart')
                          ? moment(
                              this.formHeader.current &&
                                this.formHeader.current !== null &&
                                this.formHeader.current.getFieldValue() &&
                                this.formHeader.current.getFieldValue('TargetStart')
                            ).format('YYYY-MM-DD')
                          : ''
                      )
                    }
                  />
                </Form.Item>
                <Form.Item style={{ width: '100%', flex: '50%' }} name={'Id'}></Form.Item>
                {/* <Form.Item
                  style={{ width: '100%', flex: '50%' }}
                  label={translation(translationCache, constant.actualStart)}
                  name="actualFinsh"
                  rules={[{ required: false, message: 'Please Input Actual Finish!' }]}
                >
                  <DatePicker
                    style={{ width: '70%' }}
                    placeholder={translation(translationCache, 'Please Select Date')}
                    onChange={(value) => this.handleChange(value, 'actualFinsh')}
                    format="YYYY-MM-DD"
                    // showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                    disabledDate={(d) =>
                      //     this.formHeader.current.getFieldValue("EndDate")
                      //                 ? moment(this.formHeader.current.getFieldValue("EndDate")).add(1, "day").format("YYYY-MM-DD") : ""
                      //         ))
                      !d ||
                      d.isAfter(
                        this.formHeader.current.getFieldValue() &&
                          this.formHeader.current.getFieldValue()['actualFinish']
                          ? moment(this.formHeader.current.getFieldValue()['actualFinish'])
                              .add(1, 'day')
                              .format('YYYY-MM-DD')
                          : ''
                      ) ||
                      d.isBefore(
                        this.formHeader.current.getFieldValue() &&
                          this.formHeader.current.getFieldValue()['actualFinsh'] &&
                          this.formHeader.current.getFieldValue()['actualFinsh']
                          ? moment(this.formHeader.current.getFieldValue()['actualFinsh']).format(
                              'YYYY-MM-DD'
                            )
                          : ''
                      ) ||
                      d.isAfter(moment().add(1, 'day').format('YYYY-MM-DD'))
                    }
                  />
                </Form.Item>

                <Form.Item
                  style={{ width: '100%', flex: '50%' }}
                  label={translation(translationCache, constant.actualFinish)}
                  name="actualFinish"
                  rules={[{ required: false, message: 'Please Input Actual Start!' }]}
                >
                  <DatePicker
                    style={{ width: '70%' }}
                    placeholder={translation(translationCache, 'Please Select Date')}
                    onChange={(value) => this.handleChange(value, 'actualFinish')}
                    format="YYYY-MM-DD"
                    disabledDate={(d) =>
                      //     this.formHeader.current.getFieldValue("EndDate")
                      //                 ? moment(this.formHeader.current.getFieldValue("EndDate")).add(1, "day").format("YYYY-MM-DD") : ""
                      //         ))
                      !d ||
                      d.isAfter(
                        this.formHeader.current &&
                          this.formHeader.current !== null &&
                          this.formHeader.current.getFieldValue() &&
                          this.formHeader.current.getFieldValue()['actualFinish']
                          ? moment(
                              this.formHeader.current.getFieldValue() &&
                                this.formHeader.current.getFieldValue()['actualFinish'] &&
                                this.formHeader.current.getFieldValue()['EndDate']
                            )
                              .add(1, 'day')
                              .format('YYYY-MM-DD')
                          : ''
                      ) ||
                      d.isBefore(
                        this.formHeader.current &&
                          this.formHeader.current !== null &&
                          this.formHeader.current.getFieldValue() &&
                          this.formHeader.current.getFieldValue()['actualFinsh']
                          ? moment(
                              this.formHeader.current.getFieldValue() &&
                                this.formHeader.current.getFieldValue()['actualFinsh'] &&
                                this.formHeader.current.getFieldValue()['actualFinsh']
                            ).format('YYYY-MM-DD')
                          : ''
                      ) ||
                      d.isAfter(moment().add(1, 'day').format('YYYY-MM-DD'))
                    }
                    // showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                  />
                </Form.Item> */}
              </div>
            </div>
          </StyledBox>
          {/* <StyledBox>
            <div style={{ margin: '10px' }}>
              {/* <StyledSubHeading>
              {translation(translationCache, constant.relatedRecords)}
            </StyledSubHeading> */}

          {/* <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                <Form.Item
                  style={{ width: '100%', flex: '50%', marginTop: '40px' }}
                  label={translation(translationCache, 'Work Order Id')}
                  name="WorkOrderId"
                  rules={[{ required: false, message: 'Please Input WorkOrder!' }]}
                >
                  <InputComponent
                    style={{ width: '70%' }}
                    placeholder={translation(translationCache, 'Input WorkOrder')}
                    onChange={(e) => this.handleChange(e.target.value, 'WorkOrderId')}
                  />
                </Form.Item>

                <Form.Item
                  style={{ width: '100%', flex: '50%', marginTop: '40px' }}
                  label={translation(translationCache, constant.description)}
                  name="WDDescription"
                >
                  <InputComponent
                    style={{ width: '70%' }}
                    placeholder={translation(translationCache, 'Input Description')}
                    onChange={(e) => this.handleChange(e.target.value, 'WDDescription')}
                  />
                </Form.Item>

                <Form.Item
                  style={{ width: '100%', flex: '50%' }}
                  label={translation(translationCache, constant.status)}
                  name="WDStatusId"
                >
                  <Select
                    style={{ width: '70%' }}
                    placeholder={translation(translationCache, 'Select Status')}
                    onChange={(value) => this.handleChange(value, 'WDStatusId')}
                  >
                    {statusList &&
                      Array.isArray(statusList) &&
                      statusList.map((status, index) => {
                        return (
                          <Option key={index} value={status.Id}>
                            {' '}
                            {status.Status}{' '}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
                <Form.Item style={{ width: '100%', flex: '50%' }}>
                  <InputComponent style={{ display: 'none' }} />
                </Form.Item>
              </div>
            </div> */}
          {/* </StyledBox> */}
          {/* <Form.Item style={{ width: '100%', flex: '50%', marginTop: '40px' }}>
            <StyledButton theme={themeSettings} htmlType="submit">
              {translation(translationCache, constant.submit)}
            </StyledButton>
          </Form.Item> */}
          <StyledModal
            open={this.state.visible}
            width={800}
            closable
            title={translation(translationCache, constant.selectEquipment)}
            onOk={this.onModalOk}
            onCancel={this.onModalCancel}
          >
            <TableSelect
              data={this.state.tableData}
              translationCache={this.props.translationCache}
              key="Location"
              columns={[
                {
                  title: translation(translationCache, 'Equipment'),
                  dataIndex: 'DisplayCode',
                  key: 'DisplayCode',
                  ...this.getColumnSearchProps('DisplayCode')
                },
                {
                  title: translation(translationCache, 'Equipment Description'),
                  dataIndex: 'Name',
                  key: 'Name',
                  ...this.getColumnSearchProps('Name')
                }
              ]}
              handleDropdownChange={(e) => this.TableDropdownChange(e, 'visible')}
            />
            {/* <HierarchyModal
                  value={this.state.deviceId}
                  treeData={this.state.treeData}
                  translationCache={this.props.translationCache}
                  language={this.props.language}
                  handleDropdownChange={this.hierarchyDropdownChange}
                  handleParentNode={this.parentNode}
                  deviceList={this.state.deviceList}
                /> */}
          </StyledModal>
        </StyledForm>
      </StyledComponent>
    );
  }
}
const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache()
});
export default connect(mapStateToProps)(ServiceRequestForm);

// <div style={{ background: '#0a2b4d', margin: '10px' }}>
// <StyledSubHeading>
//   {translation(translationCache, constant.userInformation)}{' '}
// </StyledSubHeading>
// <div style={{ display: 'flex', flexWrap: 'wrap' }}>
//   <Form.Item
//     style={{ width: '100%', flex: '50%' }}
//     label={translation(translationCache, constant.name)}
//     name="Name"
//     rules={[{ required: false, message: 'Please Input Field!' }]}
//   >
//     <InputComponent
//       style={{ width: '70%' }}
//       placeholder={translation(translationCache,"Enter Name"
//       disabled
//       value={formObject.JobPlanName}
//       onChange={(e) => this.handleChange(e.target.value, 'Name')}
//     />
//   </Form.Item>

//   <Form.Item
//     style={{ width: '100%', flex: '50%' }}
//     label={translation(translationCache, constant.phoneNumber)}
//     name="Phone"
//     rules={[{ required: false, message: 'Please Input Field!' }]}
//   >
//     <InputComponent
//       style={{ width: '70%' }}
//       placeholder={translation(translationCache,"Enter Phone"
//       disabled
//       value={formObject.JobPlanName}
//       onChange={(e) => this.handleChange(e.target.value, 'Phone')}
//     />
//   </Form.Item>

//   <Form.Item
//     style={{ width: '100%', flex: '50%' }}
//     label={translation(translationCache, constant.email)}
//     name="Email"
//     rules={[{ required: false, message: 'Please Input Field!' }]}
//   >
//     <InputComponent
//       style={{ width: '70%' }}
//       placeholder={translation(translationCache,"Enter Email"
//       disabled
//       value={formObject.JobPlanName}
//       onChange={(e) => this.handleChange(e.target.value, 'Email')}
//     />
//   </Form.Item>

//   <Form.Item
//     style={{ width: '100%', flex: '50%' }}
//     label={translation(translationCache, constant.classification)}
//     name="Classification"
//     rules={[{ required: false, message: 'Please Input Field!' }]}
//   >
//     <InputComponent
//       style={{ width: '70%' }}
//       placeholder={translation(translationCache,"Input Classification"
//       value={formObject.JobPlanName}
//       onChange={(e) => this.handleChange(e.target.value, 'Classification')}
//     />
//   </Form.Item>

//   <Form.Item
//     style={{ width: '100%', flex: '50%' }}
//     label={translation(translationCache, constant.priority)}
//     name="Priority"
//     rules={[{ required: false, message: 'Please Input Priority!' }]}
//   >
//     {/* <Select
//       style={{ width: '70%' }}
//       placeholder={translation(translationCache,"Select Priority"
//       onChange={(value) => this.handleChange(value, 'Priority')}
//     ></Select> */}
//     <InputComponent value={formObject.Priority} style={{ width: '70%' }} />
//   </Form.Item>

//   <Form.Item style={{ width: '100%', flex: '50%' }}>
//     <InputComponent style={{ display: 'none' }} />
//   </Form.Item>
// </div>
// </div>
