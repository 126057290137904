import axios from 'axios';
import localStorage from '../../../../../utils/localStorage';

export function getTreeData() {
  const token = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  let Authorization = 'Bearer ' + token;
  const getProcessArea = {
    method: 'POST',
    url: `/api/hierarchymodule/getHierarchyLevels?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    },
    data: {
      HierarchyId: 10
    }
  };
  return axios(getProcessArea)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}

export function _getMarkerList(payload) {
  const token = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  let Authorization = 'Bearer ' + token;
  const getProcessArea = {
    method: 'POST',
    url: `/api/device/getStatusDetails?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    },
    data: payload
  };
  return axios(getProcessArea)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
  // return [
  //   {
  //     "Code": "FRM_1",
  //     "Name": "Kanyakumari",
  //     "AliasCode": "21_FRM_1",
  //     "DisplayCode": "FRM_1",
  //     "Description": "Kanyakumari",
  //     "Maximum Output": "100kW",
  //     "Latitude": "25.360933",
  //     "Longitude": "55.375195",
  //     ImageURL: 'http://142.93.220.247:8000/api/masterImages/RS.png'
  //   },
  //   {
  //     "Code": "FRM_2",
  //     "Name": "Dhule",
  //     "AliasCode": "21_FRM_2",
  //     "DisplayCode": "FRM_2",
  //     "Description": "Dhule",
  //     "Maximum Output": "1MW",
  //     ImageURL: 'http://142.93.220.247:8000/api/masterImages/RS.png'
  //   },
  //   {
  //     "Code": "FRM_3",
  //     "Name": "Damanjodi",
  //     "AliasCode": "21_FRM_3",
  //     "DisplayCode": "FRM_3",
  //     "Description": "Damanjodi",
  //     "Maximum Output": "3MW",
  //     ImageURL: 'http://142.93.220.247:8000/api/masterImages/RS.png'
  //   }
  // ]
  // return [
  //   {
  //     Name: 'RS',
  //     Code: 'RS',
  //     Latitude: 25.360933,
  //     Longitude: 55.375195,
  //     Status: 'Running',
  //     ImageURL: 'http://142.93.220.247:8000/api/masterImages/RS.png'
  //   },
  //   {
  //     Name: 'ECH',
  //     Code: 'ECH-1',
  //     Latitude: 25.358082,
  //     Longitude: 55.370667,
  //     Status: 'Idle',
  //     ImageURL: 'http://142.93.220.247:8000/api/masterImages/RS.png'
  //   },
  //   {
  //     Name: 'RS-1',
  //     Code: 'RS-1',
  //     Latitude: 25.35717,
  //     Longitude: 55.373199,
  //     Status: 'Stopped',
  //     ImageURL: 'http://142.93.220.247:8000/api/masterImages/RS.png'
  //   },
  //   {
  //     Name: 'RS-2',
  //     Code: 'RS-2',
  //     Latitude: 25.358741,
  //     Longitude: 55.371482,
  //     Status: 'Running',
  //     Image: 'Yellow_crane',
  //     ImageURL: 'http://142.93.220.247:8000/api/masterImages/RS.png'
  //   },
  //   {
  //     Name: 'ECH-1',
  //     Code: 'ECH-1',
  //     Latitude: 25.362233,
  //     Longitude: 55.376032,
  //     Status: 'Idle',
  //     Image: 'Green_crane',
  //     ImageURL: 'http://142.93.220.247:8000/api/masterImages/RS.png'
  //   },
  //   {
  //     Name: 'ECH-1',
  //     Code: 'ECH-1',
  //     Latitude: 25.35942,
  //     Longitude: 55.374143,
  //     Status: 'Stopped',
  //     Image: 'Yellow_crane',
  //     ImageURL: 'http://142.93.220.247:8000/api/masterImages/RS.png'
  //   }
  // ];
}

export function getDataForPopup(payload) {
  // return {
  //   Power: 289,
  //   WindSpeed: '232',
  //   Production: 98789
  // };
  const token = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  let Authorization = 'Bearer ' + token;
  const getProcessArea = {
    method: 'POST',
    url: `/api/device/getPopupDetails?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    },
    data: payload
  };
  return axios(getProcessArea)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}

export function _getTableData(payload) {
  // return [
  //   {
  //     Site: 'Kanya Kumari',
  //     Code: 'KYKumari',
  //     Type: 'Asset',
  //     Status: 'running',
  //     Capacity: '30%',
  //     Power: '20%'
  //   },
  //   { Site: 'Srinagar', Code: 'SN', Type: 'Asset', Status: 'idle', Capacity: '100%', Power: '20%' }
  // ];
  const token = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  let Authorization = 'Bearer ' + token;
  const getProcessArea = {
    method: 'POST',
    url: `/api/device/getTableDetails?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    },
    data: payload
  };
  return axios(getProcessArea)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}
