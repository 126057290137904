import React from 'react';
import moment from 'moment';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

import { getTranslationCache } from '../../selectors/language';
import { getUserProfile } from '../../selectors/layout';
import { Button, ChatListContainer, ChatRow, SidebarContainer } from './CSS/styles';
import { getChatList } from './Utils/ApICalls';

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      chatList: []
    };
  }
  componentDidMount() {
    this.FetchChatList();
  }

  FetchChatList = async () => {
    let chatList = await getChatList();
    this.setState({ chatList });
  };

  onChatSelected = (chatId) => {
    this.props.onChatSelected(chatId);
  };

  onCreateChat = () => {
    let { chatList } = this.state;
    chatList.push({
      id: chatList.length + 1,
      created_time: moment().format('YYYY-MM-DD HH:mm:ss')
    });
    this.setState({ chatList });
    this.props.onChatSelected(chatList.length + 1);
  };

  onDeleteChat = () => {};

  render() {
    let { chatList } = this.state;
    let { selectedChatId } = this.props;
    return (
      <>
        <SidebarContainer>
          <ChatListContainer>
            <Button onClick={() => this.onCreateChat()}>+ New Chat</Button>
            {chatList.map((chat) => (
              <ChatRow
                key={chat.id}
                onClick={() => this.onChatSelected(chat.id)}
                isSelected={chat.id === selectedChatId}
              >
                <span>{chat.created_time}</span>
                <FontAwesomeIcon
                  icon={faTrash}
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent the chat row's onClick event from firing.
                    this.onDeleteChat(chat.id);
                  }}
                />
              </ChatRow>
            ))}
          </ChatListContainer>
        </SidebarContainer>
      </>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  userProfile: getUserProfile(),
  translationCache: getTranslationCache()
});
export default connect(mapStateToProps)(Sidebar);
