import React from 'react';
import axios from 'axios';
import { Upload, message, Table, Row, Col, Typography } from 'antd';
import { DownOutlined, InboxOutlined } from '@ant-design/icons';
import localStorage from '../../../../utils/localStorage';
import './style.css';
import { StyledTable, StyledAddButton, StyledDashboard } from './styles';

class AssetMapping extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fileName: '',
      DataSource: []
    };
  }

  componentDidMount = () => {
    this.getFile();
    this.getAssetMappingList();
  };

  getFile = () => {
    let accessToken = localStorage.get('accessToken');
    const headers = {
      method: 'GET',
      url: `/api/maximoasset/download`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    };
    axios(headers)
      .then((response) => {
        let fileList = [];
        fileList.push(response.data);
        this.setState({ fileName: fileList[0].file });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getAssetMappingList = () => {
    let accessToken = localStorage.get('accessToken');
    const headers = {
      method: 'GET',
      url: `/api/maximoasset/list`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    };
    axios(headers)
      .then((response) => {
        this.setState({ DataSource: response.data });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  beforeUpload = (file) => {
    const isLt2M = file.size / 1024 / 1024 < 10;
    const isCSV =
      file.type === 'text/csv' ||
      '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel';
    if (!isCSV) {
      message.error('You can only upload CSV file!');
    }
    if (!isLt2M) {
      message.error('CSV file must be smaller than 10MB!');
    }
    return isLt2M && isCSV;
  };
  handleChange = (info) => {
    const { status } = info.file;
    if (status === 'done') {
      message.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  render() {
    const { DataSource, fileName } = this.state;
    const AssetColumn = [
      {
        title: 'Id',
        dataIndex: 'Id',
        key: 'Id'
      },
      {
        title: 'Device',
        dataIndex: 'Device',
        key: 'Device'
      },
      {
        title: 'Asset',
        dataIndex: 'Asset',
        key: 'Asset'
      }
    ];
    const token = localStorage.get('accessToken');
    var Authorization = 'Bearer ' + token;
    return (
      <StyledDashboard className="EAMForm" style={{ minHeight: window.innerHeight - 173 }}>
        <Row>
          <Col span={10}>
            {' '}
            <div style={{ color: '#FFF', fontWeight: 'bold', fontSize: '18px', marginLeft: '5%' }}>
              File Download
            </div>
            <div className="ant-divider ant-divider-horizontal" role="separator"></div>{' '}
            {fileName !== '' || !undefined ? (
              <a href={`${process.env.REACT_APP_Primary_IP}/api/downloads/${fileName}`}>
                {' '}
                <StyledAddButton type={'primary'}>
                  <DownOutlined /> {fileName}
                </StyledAddButton>{' '}
              </a>
            ) : (
              <StyledAddButton>No Files</StyledAddButton>
            )}
          </Col>
          <Col offset={1} span={12}>
            <div style={{ color: '#FFF', fontWeight: 'bold', fontSize: '18px' }}>File Upload</div>
            <div className="ant-divider ant-divider-horizontal" role="separator"></div>
            <div className="antUpload" style={{ fontSize: '30px' }}>
              <Upload.Dragger
                name="file"
                accept="text/csv"
                action={`/api/maximoasset/upload`}
                headers={{
                  Authorization: Authorization
                }}
                multiple="false"
                beforeUpload={this.beforeUpload}
                onChange={this.handleChange}
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                <p className="ant-upload-hint">1. Your can only upload .CSV file</p>
                <p className="ant-upload-hint">2. File should be less than 10MB</p>
              </Upload.Dragger>
            </div>
          </Col>
        </Row>

        <div style={{ marginTop: '2%' }}>
          <Typography.Title
            level={5}
            style={{ margin: 0, color: '#fff', borderBottom: '1px solid #fff' }}
          >
            {' '}
            Asset List{' '}
          </Typography.Title>

          <div className="ant-divider ant-divider-horizontal" role="separator"></div>
          <StyledTable>
            <Table
              className="table"
              columns={AssetColumn}
              size="large"
              pagination={
                DataSource.length > 10
                  ? {
                      pageSize: '10'
                    }
                  : 1
              }
              dataSource={DataSource}
            ></Table>
          </StyledTable>
        </div>
      </StyledDashboard>
    );
  }
}

export default AssetMapping;
