import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';

import history from '../../../../../../../commons/history';
import { setMenuSelection } from '../../../../../../../modules/menu/menu.duck';

class LeftSidePanel extends React.Component {
  render() {
    let craneList = [
      {
        title: 'ECH',
        redirectURL: '/rubus/dashboard/ECH',
        image: `/api/masterImages/ECH.png`,
        redirectMenu: ['Modules', '12235', '12239']
      },
      {
        title: 'Reach Stacker',
        image: `/api/masterImages/RS.png`,
        redirectURL: '/rubus/dashboard/RS',
        redirectMenu: ['Modules', '12235', '12241']
      },
      {
        title: 'ITV',
        image: `/api/masterImages/ITV.png`,
        redirectURL: '/rubus/dashboard/ITV',
        redirectMenu: ['Modules', '12235', '12243']
      },
      {
        title: 'GIS Tracking',
        image: `/api/masterImages/GIS Tracking.png`
      },
      {
        title: 'MHC',
        image: `/api/masterImages/ST_MHC_V1.png`,
        redirectURL: '/rubus/HMI/distinct/MHC/deviceCode',
        redirectMenu: ['Modules', '1218', '1220']
      },
      {
        title: 'QC',
        image: `/api/masterImages/ST_QC_V1.png`,
        redirectURL: '/rubus/HMI/static/QC SVG',
        redirectMenu: ['Modules', '1136', '1138']
      },
      {
        title: 'RTG',
        image: `/api/masterImages/ST_RTG_V1.png`,
        redirectURL: '/rubus/HMI/static/RTG',
        redirectMenu: ['Modules', '1144', '1146']
      }
    ];
    return (
      <>
        {craneList &&
          Array.isArray(craneList) &&
          craneList.map((crane) => {
            return (
              <>
                <img
                  style={{ cursor: 'pointer' }}
                  height="60"
                  weight="60"
                  onClick={() => {
                    if (crane.redirectURL) {
                      this.props.actions.setMenuSelection(crane.redirectMenu);
                      history.push(crane.redirectURL);
                    }
                  }}
                  src={crane.image}
                />
                <div>{crane.title}</div>
              </>
            );
          })}
      </>
    );
  }
}

const mapStateToProps = createStructuredSelector({});
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        setMenuSelection
      },
      dispatch
    )
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(LeftSidePanel);
