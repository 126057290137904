import React from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';

import { getTranslationCache } from '../../selectors/language';
import { getUserProfile } from '../../selectors/layout';
import { AppContainer, ChatContainer } from './CSS/styles';
import Sidebar from './Sidebar';
import ChatBox from './ChatBox';
import ChatInput from './ChatInput';

class ChatApplication extends React.Component {
  timeTicket = null;
  requestCheck = true;

  constructor(props) {
    super(props);
    this.state = {
      currentChatId: '',
      loading: false,
      messages: [
        {
          sender: 'user',
          content: 'My User content'
        },
        {
          sender: 'bot',
          content: 'My Bot content'
        }
      ]
    };
  }

  onChatSelected = (currentChatId) => {
    this.setState({ currentChatId });
  };

  onNewUserMessage = (chatId, newMessage) => {
    let { messages } = this.state;
    messages.push(newMessage);
    this.setState({ messages });
  };

  onNewChatCreated = () => {};

  render() {
    let { currentChatId } = this.state;
    return (
      <>
        <AppContainer>
          <Sidebar onChatSelected={this.onChatSelected} selectedChatId={currentChatId} />
          <ChatContainer>
            <ChatBox messages={this.state.messages} isLoading={this.state.loading} />
            <ChatInput
              onNewUserMessage={this.onNewUserMessage}
              onNewChatCreated={this.onNewChatCreated}
              chatId={currentChatId}
            />
          </ChatContainer>
        </AppContainer>
      </>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  userProfile: getUserProfile(),
  translationCache: getTranslationCache()
});
export default connect(mapStateToProps)(ChatApplication);
