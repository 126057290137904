import React, { Fragment } from 'react';
import moment from 'moment';
import axios from 'axios';
import cloneDeep from 'lodash.clonedeep';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Spin, Tag } from 'antd';

import { DownloadOutlined } from '@ant-design/icons';
import { ErrorComponent, NoRecordComponent } from '../../Utils';
import ReactEcharts from '../../Echarts/Echarts';
import { treeGraphData } from '../../ChartCalls';
import { treeDataTranslation } from '../../../Utils/Adapter/translationAdapter';
import { getTranslationCache } from '../../../../../selectors/language';
import { makeSelectThemeing } from '../../../../../selectors/theme';
import localStorage from '../../../../../utils/localStorage';
import { treeMock } from '../../../Mock/treeMock';
import { defaultRefreshTime } from '../../constant.json';

class TreeGraph extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      option: {},
      data: [],
      loading: false,
      translationCache: props.translationCache || [],
      refreshDateTime: moment(),
      errorInAPI: false,
      noDataInDB: false,
      reportName: ''
    };
  }

  timeTicket = null;
  requestCheck = true;
  abortController = new window.AbortController();

  async componentDidMount() {
    if (this.timeTicket) {
      clearInterval(this.timeTicket);
    }
    this.getData(this.props);

    let refreshTime = '';
    if (this.props.graphprops.refreshTime) {
      refreshTime = this.props.graphprops.refreshTime * 60000;
    } else {
      refreshTime = defaultRefreshTime;
    }

    this.timeTicket = setInterval(() => {
      if (this.requestCheck) {
        this.getData(this.props, this.props.selectedThemeing);
      }
    }, refreshTime);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.graphprops !== this.props.graphprops ||
      prevProps.selectionDate !== this.props.selectionDate ||
      prevProps.selectionDateLive !== this.props.selectionDateLive ||
      prevProps.selectionMonth !== this.props.selectionMonth ||
      prevProps.selectionWeek !== this.props.selectionWeek ||
      prevProps.DeviceCode !== this.props.DeviceCode ||
      prevProps.shift !== this.props.shift ||
      prevProps.selectionDateRange !== this.props.selectionDateRange ||
      prevProps.dayMonthYear !== this.props.dayMonthYear ||
      prevProps.dropdownSelection !== this.props.dropdownSelection ||
      prevProps.dashboardPayload !== this.props.dashboardPayload ||
      prevProps.AssetCode !== this.props.AssetCode ||
      prevProps.translationCache !== this.props.translationCache
    ) {
      this.getData(this.props);
    }
  }

  getData = async (props) => {
    this.requestCheck = false;
    let json = [];
    let { graphprops } = this.props;
    let { errorInAPI, noDataInDB, reportName } = this.state;
    errorInAPI = false;
    noDataInDB = false;
    if (graphprops && graphprops.enableMock && graphprops.enableMock === true) {
      json = treeMock;
      this.requestCheck = true;
    } else {
      json = await treeGraphData(this.abortController);
      this.requestCheck = true;
    }
    if (
      (json && json.message) ||
      (Array.isArray(json) && json.length === 0 && graphprops.enableMock !== true)
    ) {
      if (json.message) {
        errorInAPI = true;
      } else if (json.length === 0) {
        noDataInDB = true;
      }
    } else {
      reportName = await this.getReportNametoDownload(json);
      const option = cloneDeep(this.getOption());
      if (json && Array.isArray(json)) {
        let series = await treeDataTranslation(json, this.props.translationCache);
        option.series[0].data = series;
        this.setState({
          option,
          reportName: reportName && reportName.file ? reportName.file : '',
          excelDownload: props.graphprops.enableExcel,
          refreshDateTime: moment()
        });
      }
    }
    this.setState({
      errorInAPI,
      noDataInDB
    });
  };

  getReportNametoDownload(excelData) {
    let payload = {
      Consumption: this.props.graphprops.title,
      Data: excelData || [],
      ...this.props.graphprops.excelObj,
      Name: this.props.graphprops.dashboardName,
      PortName: 'MEW_BMecomo',
      ReportType: 'singleHierarchy',
      Summary: [],
      SummaryReportType: 'singleHierarchy',
      SummaryType: 'false',
      WeklySummary: [],
      SelectedDate: moment().format('YYYY-MM-DD HH:mm:ss'),
      GeneratedDate: moment().format('YYYY-MM-DD HH:mm:ss')
    };

    let accessToken = localStorage.get('accessToken');
    let siteId = localStorage.get('currentSite');
    let options = {
      method: 'POST',
      url: `/api/file/generateExcel?SiteId=${siteId}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      },
      data: JSON.stringify(payload)
    };
    return axios(options)
      .then(({ data }) => {
        return data;
      })
      .catch(() => {
        return '';
      });
  }

  getOption = () => {
    let theme = this.props.selectedThemeing;
    let option = {
      tooltip: {
        trigger: 'item',
        triggerOn: 'mousemove'
      },
      toolbox: {
        showTitle: false,
        orient: 'vertical',
        itemSize: 25,
        feature: {
          saveAsImage: {
            title: 'download',
            backgroundColor: theme === 'lightTheme' ? 'black' : 'white',
            name: this.props.graphprops.title,
            iconStyle: {
              borderColor: '#8c8c8c',
              borderWidth: 2
            }
          }
        }
      },
      // toolbox: {
      //   showTitle: false,
      //   orient: "vertical",
      //   feature: {
      //     saveAsImage: {
      //       title: "download",
      //       name: this.props.graphprops.title,
      //       backgroundColor: "black"
      //     }
      //   }
      // },
      series: [
        {
          type: 'tree',

          data: [],

          top: '1%',
          left: '10%',
          bottom: '1%',
          right: '30%',
          symbol: 'roundRect',
          layout: 'orthogonal',
          orient: 'horizontal',
          symbolSize: 7,
          edgeShape: 'polyline',
          edgeForkPosition: '63%',
          initialTreeDepth: 1,

          label: {
            position: 'top',
            fontSize: 13,
            fontFamily: 'Arial Black',
            verticalAlign: 'middle',
            color: theme === 'lightTheme' ? 'black' : 'white'
          },

          leaves: {
            label: {
              position: 'right',
              verticalAlign: 'middle',
              align: 'left'
            }
          },
          lineStyle: {
            width: 1,
            color: 'steelblue'
          },

          expandAndCollapse: true,
          animationDuration: 550,
          animationDurationUpdate: 750
        }
      ]
    };
    return option;
  };

  componentWillUnmount() {
    this.abortController.abort();
    clearInterval(this.timeTicket);
  }

  render() {
    let { loading, errorInAPI, noDataInDB, refreshDateTime, reportName } = this.state;
    let { graphprops } = this.props;
    return (
      <span>
        {loading === false ? (
          <Fragment>
            {graphprops && graphprops.checked === true ? (
              <Tag className="graphTag" color="blue">
                {refreshDateTime ? moment(refreshDateTime).format('YYYY-MM-DD HH:mm:ss') : ''}
              </Tag>
            ) : null}
            {noDataInDB === true || errorInAPI === true ? (
              noDataInDB === true ? (
                <NoRecordComponent />
              ) : (
                <ErrorComponent />
              )
            ) : (
              <ReactEcharts
                option={this.state.option}
                notMerge={true}
                lazyUpdate={true}
                style={{ height: '90%', width: '100%' }}
                className="react_for_echarts"
              />
            )}
            {reportName !== '' ? (
              <a
                href={`${process.env.REACT_APP_Primary_IP}/api/reports/${reportName}`}
                style={{ fontSize: '20px', fontWeight: 'bold' }}
              >
                <DownloadOutlined
                  style={{ color: 'rgb(191, 191, 191)', fontSize: '20px', float: 'right' }}
                />
              </a>
            ) : null}
          </Fragment>
        ) : (
          <div
            style={{
              textAlign: 'center',
              color: 'white',
              marginTop: '10%'
            }}
          >
            <h2 style={{ color: 'white' }}>
              <Spin spinning={loading} tip="Loading..." size="large"></Spin>
            </h2>
          </div>
        )}
      </span>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache(),
  selectedThemeing: makeSelectThemeing()
});

export default connect(mapStateToProps)(TreeGraph);
