export const constants = {
  operatorList: [
    {
      value: 'AVG',
      label: 'Average'
    },
    {
      value: 'FIRST',
      label: 'First value'
    },
    {
      value: 'LAST',
      label: 'Last value'
    },
    {
      value: 'MAX',
      label: 'Max'
    },
    {
      value: 'MIN',
      label: 'Min'
    },
    {
      value: 'RAW',
      label: 'Raw value'
    },
    {
      value: 'SUM',
      label: 'Sum'
    }
  ],
  frequencyList: [
    {
      label: '1Min',
      value: '1m'
    },
    {
      label: '15Min',
      value: '15m'
    },
    {
      label: '30Min',
      value: '30m'
    },
    {
      label: '60Min',
      value: '1h'
    },
    {
      label: 'Day',
      value: '1d'
    }
  ]
};

// export const constant = {
//   operatorList: [{label:'MIN',
// value:'MIN'}, {label:'AVG',value:'AVG'}, {label:'MAX',value:'MAX'}]
// };

export const constant = {
  //***trends label start***//
  Select_Parameter: 'Select Parameter',
  Select_ParameterType: 'Select Parameter Type',
  Select_Operator: 'Select Operator',
  Select_Trend_Type: 'Select Trend Type',
  Select_Hierarchy: 'Select Hierarchy',
  Select_Device: 'Select Equipment',
  Select_Range: 'Select Range',
  Select_Range_Comparision: 'Select Range Comparison',
  Generate_Trend: 'Generate Trend',
  Single_View: 'Single View',
  Multiple_View: 'Multiple View',
  Bookmark_List: 'Bookmark List',
  Configure_TO_ViewTrends: 'Configure To View Trends',
  Enter_Bookmark_Name: 'Enter Bookmark Name',
  Save_Bookmark: 'Save Bookmark',
  Table: 'Table',
  Type: 'Select Type',
  Interval: 'Select Interval',
  ViewType: 'View Type',
  BookmarkName: 'Bookmark Name',
  Reset: 'Reset',
  Bookmark_Options: 'Bookmark Options'
  //***trends label stop***//
};
