import React from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';

import { getTranslationCache } from '../../selectors/language';
import { getCurrentTimezone, getUserProfile } from '../../selectors/layout';

import { StyledMain } from './Utils/CSS/styles';
import FilterComponent from './FilterComponent';
import DataComponent from './DataComponent';

class Trends extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filterData: []
    };
  }
  getFilterationData = (filterData, allFilterData) => {
    this.setState({
      filterData,
      allFilterData
    });
  };

  render() {
    let { filterData, allFilterData } = this.state;
    return (
      <StyledMain style={{ minHeight: window.innerHeight - 73 }}>
        <FilterComponent generateData={this.getFilterationData} />
        <DataComponent FilterData={filterData} AllFilterData={allFilterData} />
      </StyledMain>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  userProfile: getUserProfile(),
  translationCache: getTranslationCache(),
  currentTimeZone: getCurrentTimezone()
});
export default connect(mapStateToProps)(Trends);
