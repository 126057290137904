import { createSelector } from 'reselect';
const translation = (state) => {
  return state.get('translation').get('translation');
};

const getCurrentLanguage = () =>
  createSelector(translation, (state) => {
    return state.get('currentLanguage');
  });

const getTranslationCache = () =>
  createSelector(translation, (state) => {
    return state.get('translationCache');
  });

export { getCurrentLanguage, getTranslationCache };
