import React from 'react';
import moment from 'moment';
import axios from 'axios';
import history from '../../../commons/history';
import { DatePicker, Radio, Select, Menu, Button } from 'antd';
import { getTranslationCache } from '../../../selectors/language';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  Table,
  Guage,
  GridGuage,
  Pie,
  PieRose,
  SingleLineDiagram,
  Donut,
  Counter,
  CompassGuage,
  Funnel,
  Bar,
  CandleStick,
  SingleBar,
  Line,
  Area,
  ProgressBar,
  Regression,
  PieBar,
  BubbleChart,
  CandleStickBrush,
  TreeGraph,
  BoxPlot,
  BeijingLine,
  LiquidGuage,
  SingleCandleStick,
  BarTable,
  StatusCounter,
  Scatter,
  HeatmapCartesion,
  HeatmapCalendar,
  Heatmap,
  DottedBar,
  PieSingleLine,
  PieLine,
  CardsList,
  WindBarb,
  StackedBar,
  StackedLine,
  FeedbackTable,
  ImageGalleryWithPreview,
  LiveStreaming,
  GuageRing,
  NotificationTable,
  SelectImageDetails,
  PieNestComponent,
  LineTable,
  SliderStatus,
  GuageLine,
  NotificationScatter,
  LineBarArea,
  CounterLine,
  GeoMap,
  SunBurst,
  DonutCounter,
  CountersPie,
  Video,
  LineTableCounter,
  Report,
  Image,
  LineWithSelection,
  Predictions
} from '../ChartComponents';
import localStorage from '../../../utils/localStorage';
import { DateSelection } from './CSS/styles';

const { RangePicker, WeekPicker } = DatePicker;
const { Option } = Select;

class Graph extends React.Component {
  constructor(props) {
    super(props);
    let { selectionDate, selectionDateLive, graphprops } = props;
    // let startDateSelected = moment(dayMonthYear.startDate).format('YYYY-MM-DD 00:00:00');
    // let endDateSelected = moment(dayMonthYear.endDate).format('YYYY-MM-DD 23:59:59');
    this.state = {
      graphprops:
        {
          ...graphprops,
          dateExists: 'true'
        } || {},
      selection: {
        selectionDate: moment(selectionDate, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'),
        selectionDateLive: moment(selectionDateLive, 'YYYY-MM-DD HH:mm:ss').format(
          'YYYY-MM-DD HH:mm:ss'
        ),
        selectionDateRange: {
          startDate: moment().subtract(1, 'hour').format('YYYY-MM-DD HH:mm'),
          endDate: moment().format('YYYY-MM-DD HH:mm'),
          timeBucket: '1m'
        }
        // dayMonthYear: {
        //   timeBucket: '1h',
        //   selectionType: 'day',
        //   ...dayMonthYear,
        //   startDate: moment(startDateSelected).format('YYYY-MM-DD HH:mm:ss'),
        //   endDate: moment(endDateSelected).formOption
      },

      currentDate: moment(),
      endDate: moment(),
      disableNext: false,
      currentTab: 'today',
      week: moment().startOf('week'),
      month: moment().format('MMMM'),
      year: moment().year(),
      payload:
        {
          startDate: moment().format('YYYY-MM-DD 00:00:00'),
          endDate: moment().format('YYYY-MM-DD 23:59:59'),
          timeBucket: '1h'
        } || {}
    };
  }

  componentDidMount() {
    let { graphprops } = this.props;
    if (graphprops && graphprops.selection && graphprops.selection === 'deviceSelection') {
      this.getOptionList();
    }
  }

  onChangeDatePicker = (value, dateString) => {
    this.setState({
      selection: {
        ...this.state.selection,
        selectionDate: moment(dateString).format('YYYY-MM-DD 00:00:00')
      }
    });
  };
  onChangeDatePickerLive = (value, dateString) => {
    this.setState({
      selection: {
        ...this.state.selection,
        selectionDateLive: moment(dateString).format('YYYY-MM-DD 00:00:00')
      }
    });
  };
  disabledDate = (current) => {
    return current >= moment().endOf('day');
  };

  onDateRangePickerChange = (currentDate) => {
    let selectionDateRange = {};
    if (currentDate && currentDate[0] && currentDate[1]) {
      selectionDateRange = {
        startDate: currentDate[0].format('YYYY-MM-DD HH:mm:ss'),
        endDate: currentDate[1].format('YYYY-MM-DD HH:mm:ss'),
        timeBucket: '1m'
      };
    }
    this.setState({
      selection: {
        ...this.state.selection,
        selectionDateRange
      }
    });
  };

  disabledDate = (current) => {
    return current > moment().add(1, 'day').startOf('day');
  };

  onChange = async (date) => {
    let startDateSelected = moment(date).format('YYYY-MM-DD 00:00:00');
    let endDateSelected = moment(date).format('YYYY-MM-DD 23:59:59');
    let payload = {
      startDate: moment(startDateSelected).format('YYYY-MM-DD HH:mm:ss'),
      endDate: moment(endDateSelected).format('YYYY-MM-DD HH:mm:ss'),
      timeBucket: '1h',
      selectionType: 'day'
    };

    this.setState({
      currentDate: date,
      selection: {
        ...this.state.selection,
        dayMonthYear: payload
      }
    });
  };

  onRadioChange = (e) => {
    if (e.target.value === 'month') {
      let currentMonth = moment().format('MMMM');
      let currentYear = moment().year();
      this.handleMenuClick(currentMonth, 'month');
      this.setState({
        currentTab: e.target.value,
        month: currentMonth,
        year: currentYear
      });
    } else if (e.target.value === 'year') {
      let currentYear = moment().year();
      this.handleMenuClick(currentYear, 'year');
      this.setState({
        currentTab: e.target.value,
        year: currentYear
      });
    } else if (e.target.value === 'week') {
      let currentWeek = moment().startOf('isoWeek');
      this.handleMenuClick(currentWeek, 'week');
      this.setState({
        currentTab: e.target.value,
        week: currentWeek
      });
    } else {
      this.onChange(moment());
      this.setState({
        currentTab: e.target.value
      });
    }
  };

  getNavMenuItems = (menusData) => {
    if (!menusData) {
      return [];
    }
    return menusData
      .map((item) => {
        return <Menu.Item key={item}>{item}</Menu.Item>;
      })
      .filter((item) => item);
  };

  handleMenuClick = async (value, parent) => {
    if (parent === 'month') {
      let currentYear = moment(this.state.year, 'YYYY').year();
      let month = moment(value, 'MMMM').month();
      let payload = {
        startDate: moment([currentYear, month]).startOf('month').format('YYYY-MM-DD HH:mm:ss'),
        endDate:
          currentYear === moment().year() && month === parseInt(moment().format('M') - 1)
            ? moment().format('YYYY-MM-DD HH:mm:ss')
            : moment([currentYear, month]).endOf('month').format('YYYY-MM-DD HH:mm:ss'),
        timeBucket: '1d',
        selectionType: 'month'
      };
      this.setState({
        currentDate: value,
        selection: {
          ...this.state.selection,
          dayMonthYear: payload
        },
        month: value
      });
    } else if (parent === 'year') {
      let { currentTab } = this.state;
      let payload = {};
      if (currentTab && currentTab === 'month') {
        let currentYear = moment(value, 'YYYY').year();
        let month = moment(this.state.month, 'MMMM').month();
        payload = {
          startDate: moment([currentYear, month]).startOf('month').format('YYYY-MM-DD HH:mm:ss'),
          endDate:
            currentYear === moment().year() && month === parseInt(moment().format('M') - 1)
              ? moment().format('YYYY-MM-DD HH:mm:ss')
              : moment([currentYear, month]).endOf('month').format('YYYY-MM-DD HH:mm:ss'),
          timeBucket: '1d',
          selectionType: 'month'
        };
      } else {
        payload = {
          startDate: moment([value, 0]).startOf('month').format('YYYY-MM-DD HH:mm:ss'),
          endDate:
            value === moment().year()
              ? moment().format('YYYY-MM-DD HH:mm:ss')
              : moment([value, 11]).endOf('month').format('YYYY-MM-DD HH:mm:ss'),
          timeBucket: '1 month',
          selectionType: 'year'
        };
      }
      this.setState({
        currentDate: value,
        selection: {
          ...this.state.selection,
          dayMonthYear: payload
        },
        year: value
      });
    } else if (parent === 'week') {
      let currentWeek = moment().startOf('isoWeek');
      let payload = {
        startDate: moment(value).startOf('week').format('YYYY-MM-DD HH:mm:ss'),
        endDate: value.isSame(currentWeek)
          ? moment().format('YYYY-MM-DD HH:mm:ss')
          : moment(value).endOf('week').format('YYYY-MM-DD HH:mm:ss'),
        timeBucket: '1d',
        selectionType: 'week'
      };
      this.setState({
        currentDate: value,
        payload,
        week: moment(value).startOf('week'),
        selection: {
          ...this.state.selection,
          dayMonthYear: payload
        }
      });
    }
  };

  onButtonClick = () => {
    history.push(`/rubus/LogBook/Power Sales`);
  };

  renderGraph = (graphprops, selection) => {
    let graphNewProps = {
      ...this.props,
      ...this.props.selection,
      ...selection
    };
    switch (graphprops.graph) {
      case 'guage':
        return <Guage {...graphNewProps} />;
      case 'GridGuage':
        return <GridGuage {...graphNewProps} />;
      case 'singleBar':
        return <SingleBar {...graphNewProps} />;
      case 'image':
        return <SingleLineDiagram {...graphNewProps} />;
      case 'counter':
        return <Counter {...graphNewProps} />;
      case 'counterLine':
        return <CounterLine {...graphNewProps} />;
      case 'compassGuage':
        return <CompassGuage {...graphNewProps} />;
      case 'bar':
        return <Bar {...graphNewProps} />;
      case 'table':
        return <Table {...graphNewProps} />;
      case 'candleStick':
        return <CandleStick {...graphNewProps} />;
      case 'line':
        return <Line {...graphNewProps} />;
      case 'progressBar':
        return <ProgressBar {...graphNewProps} />;
      case 'funnel':
        return <Funnel {...graphNewProps} />;
      case 'donut':
        return <Donut {...graphNewProps} />;
      case 'pie':
        return <Pie {...graphNewProps} />;
      case 'pieRose':
        return <PieRose {...graphNewProps} />;
      case 'area':
        return <Area {...graphNewProps} />;
      case 'regression':
        return <Regression {...graphNewProps} />;
      case 'pieBar':
        return <PieBar {...graphNewProps} />;
      case 'bubbleChart':
        return <BubbleChart {...graphNewProps} />;
      case 'candleStickBrush':
        return <CandleStickBrush {...graphNewProps} />;
      case 'treeGraph':
        return <TreeGraph {...graphNewProps} />;
      case 'boxPlot':
        return <BoxPlot {...graphNewProps} />;
      case 'liquidGuage':
        return <LiquidGuage {...graphNewProps} />;
      case 'beijingLine':
        return <BeijingLine {...graphNewProps} />;
      case 'singleCandleStick':
        return <SingleCandleStick {...graphNewProps} />;
      case 'barTable':
        return <BarTable {...graphNewProps} />;
      case 'lineTable':
        return <LineTable {...graphNewProps} />;
      case 'statusCounter':
        return <StatusCounter {...graphNewProps} />;
      case 'scatter':
        return <Scatter {...graphNewProps} />;
      case 'HeatmapCartesion':
        return <HeatmapCartesion {...graphNewProps} />;
      case 'heatmapCalendar':
        return <HeatmapCalendar {...graphNewProps} />;
      case 'heatmap':
        return <Heatmap {...graphNewProps} />;
      case 'dottedBar':
        return <DottedBar {...graphNewProps} />;
      case 'guageLine':
        return <GuageLine {...graphNewProps} />;
      case 'pieSingleLine':
        return <PieSingleLine {...graphNewProps} />;
      case 'pieLine':
        return <PieLine {...graphNewProps} />;
      case 'cardsList':
        return <CardsList {...graphNewProps} />;
      case 'windBarb':
        return <WindBarb {...graphNewProps} />;
      case 'stackedBar':
        return <StackedBar {...graphNewProps} />;
      case 'stackedLine':
        return <StackedLine {...graphNewProps} />;
      case 'feedbackTable':
        return <FeedbackTable {...graphNewProps} />;
      case 'ImageGalleryWithPreview':
        return <ImageGalleryWithPreview {...graphNewProps} />;
      case 'liveStreaming':
        return <LiveStreaming {...graphNewProps} />;
      case 'GuageRing':
        return <GuageRing {...graphNewProps} />;
      case 'notificationTable':
        return <NotificationTable {...graphNewProps} />;
      case 'selectImageDetails':
        return <SelectImageDetails {...graphNewProps} />;
      case 'pieNest':
        return <PieNestComponent {...graphNewProps} />;
      case 'sliderStatus':
        return <SliderStatus {...graphNewProps} />;
      case 'notificationScatter':
        return <NotificationScatter {...graphNewProps} />;
      case 'LineBarArea':
        return <LineBarArea {...graphNewProps} />;
      case 'GeoMap':
        return <GeoMap {...graphNewProps} />;
      case 'SunBurst':
        return <SunBurst {...graphNewProps} />;
      case 'DonutCounter':
        return <DonutCounter {...graphNewProps} />;
      case 'CountersPie':
        return <CountersPie {...graphNewProps} />;
      case 'Video':
        return <Video {...graphNewProps} />;
      case 'LineTableCounter':
        return <LineTableCounter {...graphNewProps} />;
      case 'Report':
        return <Report {...graphNewProps} />;
      case 'Image':
        return <Image {...graphNewProps} />;
      case 'LineWithSelection':
        return <LineWithSelection {...graphNewProps} />;
      case 'Predictions':
        return <Predictions {...graphNewProps} />;

      default:
        return <div style={{ color: 'white' }}>{`Graph Doesn't exists`}</div>;
    }
  };

  getOptionList = () => {
    let { selection } = this.state;
    let accessToken = localStorage.get('accessToken');
    let siteId = localStorage.get('currentSite');
    const headers = {
      method: 'POST',
      url: `/api/landingPage/landingpagedeviceStatus?SiteId=${siteId}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    };
    axios(headers).then((response) => {
      if (response.data) {
        let data = response.data;
        let optionList = [];
        data &&
          Array.isArray(data) &&
          data.map((ZoneData) => {
            return (optionList = [
              ...optionList,
              ...(ZoneData && ZoneData['ListOfDevices'] ? ZoneData['ListOfDevices'] : [])
            ]);
          });

        this.setState({
          optionList,
          selection: {
            ...selection,
            deviceId: optionList && Array.isArray(optionList) ? optionList[0].deviceCode : ''
          },
          deviceId: optionList && Array.isArray(optionList) ? optionList[0].deviceCode : ''
        });
      }
    });
  };

  disabledDate = (current) => {
    return current > moment().endOf('day');
  };
  translation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };
  render() {
    let { graphprops } = this.props;
    let { selection, currentTab, optionList, deviceId } = this.state;
    if (moment().year() > 2020) {
      var yearList = [];
      for (var i = 2020; i <= moment().year(); i++) {
        yearList.push(i);
      }
      yearList.sort((a, b) => b - a);
    }

    return (
      <span>
        {/* {graphprops && graphprops.selection === 'datePicker' ? (
          <DateSelection>
            <DatePicker
              format="YYYY-MM-DD"
              value={moment(selection.selectionDate)}
              onChange={this.onChangeDatePicker}
              disabledDate={this.disabledDate}
              allowClear={false}
            />
          </DateSelection>
        ) : null} */}
        {graphprops && graphprops.selection === 'datePickerLive' ? (
          <DateSelection>
            <DatePicker
              format="YYYY-MM-DD"
              value={moment(selection.selectionDateLive)}
              onChange={this.onChangeDatePickerLive}
              disabledDate={this.disabledDate}
              allowClear={false}
            />
          </DateSelection>
        ) : null}
        {graphprops && graphprops.selection === 'dateRangePicker' ? (
          <DateSelection>
            <RangePicker
              format="YYYY-MM-DD HH:mm:ss"
              placeholder={['Start Date', 'End Date']}
              onChange={this.onDateRangePickerChange}
              value={[
                moment(selection.selectionDateRange.startDate),
                moment(selection.selectionDateRange.endDate)
              ]}
              style={{
                width: '400px',
                cursor: 'pointer'
              }}
              disabledDate={this.disabledDate}
            />
          </DateSelection>
        ) : null}
        {graphprops && graphprops.selection === 'dateMonthYearPicker' ? (
          <DateSelection>
            <Radio.Group
              defaultValue={this.state.currentTab}
              onChange={this.onRadioChange}
              style={{ marginLeft: '20px' }}
            >
              <Radio.Button value="today">{this.translation('Daily')}</Radio.Button>
              {/* <Radio.Button value="week">Week</Radio.Button> */}
              <Radio.Button value="month">{this.translation('Month')}</Radio.Button>
              <Radio.Button value="year">{this.translation('Year')}</Radio.Button>
              {graphprops.title === 'Power Sales & Performance' && graphprops.graph === 'table' ? (
                <span style={{ marginLeft: '20px' }}>
                  <Button value="year" onClick={this.onButtonClick}>
                    {this.translation('Redirect to Logbook')}
                  </Button>
                </span>
              ) : null}
            </Radio.Group>

            {currentTab === 'today' ? (
              <DatePicker
                onChange={this.onChange}
                value={moment(this.state.currentDate)}
                disabledDate={this.disabledDate}
              />
            ) : null}
            {currentTab === 'week' ? (
              <WeekPicker
                value={this.state.week}
                style={{ width: '200px' }}
                disabledDate={this.disabledDate}
                placeholder="Select Week"
                onChange={(e) => this.handleMenuClick(e, 'week')}
              />
            ) : null}
            {currentTab === 'month' ? (
              <Select
                value={this.state.month}
                onChange={(e) => this.handleMenuClick(e, 'month')}
                style={{ width: '100px' }}
              >
                {moment.months().map((value, index) => {
                  return (
                    <Option key={index} value={value}>
                      {value}
                    </Option>
                  );
                })}
              </Select>
            ) : null}
            {currentTab === 'year' || currentTab === 'month' ? (
              <Select
                value={this.state.year}
                onChange={(e) => this.handleMenuClick(e, 'year')}
                style={{ width: '100px' }}
              >
                {yearList.map((value, index) => {
                  return (
                    <Option key={index} value={value}>
                      {value}
                    </Option>
                  );
                })}
              </Select>
            ) : null}
          </DateSelection>
        ) : null}

        {graphprops && graphprops.selection === 'deviceSelection' ? (
          <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
            <Select
              onChange={(deviceId) =>
                this.setState({ deviceId, selection: { ...selection, deviceId } })
              }
              value={deviceId}
              style={{ width: 220 }}
            >
              {optionList &&
                Array.isArray(optionList) &&
                optionList.map((trainData, index) => {
                  return (
                    <Select.Option key={index} value={trainData.deviceCode}>
                      {trainData.deviceName}
                    </Select.Option>
                  );
                })}
            </Select>
          </div>
        ) : null}

        {this.renderGraph(graphprops, selection)}
      </span>
    );
  }
}

// export default Graph;

const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache()
});

export default connect(mapStateToProps)(Graph);
