/**
 * @author Swathi
 * @description This Component is mainly for Creating Jobplans and Task
 * @View Table with crud operations of Jobplanes
 */
import React from 'react';
import moment from 'moment';
// import _ from 'lodash';
import { Button, Form, Input, message, Select } from 'antd';
import { connect } from 'react-redux';
import { constant } from '../../../Utils/constants';
import { createStructuredSelector } from 'reselect';
import { decode as base64_decode } from 'base-64';

import {
  getSitesList,
  getParameterList,
  getCmpointList,
  getwarningTypeList,
  getnotificationTypeList,
  submitConditionMonitor,
  getWorkOrderName,
  getConditionMonitorById,
  getUsersList,
  checkLimit
} from '../../../Utils/FormCalls';
import translation from '../../../Utils/translation';
import history from '../../../../../commons/history';
import { ToolsForm } from './TaskRequirements';
//  import FileBase64 from '../../../Utils/FileUpload';
import themeSettings from '../../../Utils/themeSettings.json';
import { getTranslationCache } from '../../../../../selectors/language';
import {
  StyledButton,
  StyledComponent,
  StyledForm,
  StyledModal,
  StyledSubForm
} from '../../../CSS/style';
import InputComponent from '../../../Widgets/InputComponent';
import localStorage from '../../../../../utils/localStorage';
import { getUserProfile } from '../../../../../selectors/layout';
import TableSelect from '../../../Widgets/TableSelect';
import { DownOutlined, SearchOutlined } from '@ant-design/icons';
import axios from 'axios';
import Highlighter from 'react-highlight-words';
const { Option } = Select;
const fileData = new FormData();
class ConditionMonitoring extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sitesList: [],
      parameterList: [],
      cmpointList: [],
      equipmentList: [],
      warningTypeList: [],
      notificationTypeList: [],
      workTypesList: [],
      statusList: [],
      formObject: {},
      selectedJobPlan:
        props.formObject && props.formObject.JobPlanName ? props.formObject.JobPlanName : '',
      jobplanActivekey: '1',
      datasource: []
    };
  }
  conditionMonitorRef = React.createRef();

  componentDidMount = async () => {
    this._getDataById();
    try {
      let notificationTypeList = await getnotificationTypeList();
      let Sr =
        notificationTypeList && Array.isArray(notificationTypeList)
          ? notificationTypeList.filter((status) => status.Key === 'ServiceRequest')
          : [];
      let SrId = Sr && Sr[0] ? Sr[0].Value : undefined;
      let Wo =
        notificationTypeList && Array.isArray(notificationTypeList)
          ? notificationTypeList.filter((status) => status.Key === 'WorkOrder')
          : [];
      let WoId = Wo && Wo[0] ? Wo[0].Value : undefined;
      let Slack =
        notificationTypeList && Array.isArray(notificationTypeList)
          ? notificationTypeList.filter((status) => status.Key === 'Slack')
          : [];
      let SlackId = Slack && Slack[0] ? Slack[0].Value : undefined;
      let Email =
        notificationTypeList && Array.isArray(notificationTypeList)
          ? notificationTypeList.filter((status) => status.Key === 'Email')
          : [];
      let EmailId = Email && Email[0] ? Email[0].Value : undefined;
      this.setState({
        EmailId,
        SlackId,
        WoId,
        SrId,
        notificationTypeList: notificationTypeList
      });
    } catch (error) {
      message.warn(`${error.message}`);
    }
    try {
      let warningTypeList = await getwarningTypeList();
      this.setState({ warningTypeList: warningTypeList });
    } catch (error) {
      message.warn(`${error.message}`);
    }
    try {
      let sitesList = await getSitesList();
      let formObject = this.conditionMonitorRef.current.getFieldsValue();
      formObject = {
        ...formObject,
        SiteId: localStorage.get('currentSite') ? parseInt(localStorage.get('currentSite')) : ''
      };
      this.conditionMonitorRef.current.setFieldsValue(formObject);
      this.setState({ sitesList: sitesList, formObject });
    } catch (error) {
      message.warn(`${error.message}`);
    }
    try {
      let cmpointList = await getCmpointList();
      this.setState({ cmpointList });
    } catch (error) {
      message.warn(`${error.message}`);
    }
    try {
      let usersList = await getUsersList();
      this.setState({ usersList: usersList });
    } catch (error) {
      message.warn(`${error.message}`);
    }
  };
  componentDidUpdate = async (prevProps) => {
    if (
      this.props.formObject &&
      Object.keys(this.props.formObject).length === 0 &&
      Object.keys(this.state.formObject).length === 0
    ) {
      this.onReset();
    }
    if (prevProps.translationCache !== this.props.translationCache) {
      this.setState({
        translationCache: this.props.translationCache
      });
    }
    if (this.props.formObject && Object.keys(this.props.formObject).length === 0) {
      this.conditionMonitorRef.current.setFieldsValue(this.props.formObject);
      // this.formRef.current.setFieldsValue(this.props.formObject.TaskData)
    }
    if (prevProps.formObject !== this.props.formObject) {
      if (this.props.formObject && !this.props.formObject.WorkOrder) {
        let workOrderName = await getWorkOrderName();
        this.handleChange(workOrderName['WorkOrder'], Object.keys(workOrderName)[0]);
      }
      this.setState({
        formObject: this.props.formObject
      });
    }
    if (this.props.history.location !== prevProps.history.location) {
      this.onReset();
      this._getDataById();
    }
  };
  _getDataById = async (Id) => {
    let formObject = {};
    let path = history.location.pathname.split('/rubus/AMM/CMDetails/');
    let formId = Id ? Id : base64_decode(path[1]);
    if (formId !== 'new') {
      formObject = await getConditionMonitorById(formId, 'withId');

      // _.cloneDeepWith(formObject, (value) => {
      //   return value && value !== null && value.WorkOrderCreationTime
      //     ? {
      //         ...value,
      //         ...(value['WorkOrderCreationTime'] = moment
      //           .utc(value.WorkOrderCreationTime, 'YYYY-MM-DD HH:mm:ss')
      //           .local())
      //       }
      //     : _.noop();
      // });
    }
    if (formId && formId !== 'new') {
      let parameterList = await getParameterList(
        formObject && formObject.Equipment ? formObject.Equipment : ''
      );
      this.setState({
        formObject,
        parameterList
      });
    } else {
      this.setState({
        formObject
      });
    }

    if (this.conditionMonitorRef.current) {
      this.conditionMonitorRef.current.setFieldsValue(formObject);
    }
  };

  handleChange = async (value, key) => {
    let { formObject, equipmentList, warningTypeList, cmpointList } = this.state;
    if (key === 'Equipment') {
      let parameterList = await getParameterList(value);
      this.setState({ parameterList });
      let selectedEquip = Object.keys(equipmentList)
        .map((r) => equipmentList[r])
        .filter((item) => item.AliasCode === value);
      let formObject = this.conditionMonitorRef.current.getFieldsValue();
      formObject = {
        ...formObject,
        Equipment: value,
        EquipmentDescription: selectedEquip[0].Name
      };
      this.conditionMonitorRef.current.setFieldsValue(formObject);
      this.setState({
        visible: false,
        formObject
      });
    }
    if (key === 'WarningTypeId') {
      formObject = {
        ...formObject,
        WarningTypeId: value
      };
      let list = Object.keys(warningTypeList)
        .map((obj) => warningTypeList[obj])
        .filter((item) => item.Value !== value);
      this.conditionMonitorRef.current.setFieldsValue(formObject);
      this.setState({
        warningTypeList: list,
        formObject
      });
    }
    if (key === 'PointTypeId') {
      let GuageId =
        cmpointList &&
        Array.isArray(cmpointList) &&
        cmpointList.filter((e) => {
          return e.Key === 'Guage';
        });
      let ContinousId =
        cmpointList &&
        Array.isArray(cmpointList) &&
        cmpointList.filter((e) => {
          return e.Key === 'Continuous';
        });
      let FaultId =
        cmpointList &&
        Array.isArray(cmpointList) &&
        cmpointList.filter((e) => {
          return e.Key === 'Fault';
        });

      if (value === (GuageId && GuageId[0] && GuageId[0].Value ? GuageId[0].Value : '')) {
        this.setState({
          viewGauge: 'true',
          viewContinous: 'false',
          viewFault: 'false'
        });
      } else if (
        value ===
        (ContinousId && ContinousId[0] && ContinousId[0].Value ? ContinousId[0].Value : '')
      ) {
        this.setState({
          viewContinous: 'true',
          viewFault: 'false',
          viewGauge: 'false'
        });
      } else if (value === (FaultId && FaultId[0] && FaultId[0].Value ? FaultId[0].Value : '')) {
        this.setState({
          viewFault: 'true',
          viewGauge: 'false',
          viewContinous: 'false'
        });
      }
      formObject = this.conditionMonitorRef.current.getFieldsValue();
      let t1 =
        this.conditionMonitorRef &&
        this.conditionMonitorRef.current &&
        this.conditionMonitorRef.current.setFieldsValue({ NotificationTypeId: [] });
      this.conditionMonitorRef.current.setFieldsValue(t1);
    }
    if (key === 'lastCreationTimeforWO') {
      formObject = {
        ...formObject,
        [key]: moment(value, 'HH:mm')
      };
    }
  };
  fileupload = async (values, key, fileDataObject) => {
    let formObject = this.conditionMonitorRef.current.getFieldsValue();

    if (formObject[key]) {
      formObject[key].push(...values);
    } else {
      formObject[key] = values;
    }
    formObject[key] =
      formObject[key] &&
      formObject[key].reduce((acc, current) => {
        const x = acc.find((item) => item.Name === current.Name);
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, []);
    formObject = {
      ...formObject,
      [key]: formObject[key]
    };
    fileData.append('files', fileDataObject);
    this.conditionMonitorRef.current.setFieldsValue(formObject);
    this.setState({ formObject, fileData });
  };
  onFormsubmit = async (values) => {
    let { formObject } = this.state;
    let userId =
      this.props.userProfile && this.props.userProfile.user && this.props.userProfile.user.Id
        ? this.props.userProfile.user.Id
        : '';
    delete values.EquipmentDescription;

    formObject = {
      ...values,
      ...(formObject && !formObject.Id ? { CreatedBy: userId } : {}),
      ...(formObject && formObject.Id ? { UpdatedBy: userId } : {})
    };
    let statusResponse = await submitConditionMonitor(formObject, this.state.fileData);
    if (statusResponse && statusResponse.message) {
      // history.goBack();
      this._getDataById(statusResponse.Id);
      message.success(statusResponse.message);
      // this.onReset();
      this.setState({ fileData: [] });
    } else if (statusResponse && statusResponse.Error) {
      message.error(statusResponse.Error);
    }
  };
  onReset = () => {
    // this.formRef.current.resetFields();
    this.conditionMonitorRef.current.resetFields();
  };
  // onFinishFailed = () => {
  //   message.error('Please enter mandatory fields');
  // };
  getValueFromList = (list, compareKey, compareValue, resultKey) => {
    let filterObject = [];
    if (list && Array.isArray(list)) {
      filterObject = list.filter((listObject) => listObject[compareKey] === compareValue);
    }
    return filterObject && filterObject[0] && filterObject[0][resultKey]
      ? filterObject[0][resultKey]
      : '';
  };
  getDeviceData = (key) => {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    let headers = {
      method: 'GET',
      url: `/api/device/deviceListByParameterCategory?SiteId=${siteId}&ParameterCategory=Operational`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    axios(headers)
      .then(({ data }) => {
        this.setState({
          tableData: data,
          [key]: true,
          formObject: this.conditionMonitorRef.current.getFieldsValue()
        });
      })
      .catch(function () {});
  };
  TableDropdownChange = async (value, modal) => {
    let formObject = this.conditionMonitorRef.current.getFieldsValue();
    formObject = {
      ...formObject,
      Equipment: value.AliasCode,
      DeviceCode: value.DeviceCode,
      DisplayCode: value.DisplayCode,
      EquipmentDescription: value.Name,
      Location: value.Location,
      LocationDescription: value.LocationDescription
    };
    let parameterList = await getParameterList(value.AliasCode);
    this.conditionMonitorRef.current.setFieldsValue(formObject);
    this.setState({
      formObject,
      [modal]: false,
      parameterList
    });
  };

  handleSelect = (value, key, subindex, keyName) => {
    let data = this.conditionMonitorRef.current.getFieldValue();
    if (data && data[keyName] && data[keyName][subindex] && data[keyName][subindex]) {
      data[keyName][subindex][key] = value;
    }
    if (data && data[key]) {
      data[key] = value;
    }
    this.conditionMonitorRef.current.setFieldsValue(data);
    this.setState({ formObject: data });
  };

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };
  handleReset = (clearFilters, confirm) => {
    clearFilters();
    this.handleSearch([], confirm);
  };

  onDrawerCancel = (e) => {
    if (e.target.tagName === 'DIV') {
      e.preventDefault();
      message.error('Please verify form');
    } else {
      this.setState({
        visible: false
      });
    }
  };
  onModalOk = () => {
    this.setState({
      visible: false
    });
  };
  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={translation(this.props.translationCache, constant.search)}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          {translation(this.props.translationCache, constant.search)}
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters, confirm)}
          size="small"
          style={{ width: 90 }}
        >
          {translation(this.props.translationCache, constant.Reset)}
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined, fontSize: '130%' }} />
    ),
    onFilter: (value, record) =>
      record &&
      record[dataIndex] &&
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text && text.toString()}
      />
    )
  });
  getDisableStatusContious = (notification) => {
    let selectedNotif =
      this.conditionMonitorRef.current &&
      this.conditionMonitorRef.current.getFieldValue('NotificationTypeId')
        ? this.conditionMonitorRef.current.getFieldValue('NotificationTypeId')
        : [];
    let { WoId, SrId } = this.state;
    if (selectedNotif && Array.isArray(selectedNotif)) {
      if (
        notification &&
        notification.Key &&
        notification.Key === 'ServiceRequest' &&
        selectedNotif.includes(WoId)
      ) {
        //If SelectedNotification has WOId , disable SR
        return true;
      } else if (
        notification &&
        notification.Key &&
        notification.Key === 'WorkOrder' &&
        selectedNotif.includes(SrId)
      ) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  };
  onParameterChange = (key, value) => {
    let { parameterList } = this.state;
    let formObject = this.conditionMonitorRef.current.getFieldsValue();
    let selectedParameter =
      parameterList &&
      Array.isArray(parameterList) &&
      parameterList.filter((parameter) => parameter.ParameterCode === value);
    formObject = {
      ...formObject,
      [key]: value,
      // selectedParameter &&
      // selectedParameter[0] &&
      // selectedParameter[0].EdgeCode &&
      // selectedParameter[0].ParameterDescription
      //   ? selectedParameter[0].EdgeCode + '@' + selectedParameter[0].ParameterDescription
      //   : '',
      UOM:
        selectedParameter && selectedParameter[0] && selectedParameter[0].UOM
          ? selectedParameter[0].UOM
          : ''
    };

    this.conditionMonitorRef.current.setFieldsValue(formObject);
    this.setState({
      formObject
    });
  };
  render() {
    let {
      sitesList,
      parameterList,
      formObject,
      cmpointList,
      EmailId,
      SlackId,
      notificationTypeList,
      warningTypeList,
      usersList
    } = this.state;
    let { translationCache } = this.props;
    let GuageId =
      cmpointList &&
      Array.isArray(cmpointList) &&
      cmpointList.filter((e) => {
        return e.Key === 'Guage';
      });
    let ContinousId =
      cmpointList &&
      Array.isArray(cmpointList) &&
      cmpointList.filter((e) => {
        return e.Key === 'Continuous';
      });
    // let FaultId =
    //   cmpointList &&
    //   Array.isArray(cmpointList) &&
    //   cmpointList.filter((e) => {
    //     return e.Key === 'Fault';
    //   });

    /* eslint-disable no-template-curly-in-string */
    const validateMessages = {
      required: '${label} is required!',
      types: {
        email: '${label} is not a valid email!',
        number: '${label} is not a valid number!'
      },
      number: {
        range: '${label} must be between ${min} and ${max}'
      },
      pattern: {
        mismatch: '${label} does not match pattern'
      }
    };
    return (
      <StyledComponent theme={themeSettings} style={{ minHeight: window.innerHeight - 167 }}>
        <StyledForm
          theme={themeSettings}
          ref={this.conditionMonitorRef}
          onFinish={this.onFormsubmit}
          onKeyDown={(e) => {
            e.key === 'Enter' && e.preventDefault();
          }}
          // onFinishFailed={this.onFinishFailed}
          initialValues={formObject}
          layout="horizontal"
          labelCol={{ flex: '130px' }}
          labelAlign="right"
          labelWrap
          //wrapperCol={{ flex: 1 }}
          validateMessages={validateMessages}
        >
          <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
            <StyledButton theme={themeSettings} htmlType="submit">
              {translation(translationCache, constant.submit)}
            </StyledButton>
          </div>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            <Form.Item name="Id"></Form.Item>
            <Form.Item
              label={translation(translationCache, constant.cMPoint)}
              name="Name"
              rules={[
                { required: true },
                {
                  // pattern: new RegExp(/^[A-Za-z0-9]*$/)
                  // pattern: new RegExp(
                  //   /^[一-龯ぁ-んァ-ンぁ-んァ-ン\wぁ-んァ-ンｧ-ﾝﾞﾟぁ-ゞ0-9A-Za-zァ-ヶｦ-ﾟ-_&一 ]*$/
                  // )
                }
              ]}
              style={{
                width: '100%',
                flex: '50%'
              }}
            >
              <InputComponent
                //maxLength={50}
                placeholder={translation(translationCache, 'Input CMPoint')}
                style={{ width: '70%' }}
                value={'WO-11'}
                disabled={formObject && formObject.Id ? true : false}
              />
            </Form.Item>
            <Form.Item
              label={translation(translationCache, constant.cMPointDescription)}
              name="Description"
              style={{ width: '100%', flex: '50%' }}
              rules={[
                { required: true },
                {
                  // pattern: new RegExp(/^[A-Za-z0-9]*$/)
                  // pattern: new RegExp(
                  //   /^[一-龯ぁ-んァ-ンぁ-んァ-ン\wぁ-んァ-ンｧ-ﾝﾞﾟぁ-ゞ0-9A-Za-zァ-ヶｦ-ﾟ-_&一 ]*$/
                  // )
                }
              ]}
            >
              <InputComponent
                //maxLength={150}
                placeholder={translation(translationCache, 'Input CMPoint Description')}
                style={{ width: '70%' }}
                value={'WO-11'}
              />
            </Form.Item>
            <Form.Item name="Equipment"></Form.Item>

            <Form.Item
              style={{
                width: '100%',
                flex: '50%'
              }}
              label={translation(translationCache, constant.equipment)}
              name="DisplayCode"
              rules={[{ required: true }]}
            >
              <InputComponent
                disabled={true}
                suffix={
                  <DownOutlined
                    style={{
                      color: formObject && formObject.Id ? null : '#1a3b5c',
                      cursor: formObject && formObject.Id ? 'not-allowed' : null,
                      opacity: formObject && formObject.Id ? '0.8' : null
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      if (formObject && !formObject.Id) {
                        this.getDeviceData('visible');
                      }
                    }}
                  />
                }
                // maxLength={150}
                style={{ width: '70%' }}
                placeholder={translation(translationCache, constant.SelectEquipment)}
                // onClick={() => {
                //   this.getDeviceData('visible');
                // }}
              />
            </Form.Item>
            <StyledModal
              open={this.state.visible}
              width={800}
              closable
              title={translation(translationCache, constant.selectEquipment)}
              onOk={this.onModalOk}
              onCancel={this.onDrawerCancel}
            >
              <TableSelect
                data={this.state.tableData}
                translationCache={this.props.translationCache}
                key="Equipment"
                columns={[
                  {
                    title: translation(translationCache, 'Equipment'),
                    dataIndex: 'DisplayCode',
                    key: 'DisplayCode',
                    ...this.getColumnSearchProps('DisplayCode')
                  },
                  {
                    title: translation(translationCache, 'Equipment Description'),
                    dataIndex: 'Name',
                    key: 'Name',
                    ...this.getColumnSearchProps('Name')
                  }
                ]}
                handleDropdownChange={(e) => this.TableDropdownChange(e, 'visible')}
              />
              {/* <HierarchyModal
                  value={this.state.deviceId}
                  treeData={this.state.treeData}
                  translationCache={this.props.translationCache}
                  language={this.props.language}
                  handleDropdownChange={this.hierarchyDropdownChange}
                  handleParentNode={this.parentNode}
                  deviceList={this.state.deviceList}
                /> */}
            </StyledModal>
            <Form.Item
              label={translation(translationCache, constant.equipmentDescription)}
              name="EquipmentDescription"
              style={{
                width: '100%',
                flex: '50%'
                // display: formObject && formObject.Id ? 'none' : true
              }}
              rules={[
                {
                  required: true,
                  message: translation(translationCache, 'Please Input Equipment Description!')
                }
              ]}
            >
              <InputComponent
                placeholder={translation(translationCache, 'Autopopulated based on Equipment')}
                disabled={true}
                style={{ width: '70%' }}
              />
            </Form.Item>

            <Form.Item
              label={translation(translationCache, constant.location)}
              name="Location"
              style={{
                width: '100%',
                flex: '50%'
                // display: formObject && formObject.Id ? 'none' : true
              }}
              rules={[
                {
                  required: true,
                  message: translation(translationCache, 'Please Location!')
                }
              ]}
            >
              <InputComponent
                placeholder={translation(translationCache, 'Autopopulated based on Equipment')}
                disabled={true}
                style={{ width: '70%' }}
              />
            </Form.Item>
            <Form.Item
              label={translation(translationCache, constant.locationDescription)}
              name="LocationDescription"
              style={{
                width: '100%',
                flex: '50%'
                // display: formObject && formObject.Id ? 'none' : true
              }}
              rules={[
                {
                  required: true,
                  message: translation(translationCache, 'Please Input Equipment Description!')
                }
              ]}
            >
              <InputComponent
                placeholder={translation(translationCache, 'Autopopulated based on Equipment')}
                disabled={true}
                style={{ width: '70%' }}
              />
            </Form.Item>

            <Form.Item
              label={translation(translationCache, constant.site)}
              name="SiteId"
              style={{
                width: '100%',
                flex: '50%'
                // display: formObject && formObject.Id ? 'none' : true
              }}
              rules={[
                { required: true, message: translation(translationCache, 'Please Input Site!') }
              ]}
            >
              <Select
                style={{ width: '70%' }}
                disabled={formObject && formObject.Id ? true : false}
                placeholder={translation(translationCache, 'Select Site')}
              >
                {sitesList &&
                  Array.isArray(sitesList) &&
                  sitesList.map((site, index) => {
                    return (
                      <Option key={index} value={site.Id}>
                        {' '}
                        {site.Name}{' '}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
            {/* <Form.Item
                name="Attachments"
                style={{ width: '100%', flex: '50%' }}
                label={translation(translationCache, constant.attachments)}
              >
                <FileBase64
                  downloadClassName={'formdownloadInput'}
                  Attachments={formObject && formObject.Attachments ? formObject.Attachments : []}
                  fileUpload={this.fileupload}
                ></FileBase64>
              </Form.Item> */}
            <Form.Item
              label={translation(translationCache, constant.parameter)}
              name="ParameterCode"
              style={{ width: '100%', flex: '50%' }}
              rules={[
                {
                  required: true,
                  message: translation(translationCache, 'Please Input Parameter!')
                }
              ]}
            >
              <Select
                style={{ width: '70%' }}
                placeholder={translation(translationCache, 'Select Parameter')}
                onChange={(e) => this.onParameterChange('ParameterCode', e)}
              >
                {parameterList &&
                  Array.isArray(parameterList) &&
                  parameterList.map((parameter, index) => {
                    return (
                      <Option key={index} value={parameter.ParameterCode}>
                        {parameter.EdgeCode} @ {parameter.ParameterDescription}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
            <Form.Item
              label={translation(translationCache, constant.cMPointType)}
              name="PointTypeId"
              style={{ width: '100%', flex: '50%' }}
              rules={[
                {
                  required: true,
                  message: translation(translationCache, 'Please Input CMPoint Type!')
                }
              ]}
            >
              <Select
                disabled={formObject && formObject.Id ? true : false}
                style={{ width: '70%' }}
                placeholder={translation(translationCache, 'Select CM Point Type')}
                onChange={(e) => this.handleChange(e, 'PointTypeId')}
              >
                {cmpointList &&
                  Array.isArray(cmpointList) &&
                  cmpointList.map((parameter, index) => {
                    return (
                      <Option key={index} value={parameter.Value}>
                        {' '}
                        {translation(translationCache, parameter.Key)}{' '}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
            <Form.Item
              label={translation(translationCache, constant.UOM)}
              name="UOM"
              style={{ width: '100%', flex: '50%' }}
              rules={
                [
                  // { required: true, message: translation(translationCache, '') },
                ]
              }
            >
              <InputComponent
                placeholder={translation(translationCache, 'Auto Populated from Parameters')}
                disabled={true}
                style={{ width: '70%' }}
              />
            </Form.Item>
            <Form.Item
              label={translation(translationCache, constant.minimumValue)}
              name="MinimumValue"
              style={{
                width: '100%',
                flex: '50%'
                // display: formObject && formObject.Id ? 'none' : true
              }}
              rules={[
                {
                  required: false,
                  message: translation(translationCache, 'Please Input Minimum Value')
                },
                {
                  validator: (_, value) =>
                    checkLimit(
                      value,
                      'MinimumValue',
                      this.conditionMonitorRef &&
                        this.conditionMonitorRef.current &&
                        this.conditionMonitorRef.current.getFieldsValue()
                        ? this.conditionMonitorRef.current.getFieldsValue()
                        : {},
                      {
                        validateField: 'MaximumValue',
                        operator: 'less'
                      }
                    )
                }
              ]}
            >
              <InputComponent
                placeholder={translation(translationCache, 'Please Input Minimum Value')}
                style={{ width: '70%' }}
              />
            </Form.Item>
            <Form.Item
              label={translation(translationCache, constant.maximumValue)}
              name="MaximumValue"
              style={{
                width: '100%',
                flex: '50%'
              }}
              rules={[
                {
                  required: false,
                  message: translation(translationCache, 'Please Input Maximum Value')
                },
                {
                  validator: (_, value) =>
                    checkLimit(
                      value,
                      'MaximumValue',
                      this.conditionMonitorRef &&
                        this.conditionMonitorRef.current &&
                        this.conditionMonitorRef.current.getFieldsValue()
                        ? this.conditionMonitorRef.current.getFieldsValue()
                        : {},
                      {
                        validateField: 'MinimumValue',
                        operator: 'greater'
                      }
                    )
                }
              ]}
            >
              <InputComponent
                placeholder={translation(translationCache, 'Please Input Maximum Value')}
                // disabled={true}
                style={{ width: '70%' }}
              />
            </Form.Item>

            {/* <Form.Item style={{ width: '100%', flex: '50%' }}></Form.Item> */}
          </div>

          {this.conditionMonitorRef.current &&
          this.conditionMonitorRef.current.getFieldValue('PointTypeId') ===
            (GuageId && GuageId[0] && GuageId[0].Value ? GuageId[0].Value : '') ? (
            <StyledSubForm theme={themeSettings} style={{ padding: '0px' }}>
              <Form.Item name="Warnings">
                <ToolsForm
                  SrId={this.state.SrId}
                  WoId={this.state.WoId}
                  EmailId={this.state.EmailId}
                  SlackId={this.state.SlackId}
                  formObject={
                    this.conditionMonitorRef.current !== null
                      ? this.conditionMonitorRef.current.getFieldsValue()
                      : {}
                  }
                  onNotificationChange={this.handleSelect}
                  warningTypeList={warningTypeList}
                  notificationTypeList={notificationTypeList}
                  usersList={usersList}
                  conditionMonitorRef={this.conditionMonitorRef}
                />
              </Form.Item>
            </StyledSubForm>
          ) : null}
          {this.conditionMonitorRef.current &&
          this.conditionMonitorRef.current.getFieldValue('PointTypeId') ===
            (ContinousId && ContinousId[0] && ContinousId[0].Value ? ContinousId[0].Value : '') ? (
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              <Form.Item
                label={translation(translationCache, constant.frequencySetPoint)}
                name={'FrequencySetPoint'}
                rules={[
                  {
                    required: true
                    // message: translation(translationCache, 'FrequencySetPoint Type!')
                  }
                ]}
                style={{ width: '100%', flex: '50%', marginTop: '30px' }}
              >
                <InputComponent
                  //maxLength={30}
                  placeholder={translation(translationCache, 'Input FrequencySetPoint')}
                  style={{ width: '70%' }}
                />
              </Form.Item>

              <Form.Item
                label={translation(translationCache, constant.rollOverSetPoint)}
                name={'RollOverSetPoint'}
                rules={[
                  { required: true }
                  //  { pattern: new RegExp(/^[0-9-.]*$/) }
                ]}
                style={{ width: '100%', flex: '50%', marginTop: '30px' }}
              >
                <InputComponent
                  //maxLength={30}
                  placeholder={translation(translationCache, 'Input Roll OverSetPoint')}
                  style={{ width: '70%' }}
                />
              </Form.Item>

              <Form.Item
                label={translation(translationCache, constant.notificationType)}
                name={'NotificationTypeId'}
                rules={[
                  {
                    required: false,
                    message: translation(translationCache, 'Please Input Notification Type!')
                  }
                ]}
                style={{ width: '100%', flex: '50%' }}
              >
                <Select
                  showArrow={true}
                  mode="multiple"
                  style={{ width: '70%' }}
                  placeholder={translation(translationCache, 'Select Notification Type')}
                  onChange={(e) => {
                    this.handleSelect(e, 'NotificationTypeId');
                  }}
                  // onDeselect={(e) => { this.onNotificationDeselect(e) }}
                >
                  {notificationTypeList &&
                    Array.isArray(notificationTypeList) &&
                    notificationTypeList.map((notification, index) => {
                      return (
                        <Option
                          key={index}
                          value={notification.Value}
                          disabled={this.getDisableStatusContious(notification)}
                        >
                          {notification.Key}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>

              {this.conditionMonitorRef &&
              this.conditionMonitorRef.current &&
              this.conditionMonitorRef.current.getFieldValue('NotificationTypeId') &&
              this.conditionMonitorRef.current
                .getFieldValue('NotificationTypeId')
                .includes(EmailId) ? (
                <Form.Item
                  style={{ flex: '50%', width: '100%' }}
                  labelCol={{ flex: '130px' }}
                  label={translation(translationCache, constant.user)}
                  // {...restField}
                  name={'Emails'}
                >
                  <Select
                    mode="multiple"
                    placeholder={translation(translationCache, 'Select User')}
                    style={{ width: '70%' }}
                  >
                    {usersList &&
                      Array.isArray(usersList) &&
                      usersList.map((item, index) => {
                        return (
                          <Option key={index} value={item.Email}>
                            {' '}
                            {item.FirstName}{' '}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              ) : null}

              {this.conditionMonitorRef &&
              this.conditionMonitorRef.current &&
              this.conditionMonitorRef.current.getFieldValue('NotificationTypeId') &&
              this.conditionMonitorRef.current
                .getFieldValue('NotificationTypeId')
                .includes(SlackId) ? (
                <Form.Item
                  style={{ flex: '50%', width: '100%' }}
                  labelCol={{ flex: '130px' }}
                  label={translation(translationCache, constant.channel)}
                  name={'ChannelName'}
                  rules={[
                    {
                      required: true,
                      message: translation(translationCache, 'Please Input Channel')
                    }
                  ]}
                >
                  <InputComponent
                    placeholder={translation(translationCache, 'Input Channel')}
                    style={{ width: '70%' }}
                  />
                </Form.Item>
              ) : null}
              <Form.Item style={{ flex: '50%' }}>
                <InputComponent style={{ width: '70%', display: 'none' }} />
              </Form.Item>
            </div>
          ) : null}
        </StyledForm>
      </StyledComponent>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  userProfile: getUserProfile(),
  translationCache: getTranslationCache()
});
export default connect(mapStateToProps)(ConditionMonitoring);
