/* eslint-disable array-callback-return */
import React, { Component } from 'react';
import moment from 'moment';
import cloneDeep from 'lodash.clonedeep';
import axios from 'axios';
import FileBase64 from 'react-file-base64';
import localStorage from '../../../../utils/localStorage';
import { SelectComponent } from '../Widgets';
import { Form, Input, InputNumber, Row, message, Col, Tree, DatePicker } from 'antd';
import { TableInsideTables } from '../Widgets';
import { DownOutlined, UpCircleFilled } from '@ant-design/icons';

const { TreeNode } = Tree;

class FormTableWithMultiSubHedearTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formObject: cloneDeep(props.formObject) || {},
      columns: props.formObject && props.formObject.columns ? props.formObject.columns : [],
      data: props.formObject && props.formObject.datasource ? props.formObject.datasource : [],
      activeKey: [],
      headerValue: {},
      recordType: props.recordType || '',
      remarks: '',
      shift: 'daily',
      hierarchy: this.getMenuHierarchyStructure(),
      deviceList: [],
      autoExpandParent: true
    };
  }

  componentDidMount() {
    let { formObject } = this.state;
    formObject = cloneDeep(this.props.formObject);
    this.getDeviceList();
    this.setState({
      columns:
        this.props.formObject && this.props.formObject.columns ? this.props.formObject.columns : [],
      data:
        this.props.formObject && this.props.formObject.datasource
          ? this.props.formObject.datasource
          : [],
      formObject: {
        ...formObject
      }
    });
    this.props.downTimeData(formObject);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.formObject !== this.props.formObject ||
      prevProps.headerValue !== this.props.headerValue ||
      prevProps.header !== this.props.header ||
      prevProps.elog !== this.props.elog ||
      prevProps.userProfile !== this.props.userProfile
    ) {
      this.setState({
        formObject: cloneDeep(this.props.formObject),
        columns:
          this.props.formObject && this.props.formObject.columns
            ? this.props.formObject.columns
            : [],
        data:
          this.props.formObject && this.props.formObject.datasource
            ? this.props.formObject.datasource
            : [],
        headerValue: this.props.headerValue,
        header: this.state.header,
        userProfile: this.props.userProfile,
        elog: this.props.elog
      });
    }
  }

  getMenuHierarchyStructure = () => {
    const userData = JSON.parse(localStorage.get('modules'));
    if (userData && userData.hierarchy) {
      let final = userData.hierarchy.map((userHierarchy) => {
        return this.renameProperty(userHierarchy, 'label', 'title', 'value', 'key');
      });
      return final;
    }
  };
  getDeviceList = () => {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    const headers = {
      method: 'GET',
      url: `/api/device/get?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    axios(headers)
      .then((res) => {
        let deviceList = res.data;
        this.setState({ deviceList });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  renameProperty = (obj, oldName1, newName1, oldName2, newName2) => {
    if (obj && obj.children) {
      let children = obj.children.map((objChildren) => {
        return this.renameProperty(objChildren, oldName1, newName1, oldName2, newName2);
      });
      obj['children'] = children;
      obj[newName1] = obj[oldName1];
      delete obj[oldName1];
      obj[newName2] = obj[oldName2];
      delete obj[oldName2];
      return obj;
    } else {
      obj[newName1] = obj[oldName1];
      delete obj[oldName1];
      obj[newName2] = obj[oldName2];
      delete obj[oldName2];
      return obj;
    }
  };

  onInputHeaderMainChange = (columnValue, columnKey) => {
    let { formObject } = this.state;
    formObject = {
      ...formObject,
      form: {
        ...formObject['form'],
        datasource: {
          ...(formObject && formObject['form'] && formObject['form'].datasource
            ? formObject['form'].datasource
            : {}),
          [columnKey]:
            columnValue && Array.isArray(columnValue) && columnValue.length === 2
              ? columnValue[1]
              : columnValue,
          ...(columnValue && Array.isArray(columnValue) && columnValue.length === 2
            ? {
                [`${columnKey}` + 1]: JSON.stringify(columnValue)
              }
            : {})
        }
      }
    };
    this.props.downTimeData(formObject);
  };

  onInputHeaderMainChangeBase64 = (columnValue, columnKey) => {
    let { formObject } = this.state;
    formObject = {
      ...formObject,
      form: {
        ...formObject['form'],
        datasource: {
          ...(formObject && formObject['form'] && formObject['form'].datasource
            ? formObject['form'].datasource
            : {}),
          [columnKey]:
            columnValue && Array.isArray(columnValue) && columnValue.length === 2
              ? columnValue[1]
              : columnValue.base64,
          ...(columnValue && Array.isArray(columnValue) && columnValue.length === 2
            ? {
                [`${columnKey}` + 1]: JSON.stringify(columnValue)
              }
            : {})
        }
      }
    };
    this.props.downTimeData(formObject);
  };
  onInputHeaderConditionMainChange = (columnValue, columnKey, entryCondition) => {
    let { formObject } = this.state;
    let datasource =
      formObject && formObject['form'] && formObject['form'].datasource
        ? formObject['form'].datasource
        : [];
    if (entryCondition && entryCondition.clearFields && Array.isArray(entryCondition.clearFields)) {
      entryCondition.clearFields.map((fieldName) => {
        delete datasource[fieldName];
      });
    }
    datasource = {
      ...datasource,
      [columnKey]: columnValue
    };
    formObject = {
      ...formObject,
      form: {
        ...formObject['form'],
        datasource
      }
    };
    this.props.downTimeData(formObject);
  };

  inputHeaderMainNumberChange = (minValue, maxValue, value, key, columns) => {
    if (
      value >= minValue &&
      value <= maxValue &&
      minValue !== undefined &&
      maxValue !== undefined
    ) {
      this.onInputHeaderMainChange(value, key);
    } else if (columns && columns.entryCondition) {
      this.onInputHeaderConditionMainChange(value, key, columns.entryCondition);
    } else if (minValue === undefined && maxValue === undefined) {
      this.onInputHeaderMainChange(value, key);
    } else {
      this.onInputHeaderMainChange('', key);
      message.error(`The input range must between ${minValue} and ${maxValue}`);
    }
  };

  renderMainSwitch(formObject, columns) {
    switch (columns.widget) {
      case 'input':
        return (
          <Input
            style={{ width: '90%', fontWeight: 'bold' }}
            value={
              formObject['form'] &&
              formObject['form'].datasource &&
              formObject['form'].datasource[columns.key]
                ? formObject['form'].datasource[columns.key]
                : ''
            }
            maxLength={columns && columns.maxLength ? columns.maxLength : 100}
            disabled={columns.disable ? columns.disable : false}
            placeholder={columns.placeHolder}
            onChange={(e) => this.onInputHeaderMainChange(e.target.value, columns.key)}
          />
        );
      case 'inputNumber':
        return (
          <InputNumber
            style={{ width: '90%', fontWeight: 'bold' }}
            value={
              formObject['form'] &&
              formObject['form'].datasource &&
              formObject['form'].datasource[columns.key]
                ? formObject['form'].datasource[columns.key]
                : ''
            }
            min={columns.min ? columns.min : 0}
            max={columns.max ? columns.max : 1000000000}
            placeholder={columns.placeHolder}
            onChange={(e) =>
              this.inputHeaderMainNumberChange(columns.min, columns.max, e, columns.key, columns)
            }
            // onChange={(e) => this.onInputHeaderMainChange(e, columns.key)}
          />
        );
      case 'date-picker':
        return (
          <DatePicker
            value={
              formObject['form'] &&
              formObject['form']['datasource'] &&
              formObject['form']['datasource']['Date']
                ? moment(formObject['form']['datasource']['Date'], 'YYYY-MM-DD HH:mm:ss')
                : undefined
            }
            showNow={false}
            disabled={columns.disable ? columns.disable : false}
            disabledDate={this.disabledDate}
            onChange={(e) => this.onDateHeaderMainChange(e, columns.key)}
            style={{ width: '90%', fontWeight: 'bold' }}
          />
        );
      case 'select':
        return (
          <SelectComponent
            column={columns}
            record={{ key: columns }}
            text={
              columns && columns.hierarchy && columns.hierarchy === true
                ? formObject['form'] &&
                  formObject['form'].datasource &&
                  formObject['form'].datasource[`${columns.key}` + 1]
                  ? formObject['form'].datasource[`${columns.key}` + 1]
                  : ''
                : formObject['form'] &&
                  formObject['form'].datasource &&
                  formObject['form'].datasource[columns.key]
                ? formObject['form'].datasource[columns.key]
                : ''
            }
            userProfile={this.props.userProfile}
            disabled={columns.disable ? columns.disable : false}
            handleFieldChange={(e) => this.onInputHeaderMainChange(e, columns.key)}
            handleKeyPress={this.handleKeyPress}
          />
        );
      case 'imageUploadBase64':
        return (
          <span>
            <FileBase64
              type="file"
              multiple={false}
              onDone={(file) => {
                this.onInputHeaderMainChangeBase64(file, columns.key);
              }}
            />
            {formObject['form'] &&
            formObject['form'].datasource &&
            formObject['form'].datasource[columns.key] ? (
              <div>
                <a
                  style={{ color: '#062d57' }}
                  href={formObject['form'].datasource[columns.key]}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    alt=""
                    width="100"
                    height="100"
                    src={`${formObject['form'].datasource[columns.key]}`}
                  ></img>
                </a>
              </div>
            ) : null}
          </span>
        );
      case 'textArea':
        return (
          <div>
            <Input.TextArea
              rows={4}
              style={{ width: '90%', fontWeight: 'bold' }}
              value={
                formObject['form'] &&
                formObject['form'].datasource &&
                formObject['form'].datasource[columns.key]
                  ? formObject['form'].datasource[columns.key]
                  : ''
              }
              placeholder={columns.placeHolder}
              onChange={(e) => this.onInputHeaderMainChange(e.target.value, columns.key)}
              autoSize={{ minRows: 2, maxRows: 4 }}
              // style={{ width: '100%' }}
              maxLength={250}
            />
          </div>
        );
      default:
        return (
          <div>
            {formObject['form'] &&
            formObject['form'].datasource &&
            formObject['form'].datasource[columns.key] ? (
              formObject['form'].datasource[columns.key]
            ) : (
              <div>{'   '}</div>
            )}
          </div>
        );
    }
  }

  checkDeviceExistsOrNot = (data) => {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    const Object = {
      method: 'POST',
      url: `/api/logbook/getDeviceData?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data
    };
    return axios(Object)
      .then((response) => {
        return response.data;
      })
      .catch(() => {
        return {};
      });
  };
  onExpand = (expandedKeys) => {
    this.setState({
      expandedKeys,
      autoExpandParent: false
    });
  };

  onSelect = async (selectedKeys) => {
    let { formObject, hierarchy, deviceList } = this.state;
    let deviceObject = deviceList.filter((device) => {
      return device.AliasCode === selectedKeys[0];
    });
    if (deviceObject && deviceObject.length > 0) {
      let payload = {
        DeviceAlias: deviceObject && deviceObject[0] ? deviceObject[0].AliasCode : '',
        eLogReferenceId:
          this.props.elog && this.props.elog.eLogReferenceId ? this.props.elog.eLogReferenceId : ''
      };
      let checkDeviceExits = await this.checkDeviceExistsOrNot(payload);
      let checkStatus = false;
      hierarchy.map(() => {
        if (checkDeviceExits && Array.isArray(checkDeviceExits) && checkDeviceExits.length > 0) {
          if (checkStatus === false) {
            this.props.checkDeviceExistsOrNot(checkDeviceExits);
          }
          checkStatus = true;
        } else {
          formObject = {
            ...formObject,
            form: {
              ...formObject['form'],
              datasource: {
                DeviceId: selectedKeys[0]
              }
            },
            table: {
              ...formObject['table'],
              datasource: []
            }
          };
          this.setState({ selectedKeys: selectedKeys[0] });
          this.props.downTimeData(formObject);
        }
      });
    } else {
      message.error('Please select valid sensor');
    }
  };

  getData = (selectedKey) => {
    this.onSelect([selectedKey]);
  };

  setTranslation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] && translationCache[keyword]
      ? translationCache[keyword]
      : keyword;
  };

  saveTableViewData = (list) => {
    let { formObject } = this.state;
    formObject = {
      ...formObject,
      table: {
        ...(formObject && formObject['table'] ? formObject['table'] : {}),
        ...list
      }
    };
    this.props.downTimeData(formObject);
  };
  onExpand = (expandedKeys) => {
    this.setState({
      expandedKeys,
      autoExpandParent: false
    });
  };

  renderTreeNodes = (data) => {
    return data.map((item) => {
      if (item.children && item.children.length > 0) {
        return (
          <TreeNode
            title={`${this.setTranslation(item.title)}${
              item.ShortDescription ? `-${this.setTranslation(item.ShortDescription)}` : ''
            }`}
            key={item.key}
            dataRef={item}
          >
            {this.renderTreeNodes(item.children)}
          </TreeNode>
        );
      }
      return (
        <TreeNode
          key={item.key}
          title={
            <div onClick={() => this.getData(item.key)}>
              {' '}
              <UpCircleFilled style={{ marginRight: '10px' }} />
              {`${this.setTranslation(item.title)}${
                item.ShortDescription ? `-${this.setTranslation(item.ShortDescription)}` : ''
              }`}
            </div>
          }
          dataRef={item}
          showIcon={true}
        />
      );
    });
  };

  render() {
    let { formObject, translationCache } = this.props;
    let { hierarchy } = this.state;
    return (
      <div style={{ marginTop: '30px', marginBottom: '30px', color: 'white', fontWeight: 'bold' }}>
        <Form
          className="batchUpdateForm1"
          name="basic"
          labelCol={{
            span: 6
          }}
          wrapperCol={{
            span: 16
          }}
        >
          <Row
            style={{
              padding: '10px',
              border: '3px solid gray'
            }}
          >
            <Col span={8}>
              <div
                style={{
                  padding: '10px'
                }}
              >
                <label style={{ fontWeight: 'bold', color: 'white' }}>
                  {' '}
                  {translationCache && translationCache['Sensor']
                    ? translationCache['Sensor']
                    : 'Sensor'}{' '}
                  :
                </label>
                <Tree
                  showIcon
                  defaultExpandAll={true}
                  // expandedKeys={this.state.expandedKeys}
                  switcherIcon={<DownOutlined />}
                  onExpand={this.onExpand}
                  autoExpandParent={this.state.autoExpandParent}
                  selectedKeys={[
                    formObject &&
                    formObject.form &&
                    formObject.form.datasource &&
                    formObject.form.datasource.DeviceId
                      ? formObject.form.datasource.DeviceId
                      : ''
                  ]}
                >
                  {this.renderTreeNodes(hierarchy)}
                </Tree>
              </div>
            </Col>
            <Col span={16}>
              <div
                style={{
                  padding: '10px',
                  borderLeft: '3px dashed gray'
                }}
              >
                <Row>
                  {formObject && formObject['form'] && formObject['form'].columns
                    ? formObject['form'].columns.map((columns, index) => {
                        if (columns && columns.widget === 'heading') {
                          return (
                            <div
                              key={index}
                              style={{
                                fontWeight: 'bold',
                                fontSize: '18px',
                                margin: '15px 0px 10px 0px',
                                color: 'black',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                backgroundColor: 'steelblue',
                                width: '100%'
                              }}
                            >
                              {columns && columns.displayName
                                ? translationCache &&
                                  translationCache[columns.displayName] &&
                                  translationCache[columns.displayName]
                                  ? translationCache[columns.displayName]
                                  : columns.displayName
                                : ''}
                            </div>
                          );
                        } else {
                          return (
                            <div style={{ width: '90%', display: 'flex' }} key={index}>
                              <Form.Item
                                style={{ width: '90%', color: 'white' }}
                                required={false}
                                label={
                                  <span style={{ marginLeft: columns.marginLeft, color: 'white' }}>
                                    {translationCache &&
                                    translationCache[columns.displayName] &&
                                    translationCache[columns.displayName]
                                      ? translationCache[columns.displayName]
                                      : columns.displayName}
                                  </span>
                                }
                              >
                                <div>
                                  {this.renderMainSwitch(formObject, columns)}
                                  <span style={{ marginLeft: '5px' }}>
                                    {columns && columns.units ? columns.units : ''}
                                  </span>
                                </div>
                              </Form.Item>
                            </div>
                          );
                        }
                      })
                    : null}
                </Row>
              </div>

              <div
                style={{
                  padding: '10px'
                }}
              >
                <TableInsideTables
                  form={formObject && formObject['table'] ? formObject['table'] : {}}
                  formObject={formObject}
                  recordType={'FormSubSectionTable'}
                  saveTableViewData={(e) => this.saveTableViewData(e)}
                  showAddButton={
                    formObject && formObject['table'] && formObject['table'].showAddButton
                      ? formObject['table'].showAddButton
                      : false
                  }
                  translationCache={this.props.translationCache}
                  status={this.props.status}
                  deviceId={
                    formObject &&
                    formObject.form &&
                    formObject.form.datasource &&
                    formObject.form.datasource.DeviceId
                      ? formObject.form.datasource.DeviceId
                      : ''
                  }
                />
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}

export default FormTableWithMultiSubHedearTable;
