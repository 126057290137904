import axios from 'axios';
import localStorage from '../../../../../../../utils/localStorage';

export function getLiveCranesList(selectedAsset, selectedMode) {
  // return [
  //   {
  //     DeviceName: 'RS',
  //     DeviceCode: 'RS',
  //     Latitude: 25.360933,
  //     Longitude: 55.375195,
  //     Status: 'Running',
  //     RunningStatus: '10Km/hr towards Parking'
  //   },
  //   {
  //     DeviceName: 'ECH',
  //     DeviceCode: 'ECH-1',
  //     Latitude: 25.358082,
  //     Longitude: 55.370667,
  //     Status: 'Idle',
  //     Image: 'Yellow_crane'
  //   },
  //   {
  //     DeviceName: 'RS-1',
  //     DeviceCode: 'RS-1',
  //     Latitude: 25.35717,
  //     Longitude: 55.373199,
  //     Status: 'Stopped',
  //     Image: 'Green_crane'
  //   },
  //   {
  //     DeviceName: 'RS-2',
  //     DeviceCode: 'RS-2',
  //     Latitude: 25.358741,
  //     Longitude: 55.371482,
  //     Status: 'Running',
  //     Image: 'Yellow_crane'
  //   },
  //   {
  //     DeviceName: 'ECH-1',
  //     DeviceCode: 'ECH-1',
  //     Latitude: 25.362233,
  //     Longitude: 55.376032,
  //     Status: 'Idle',
  //     Image: 'Green_crane'
  //   },
  //   {
  //     DeviceName: 'ECH-1',
  //     DeviceCode: 'ECH-1',
  //     Latitude: 25.35942,
  //     Longitude: 55.374143,
  //     Status: 'Stopped',
  //     Image: 'Yellow_crane'
  //   }
  // ];

  const token = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  let Authorization = 'Bearer ' + token;
  const getProcessArea = {
    method: 'POST',
    url: `/api/device/getCraneStatus?SiteId=${siteId}&Module=CHM`,
    headers: {
      Authorization: Authorization
    },
    data: {
      Mode: selectedMode,
      Asset: selectedAsset
    }
  };
  return axios(getProcessArea)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}

export function getLiveCraneCountsList() {
  const token = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  let Authorization = 'Bearer ' + token;
  const getProcessArea = {
    method: 'GET',
    url: `/api/device/getCraneStatusCount?SiteId=${siteId}&Module=CHM`,
    headers: {
      Authorization: Authorization
    }
  };
  return axios(getProcessArea)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
  // return [
  //   {
  //     Asset: 'ITV',
  //     Total: 1,
  //     Running: 2,
  //     Idle: 2
  //   },
  //   {
  //     Asset: 'RS',
  //     Total: 1,
  //     Running: 2,
  //     Idle: 2
  //   },
  //   {
  //     Asset: 'ECH',
  //     Total: 1,
  //     Running: 2,
  //     Idle: 2
  //   }
  // ];
}

export function getCraneDetailsByCode(DeviceAlias) {
  const token = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  let Authorization = 'Bearer ' + token;
  const getProcessArea = {
    method: 'GET',
    url: `/api/device/getCraneDetails?SiteId=${siteId}&DeviceAlias=${DeviceAlias}`,
    headers: {
      Authorization: Authorization
    }
  };
  return axios(getProcessArea)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
  // return {
  //   'Vehicle No': 'AP39 DR1987',
  //   'Operating Status': 'Running',
  //   'Engine Speed': '12 Km/h',
  //   'Driver Details': '1234 - Jhon',
  //   'Load/Unload Status': 'Loaded',
  //   Timestamp: '10:10:00',
  //   'Vehicle Speed': 14,
  //   EngineOnTime: '0h 30m',
  //   EngineOffTime: '3h 30m'
  // };
}

export function getTreeData() {
  return [
    {
      title: 'Port Terminal-1',
      key: '0-0',
      children: [
        {
          title: 'Asset On Berth-1',
          key: 'Wood_fuel_equipment',
          children: [
            {
              title: 'ITV',
              key: '0-0-0-0'
            },
            {
              title: 'RS',
              key: '0-0-0-1'
            },
            {
              title: 'ECH',
              key: '0-0-0-2'
            }
          ]
        },
        {
          title: 'Asset On Berth-2',
          key: '0-0-1',
          children: [
            {
              title: 'ITV',
              key: '0-0-1-0'
            },
            {
              title: 'RS',
              key: '0-0-1-1'
            },
            {
              title: 'ECH',
              key: '0-0-1-2'
            }
          ]
        },
        {
          title: 'Asset On Parking/Workshop Area',
          key: '0-0-2',
          children: [
            {
              title: 'ITV',
              key: '0-0-2-0'
            },
            {
              title: 'RS',
              key: '0-0-2-1'
            },
            {
              title: 'ECH',
              key: '0-0-2-2'
            }
          ]
        }
      ]
    }
  ];
}
