import styled from 'styled-components';
import TextareaAutosize from 'react-textarea-autosize';

export const AppContainer = styled.div`
  display: flex;
  height: 100vh;
`;

export const ChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%; // or 100%
  transition: all 0.2s; // Smooth transition
`;

export const SidebarContainer = styled.div`
  width: 250px;
  background-color: #163046;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh; // Adjust the height to fit your layout
`;

export const ChatListContainer = styled.div`
  overflow-y: auto;
`;

export const ChatRow = styled.div`
  padding: 10px;
  cursor: pointer;
  background-color: ${(props) => (props.isSelected ? '#4c4c4c' : 'transparent')};
  &:hover {
    background-color: #163046;
  }
  color: white;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center; // ensure text and icon are aligned
  overflow: hidden; // add overflow handling

  & > span {
    // add a span tag around the text inside ChatRow
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 10px;
  }
`;

export const Button = styled.button`
  padding: 20px;
  border: none;
  background-color: #163046;
  width: 100%;
  color: white;
  cursor: pointer;
  border-radius: 3px;
  border-color: #fff;
  font-size: 14px;
  &:hover {
    background-color: #163046;
  }
`;

export const SettingsRow = styled.div`
  padding: 10px;
  margin: 10px;
  cursor: pointer;
  background-color: transparent;
  &:hover {
    background-color: #163046;
  }
  border-top: 1px solid #163046;
  color: white;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Menu = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px;
`;

export const Form = styled.form`
  display: flex;
  align-items: flex-start;
  padding: 10px;
  // border-top: 1px solid #163046;
  background: #d7d7d7;
`;

export const StyledTextareaAutosize = styled(TextareaAutosize)`
  flex-grow: 1;
  border: 1px solid #eee;
  border-radius: 3px;
  padding: 10px;
  margin-right: 10px;
  resize: none;
  overflow: auto;
  font-family: inherit;
  font-size: 16px;
  min-height: 14px; // Initial height
  max-height: 500px; // Max height
  &:focus,
  &:active {
    border-color: #1c1c1c;
    outline: none;
  }
`;

export const ChatButton = styled.button`
  height: 40px;
  padding: 10px 20px;
  border: none;
  background-color: #163046;
  color: white;
  cursor: pointer;
  border-radius: 3px;
  font-size: 1em;
  align-self: flex-end;
  &:hover {
    background-color: #333333; /* Change this to the desired lighter shade */
  }
`;

export const MessageList = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  background: #d7d7d7;
`;

export const Container = styled.div`
  background-color: ${({ isUser }) => (isUser ? 'white' : '#F5F5F5')};
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
  width: 100%; // Make the container full width
  border-top: 0.5px solid #c4c4c4; // Add a thin gray line to the bottom
`;

export const Bubble = styled.div`
  margin: 10px;
  padding: 10px;
  border-radius: 10px;
  width: 50%; // Set a fixed width for the bubble
  display: flex; // Make it a flex container
  align-items: baseline; // Align items to the text baseline
  font-family: 'Inter', sans-serif; // Set the font to Inter
  font-size: 16px;
`;

export const Content = styled.div`
  margin-left: 14px;
  line-height: 1.5;
  font-size: 16px;
`;

export const Sender = styled.div`
  font-weight: 700; // Make the sender name bold
  font-family: 'Inter', sans-serif; // Set the font to Inter
  font-size: 16px;
  min-width: 50px;
`;

export const TypingContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%; // Make the container full width
`;

export const InnerContainer = styled.div`
  display: flex;
  jus ify-content: flex-start;
  width: 40%; // Set a fixed width for the inner container
`;
