import React from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';

import { getTranslationCache } from '../../selectors/language';
import { getUserProfile } from '../../selectors/layout';
import { ChatButton, Form, StyledTextareaAutosize } from './CSS/styles';

class ChatInput extends React.Component {
  timeTicket = null;
  requestCheck = true;

  constructor(props) {
    super(props);
    this.state = {
      message: ''
    };
  }

  handleSubmit = (event) => {
    event.preventDefault();
    let { message } = this.state;
    let { chatId } = this.props;

    if (message.trim() === '') return;

    if (chatId) {
      // If there is a chatId, just send the message.
      const newMessage = {
        sender: 'user',
        content: message
      };
      this.props.onNewUserMessage(chatId, newMessage);
    } else {
      // If there is no chatId, create a new chat.
      // createChat()
    }
    this.setState({ message: '' }); // Clear the input message
  };

  render() {
    let { message } = this.state;
    return (
      <>
        <Form onSubmit={this.handleSubmit}>
          <StyledTextareaAutosize
            value={message}
            onChange={(e) => this.setState({ message: e.target.value })}
            placeholder="Type a message..."
            maxRows={10}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault();
                this.handleSubmit(e);
              }
            }}
          />
          <ChatButton type="submit">Send</ChatButton>
        </Form>
      </>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  userProfile: getUserProfile(),
  translationCache: getTranslationCache()
});
export default connect(mapStateToProps)(ChatInput);
