import React, { Component, useEffect, useRef, useState } from 'react';
import axios from 'axios';
import moment from 'moment';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { Button, Col, ConfigProvider, Drawer, Empty, Form, Input, Radio, Select, Space, Table, Tabs, Tooltip, Upload, message } from 'antd';
import {
    EyeOutlined, DownloadOutlined, SearchOutlined, UploadOutlined
} from '@ant-design/icons';

import { generateBatchAttachDetails, generateBatchTableDetails, getBatchList, getGuideList, getUserList, updatePrimaryStatus } from './Utils/apicalls';
import { StyledApplication, StyledTheme } from './CSS/style'
import { getCurrentLanguage, getTranslationCache } from '../../../../../selectors/language';
import localStorage from '../../../../../utils/localStorage';
import themeSettings from '../../../../AMM/Utils/themeSettings.json'
import translation from './Utils/translation'
import './CSS/style.css';
import Highlighter from 'react-highlight-words';
import enUS from 'antd/es/locale/en_US';
import jaJP from 'antd/es/locale/ja_JP';
moment.locale('jaJP');
const { TabPane } = Tabs;
const RadioGroup = Radio.Group;
const BatchResultsScreen = (props) => {
    const [batchList, setBatchList] = useState([]);
    const [userList, setUserList] = useState([]);
    const [imageObject, setImageObject] = useState([]);
    const [tableObject, setTableObject] = useState([]);
    const [activeKey, setactiveKey] = useState("1");
    const [batch, setBatch] = useState("");
    const [reportName, setReportName] = useState("");
    const [batchId, setBatchId] = useState("");
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [GuideList, setGuideList] = useState('');
    const [openDrawer, setOpenDrawer] = useState('');
    const searchInput = useRef(null);
    const { translationCache, currentLanguage } = props
    const [locale, setLocal] = useState(props.currentLanguage === "Japanese" ? jaJP : enUS);
    moment.locale(currentLanguage === "Japanese" ? "jaJP" : 'en');
    useEffect(() => {
        (async () => {
            try {
                let batchList = await getBatchList();
                setBatchList(batchList);
                let userList = await getUserList();
                setUserList(userList);
                let guideList = await getGuideList();
                setGuideList(guideList);
            } catch (e) {
                return {};
            }
        })();
    }, []);
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters, confirm, dataIndex) => {
        clearFilters();
        setSearchText('');
        handleSearch([], confirm, dataIndex);
    };
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={translation(translationCache, 'Search')}
                    value={`${selectedKeys[0] || ''}`}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        {translation(translationCache, 'Search')}
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters, confirm, dataIndex)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        {translation(translationCache, 'Reset')}
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        {translation(translationCache, 'Close')}
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex] && record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        }, render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    let columns = [
        {
            title: translation(translationCache, 'Hole Number'),
            dataIndex: 'HoleNumber',
            key: 'HoleNumber',
            ...getColumnSearchProps('HoleNumber'),
            width: 200,
            render(text, record) {
                if (record['total']) {
                    text = record['total']
                }
                return {
                    props: {
                        style: {
                            background: getColorCode(record, text)
                        }
                    },
                    children: (
                        <div>
                            {text !== 'null' ?
                                text && text === "Total" ? translation(translationCache, 'Total') : text
                                : ''}
                        </div>
                    )
                };
            }
        },
        {
            title: translation(translationCache, 'Gun Powder Quantity'),
            dataIndex: 'GunPowderQuantity',
            key: 'GunPowderQuantity',
            width: 800,
            children: [
                {
                    title: translation(translationCache, 'Design'),
                    dataIndex: 'Design',
                    key: 'Design',
                    width: 400,
                    children: [
                        {
                            title: translation(translationCache, 'No Of Explosives with Detonators'),
                            dataIndex: 'NoOfExplosivesWithDetonators_Design',
                            key: 'NoOfExplosivesWithDetonators_Design',
                            ...getColumnSearchProps('NoOfExplosivesWithDetonators_Design'),
                            width: 200,
                            render(text, record) {
                                return {
                                    props: {
                                        style: {
                                            background: getColorCode(record, text)
                                        }
                                    },
                                    children: (
                                        <div>
                                            {text !== 'null' ? text : ''}
                                        </div>
                                    )
                                };
                            }
                        },
                        {
                            title: translation(translationCache, 'No Of Explosives without Detonators'),
                            dataIndex: 'NoOfExplosivesWithOutDetonators_Design',
                            key: 'NoOfExplosivesWithOutDetonators_Design',
                            ...getColumnSearchProps('NoOfExplosivesWithOutDetonators_Design'),
                            width: 200,
                            render(text, record) {
                                return {
                                    props: {
                                        style: {
                                            background: getColorCode(record, text)
                                        }
                                    },
                                    children: (
                                        <div>
                                            {text !== 'null' ? text : ''}
                                        </div>
                                    )
                                };
                            }
                        }
                    ]
                },
                {
                    title: translation(translationCache, 'Actual'),
                    dataIndex: 'Actual',
                    key: 'Actual',
                    width: 400,
                    children: [
                        {
                            title: translation(translationCache, 'No Of Explosives with Detonators'),
                            dataIndex: 'NoOfExplosivesWithDetonators_Actual',
                            key: 'NoOfExplosivesWithDetonators_Actual',
                            ...getColumnSearchProps('NoOfExplosivesWithDetonators_Actual'),
                            width: 200,
                            render(text, record) {
                                return {
                                    props: {
                                        style: {
                                            background: getColorCode(record, text)
                                        }
                                    },
                                    children: (
                                        <div>
                                            {text !== 'null' ? text : ''}
                                        </div>
                                    )
                                };
                            }
                        },
                        {
                            title: translation(translationCache, 'No Of Explosives without Detonators'),
                            dataIndex: 'NoOfExplosivesWithOutDetonators_Actual',
                            key: 'NoOfExplosivesWithOutDetonators_Actual',
                            ...getColumnSearchProps('NoOfExplosivesWithOutDetonators_Actual'),
                            width: 200,
                            render(text, record) {
                                return {
                                    props: {
                                        style: {
                                            background: getColorCode(record, text)
                                        }
                                    },
                                    children: (
                                        <div>
                                            {text !== 'null' ? text : ''}
                                        </div>
                                    )
                                };
                            }
                        }
                    ]
                }
            ]
        },
        {
            title: translation(translationCache, 'IsDeleted'),
            dataIndex: 'IsDeleted',
            key: 'IsDeleted'
        },
        {
            title: translation(translationCache, 'isAdd'),
            dataIndex: 'isAdd',
            key: 'isAdd'
        },

    ];

    const getColorCode = (record, text) => {
        return record && record.IsDeleted && record.IsDeleted === true ?
            "#828282" :
            record["total"] === "Total" ? "" :
                record && record.isAdd && record.isAdd === true ? "#3b82ce" : ""
    }

    const onFormsubmit = async (batchId) => {
        let batchAttchResp = await generateBatchAttachDetails({ batchId })
        let batchTableResp = await generateBatchTableDetails({ batchId })
        let tableData = batchTableResp && batchTableResp[0] && batchTableResp[0].ListJSON ? batchTableResp[0].ListJSON : []

        setImageObject(batchAttchResp && batchAttchResp[0] ? batchAttchResp[0] : {})
        setTableObject(tableData)
        let batchNameList = batchList && Array.isArray(batchList) && batchList.filter(batch => batch.Id === batchId)

        if (batchNameList && Array.isArray(batchNameList) && batchNameList.length > 0) {
            let reportName = await getReportNametoDownload(columns, tableData, batchNameList[0].Batch);
            if (reportName && reportName.file) {
                setReportName(reportName.file)
            }
        }

    }

    const viewDetails = (batchId) => {
        setBatchId(batchId)
        onFormsubmit(batchId)
    }

    const getReportNametoDownload = (
        columns,
        datasource,
        Name
    ) => {
        let payload = {};
        if (columns && Array.isArray(columns) && datasource && Array.isArray(datasource)) {
            let reportPayload = [];

            if (datasource && datasource.length > 0) {
                datasource &&
                    Array.isArray(datasource) &&
                    datasource.map((datasourceObject) => {
                        let newObj = {};
                        columns &&
                            Array.isArray(columns) &&
                            columns.map((column) => {
                                if (column && column.children) {
                                    let newChildrenObject = {};
                                    column.children.map((columnChildren) => {
                                        newChildrenObject[columnChildren.title] = getChildren(
                                            columnChildren,
                                            datasourceObject
                                        );
                                        return {};
                                    });
                                    newObj[column.title] = {
                                        ...newChildrenObject
                                    };
                                } else {
                                    if (datasourceObject[column.key] === undefined) {
                                        if (datasourceObject['total']) {
                                            newObj[column.title] = translation(translationCache, "Total")
                                        } else {
                                            newObj[column.title] = '';

                                        }
                                    } else if (
                                        datasourceObject &&
                                        datasourceObject[column.key] &&
                                        datasourceObject[column.key] !== '-' &&
                                        column &&
                                        column.isUTC &&
                                        (column.isUTC === true || column.isUTC === 'true')
                                    ) {
                                        newObj[column.title] = moment
                                            .utc(datasourceObject[column.key])
                                            .local()
                                            .format('YYYY-MM-DD HH:mm:ss');
                                    } else {
                                        newObj[column.title] = datasourceObject[column.key];
                                    }
                                }
                                return {};
                            });
                        reportPayload.push(newObj);
                        return {};
                    });
            } else {
                let newObj = {};
                columns &&
                    Array.isArray(columns) &&
                    columns.map((column) => {
                        if (column && column.children) {
                            let newChildrenObject = {};
                            column.children.map((columnChildren) => {
                                newChildrenObject[columnChildren.title] = getChildren(columnChildren, '');
                                return {};
                            });
                            newObj[column.title] = {
                                ...newChildrenObject
                            };
                        } else {
                            newObj[column.title] = '-';
                        }
                        return {};
                    });
                reportPayload.push(newObj);
            }
            payload = {
                "Consumption": translation(translationCache, "Data"),
                "Data": reportPayload,
                "GeneratedDate": moment().format("YYYY-MM-DD HH:mm:ss"),
                "Name": translation(translationCache, Name),
                "PortName": translation(translationCache, "Okumura"),
                "ReportType": "thirdLevelHierarchy",
                "SelectedDate": "-",
                "Summary": [],
                "summaryReportType": "singleHierarchy",
                "summaryType": "false",
                "WeeklySummary": [],
                "IsTranslate": translationCache && translationCache["GeneratedDate"] ? true : false,
                "translationList": {
                    "GeneratedDate": translation(translationCache, "GeneratedDate"),
                    "SelectedDate": translation(translationCache, "SelectedDate")
                }
            }
        }

        let siteId = localStorage.get('currentSite');
        let accessToken = localStorage.get('accessToken');
        let options = {};
        options = {
            method: 'POST',
            url: `/api/file/generateExcel?SiteId=${siteId}`,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            },
            data: JSON.stringify(payload)
        };
        return axios(options)
            .then(({ data }) => {
                return data;
            })
            .catch(() => {
                return '';
            });
    }

    const getChildren = (columnChildren, datasourceObject) => {
        let newChildrenObject = {};
        if (columnChildren && columnChildren.children) {
            columnChildren.children.map((subChildren) => {
                newChildrenObject[subChildren.title] = getChildren(subChildren, datasourceObject);
                return {};
            });
            return newChildrenObject;
        } else {
            if (
                columnChildren &&
                columnChildren.key &&
                datasourceObject[columnChildren.key] === undefined
            ) {
                // newChildrenObject[columnChildren.title] = ""
                return '';
            } else {
                // newChildrenObject[columnChildren.title] = datasourceObject[columnChildren.key]
                return datasourceObject[columnChildren.key];
            }
        }
        // return newChildrenObject
    }

    let ListColumns = [
        {
            title: translation(translationCache, 'Explosion surface number'),
            dataIndex: 'Batch',
            key: 'Batch',
            ...getColumnSearchProps('Batch'),
        },
        {
            title: translation(translationCache, 'Created By'),
            dataIndex: 'SavedBy',
            key: 'SavedBy',
            ...getColumnSearchProps('SavedBy'),
            render: (text) => {
                let user = userList && userList.filter(e => e.Id === text)
                return <span>
                    {user && user[0] && user[0].FirstName ? user[0].FirstName : text}
                </span>
            }
        },
        {
            title: translation(translationCache, 'Recent timestamp'),
            dataIndex: 'SavedDate',
            key: 'SavedDate',
            render: (text, record) => {
                return <span>
                    {
                        record && record.Status && record.Status === "Submitted" ?
                            moment(record.SubmittedDate).format("YYYY-MM-DD HH:mm:ss")
                            :
                            moment(text).format("YYYY-MM-DD HH:mm:ss")
                    }

                </span>
            }
        },
        {
            title: translation(translationCache, 'Status'),
            dataIndex: 'Status',
            key: 'Status',
            // ...getColumnSearchProps('Status'),
            filters: [
                {
                    text: translation(translationCache, 'Saved'),
                    value: 'Saved',
                },
                {
                    text: translation(translationCache, 'Submitted'),
                    value: 'Submitted',
                },
            ],
            onFilter: (value, record) => record.Status.startsWith(value),
            // filterSearch: true,
            render: (text) => {
                return <span>
                    {translation(translationCache, text)}
                </span>
            }
        },
        {
            title: translation(translationCache, 'Actions'),
            dataIndex: '',
            key: '',
            width: 200,
            render: (text, record) => (
                <span>
                    <Tooltip title={translation(translationCache, "View Details")}>
                        <EyeOutlined
                            onClick={(e) => {
                                e.stopPropagation();
                                setactiveKey("2")
                                viewDetails(record.Id)
                            }}
                        />
                    </Tooltip>
                </span>
            )
        }
    ];

    const onPrimaryChange = async (value, Id) => {
        let payload = { Id, Primary: true }
        let primaryResponse = await updatePrimaryStatus(payload);
        if (primaryResponse) {
            message.success(translation(translationCache, "Image updated successfully"))
            setGuideList(primaryResponse);
        }
    }

    const siteId = localStorage.get('currentSite');
    const token = localStorage.get('accessToken');
    const Authorization = 'Bearer ' + token;
    let primaryGuideObject = GuideList && GuideList.filter(e => e.Primary === true).map(e => { return e.Id })

    if (props.currentLanguage === "Japanese" && locale.locale !== "ja") {
        setLocal(jaJP)
    } else if (props.currentLanguage === "English" && locale.locale !== "en") {
        setLocal(enUS)
    }
    return (
        <ConfigProvider locale={locale}>
            <StyledApplication key={locale ? locale.locale : 'en'} style={{ minHeight: window.innerHeight - 113 }}>
                <div className="headerCSS">{translation(translationCache, 'Batch Results')}</div>
                <div style={{ display: "flex", flexDirection: "row-reverse" }}>
                    <Button type="primary" onClick={() => setOpenDrawer(true)}>{translation(translationCache, 'Camera Guide Settings')}</Button>
                </div>
                <Drawer title={translation(translationCache, "Guide Settings")} onClose={() => setOpenDrawer(false)} open={openDrawer}>
                    <Upload
                        action={`/api/ihsr/uploadGuideImages?SiteId=${siteId}&ImageSourceType=${'Guide'}`}
                        headers={{
                            Authorization: Authorization
                        }}
                        onChange={async (info) => {
                            if (info.file.status === 'done') {
                                message.error(`Guide Image Uploaded.`);
                                let guideList = await getGuideList();
                                setGuideList(guideList);
                            } else if (info.file.status === 'error') {
                                message.error(`${info.file.name} file upload failed.`);
                            }
                        }}
                    >
                        <Button>
                            <UploadOutlined /> {translation(translationCache, ' Upload Guide Images')}
                        </Button>

                    </Upload>
                    <div>
                        <RadioGroup
                            value={primaryGuideObject && primaryGuideObject[0] ? primaryGuideObject[0] : 0}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    flexWrap: 'wrap'
                                }}
                            >
                                {GuideList && Array.isArray(GuideList) && GuideList.map((guide, index) => {

                                    return (
                                        <>
                                            <div style={{ marginTop: '20px' }}>
                                                <StyledTheme>
                                                    <img
                                                        src={`https://okumura.bmecomosolution.com/api/${guide.FilePath}`}
                                                        alt="logo"
                                                        height="12"
                                                        style={{ height: '100%' }}
                                                    />
                                                </StyledTheme>
                                                <Radio
                                                    key={index}
                                                    onClick={(e) => onPrimaryChange(e.target.checked, guide.Id)}
                                                    style={{ margin: 20, alignContent: 'left' }}
                                                    value={guide.Id}
                                                >
                                                    {guide.FileName}
                                                </Radio>
                                            </div>
                                        </>
                                    );
                                })}
                            </div>
                        </RadioGroup>
                    </div>

                </Drawer>
                <Tabs
                    type="card"
                    defaultactiveKey="1"
                    activeKey={activeKey}
                    theme={themeSettings}
                    size={'middle'}
                    onChange={(activeKey) => {
                        if (activeKey === '1') {
                            setBatch("")
                            setTableObject([])
                            setImageObject([])
                        }
                        setactiveKey(activeKey)
                    }}
                >


                    <TabPane tab={translation(translationCache, 'List')} key="1">

                        <Table
                            className="downtimeTable"
                            columns={ListColumns}
                            bordered={true}
                            dataSource={batchList}
                        />

                    </TabPane>
                    <TabPane tab={translation(translationCache, 'Details')} key="2">

                        <Form
                            layout={"inline"}
                            onFinish={() => onFormsubmit(batchId)}
                        >
                            <Form.Item label={translation(translationCache, "Explosion surface number")}>
                                <Select
                                    value={batchId}
                                    onChange={(value) => setBatchId(value)}
                                    style={{ width: 300 }}>
                                    {
                                        batchList && Array.isArray(batchList) && batchList.map((batch) => {
                                            return <Select.Option value={batch.Id}>{batch.Batch}</Select.Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" htmlType="submit">{translation(translationCache, "Display")}</Button>
                            </Form.Item>
                            <Form.Item>
                                <Button onClick={() => {
                                    setactiveKey("1")
                                    setBatch("")
                                    setTableObject([])
                                    setImageObject([])
                                }} >{translation(translationCache, "Go Back")}</Button>
                            </Form.Item>

                        </Form>
                        <div style={{ display: 'flex', flexDirection: 'row', margin: '37px 0px' }}>
                            <div>
                                {
                                    reportName ?
                                        <a
                                            target="_blank"
                                            href={`https://okumura.bmecomosolution.com/api/reports/${reportName}`}
                                            style={{ fontSize: '20px', fontWeight: 'bold' }}
                                        >
                                            <DownloadOutlined
                                                style={{ color: 'rgb(26, 54, 82)', fontSize: '20px', float: 'right' }}
                                            />
                                        </a>
                                        : null
                                }

                                <Table
                                    className="downtimeTable"
                                    columns={columns.filter((e) => e.key !== "isAdd" && e.key !== "IsDeleted")}
                                    bordered={true}
                                    dataSource={tableObject}
                                />
                            </div>
                            <div>
                                {
                                    imageObject && imageObject.FilePath ?
                                        <>
                                            <a
                                                target="_blank"
                                                href={`https://okumura.bmecomosolution.com/api/${imageObject.FilePath}`}
                                                style={{ fontSize: '20px', fontWeight: 'bold' }}
                                            >
                                                <DownloadOutlined
                                                    style={{ color: 'rgb(33, 73, 114)', fontSize: '20px', float: 'right' }}
                                                />
                                            </a>
                                            <div><img
                                                src={`/api/${imageObject.FilePath}`}
                                                alt=""
                                                height="300"
                                            /></div></>
                                        :
                                        <><Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /></>
                                }

                            </div>
                        </div>
                    </TabPane>
                </Tabs>
            </StyledApplication>
        </ConfigProvider>
    );
}


const mapStateToProps = createStructuredSelector({
    translationCache: getTranslationCache(),
    currentLanguage: getCurrentLanguage()
});

export default connect(mapStateToProps)(BatchResultsScreen);
