import React, { Fragment } from 'react';
import moment from 'moment';
import ReactEcharts from '../../Echarts/Echarts';
import { connect } from 'react-redux';
import { Tag } from 'antd';

import { createStructuredSelector } from 'reselect';
import { confusionMatrixMockJson } from '../../../Mock/MockJson';
import { getChartDataWithoutSelection, getChartDataWithSelection } from '../../ChartCalls';
import { getTranslationCache } from '../../../../../selectors/language';
import { makeSelectThemeing } from '../../../../../selectors/theme';
import { defaultRefreshTime } from '../../constant.json';
import { ErrorComponent, NoRecordComponent } from '../../Utils';

class Heatmap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      option: {},
      translationCache: props.translationCache || [],
      refreshDateTime: moment(),
      errorInAPI: false,
      noDataInDB: false,
      seriesData: []
    };
  }

  timeTicket = null;
  requestCheck = true;
  abortController = new window.AbortController();
  async componentDidMount() {
    if (this.timeTicket) {
      clearInterval(this.timeTicket);
    }
    this.getData(this.props);

    let refreshTime = '';
    if (this.props.graphprops.refreshTime) {
      refreshTime = this.props.graphprops.refreshTime * 60000;
    } else {
      refreshTime = defaultRefreshTime;
    }

    this.timeTicket = setInterval(() => {
      if (this.requestCheck) {
        this.getData(this.props, this.props.selectedThemeing);
      }
    }, refreshTime);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.graphprops !== this.props.graphprops ||
      prevProps.selectionDate !== this.props.selectionDate ||
      prevProps.selectionDateLive !== this.props.selectionDateLive ||
      prevProps.selectionMonth !== this.props.selectionMonth ||
      prevProps.selectionWeek !== this.props.selectionWeek ||
      prevProps.DeviceCode !== this.props.DeviceCode ||
      prevProps.shift !== this.props.shift ||
      prevProps.selectionDateRange !== this.props.selectionDateRange ||
      prevProps.dayMonthYear !== this.props.dayMonthYear ||
      prevProps.dropdownSelection !== this.props.dropdownSelection ||
      prevProps.dashboardPayload !== this.props.dashboardPayload ||
      prevProps.AssetCode !== this.props.AssetCode ||
      prevProps.translationCache !== this.props.translationCache
    ) {
      this.getData();
    }
  }

  componentWillUnmount() {
    this.abortController.abort();
    clearInterval(this.timeTicket);
  }

  getData = async () => {
    this.requestCheck = false;
    let json = [];
    const { graphprops } = this.props;
    let option = this.getOption();
    let { errorInAPI, noDataInDB } = this.state;
    errorInAPI = false;
    noDataInDB = false;
    if (graphprops && graphprops.enableMock && graphprops.enableMock === true) {
      json = confusionMatrixMockJson;
      console.log(json, 'jsonn');
      this.requestCheck = true;
    } else {
      if (
        (graphprops && graphprops.dashboardType === 'dynamic') ||
        (graphprops.dashboardType === 'dropdownHide' && this.props.DeviceCode) ||
        (graphprops &&
          (graphprops.dateExists === 'true' || graphprops.selection === 'datePicker') &&
          this.props.selectionDate) ||
        (graphprops && graphprops.monthExists === 'true' && this.props.selectionMonth) ||
        (graphprops && graphprops.weekExists === 'true' && this.props.selectionWeek) ||
        (graphprops && graphprops.shiftExists === 'true' && this.props.shift) ||
        (graphprops &&
          (graphprops.dateRangeExists === 'true' || graphprops.selection === 'dateRangePicker') &&
          this.props.selectionDateRange) ||
        (graphprops &&
          (graphprops.dayMonthYearExists === 'true' ||
            graphprops.selection === 'dateMonthYearPicker') &&
          this.props.dayMonthYear) ||
        (graphprops && graphprops.dashboardType === 'hierarchy' && this.props.dropdownSelection) ||
        (graphprops && this.props.dashboardPayload) ||
        (graphprops && graphprops.treeExists === 'true' && this.props.AssetCode)
      ) {
        json = await getChartDataWithSelection(
          graphprops,
          this.props.DeviceCode,
          this.props.selectionDate,
          this.props.selectionMonth,
          this.props.selectionWeek,
          this.props.shift,
          this.props.selectionDateRange,
          this.props.dayMonthYear,
          this.abortController,
          this.props.dropdownSelection,
          this.props.dashboardPayload,
          this.props.AssetCode,
          this.props.selectionDateLive,
          this.props.currentTimeZone
        );
        this.requestCheck = true;
      } else {
        json = await getChartDataWithoutSelection(
          graphprops,
          this.abortController,
          this.props.dropdownSelection,
          this.props.selectionDateLive,
          '',
          this.props.currentTimeZone
        );
        this.requestCheck = true;
      }
    }

    if (
      (json && json.message) ||
      (Array.isArray(json) && json.length === 0 && graphprops.enableMock !== true)
    ) {
      if (json.message) {
        errorInAPI = true;
      } else if (json.length === 0) {
        noDataInDB = true;
      }
    } else {
      let seriesData = [];
      if (json && Array.isArray(json)) {
        //  let seriesData = json && Array.isArray(json);
        this.setState({ seriesData: json });
        option.series.data = seriesData;
        this.setState({
          option,
          refreshDateTime: moment()
        });
      }
    }
    this.setState({
      errorInAPI,
      noDataInDB
    });
  };

  render() {
    const { errorInAPI, noDataInDB, refreshDateTime } = this.state;
    let { graphprops } = this.props;

    console.log(this.state.option, 'optionnn');
    return (
      <Fragment>
        {graphprops && graphprops.checked === true ? (
          <Tag className="graphTag" color="blue">
            {refreshDateTime ? moment(refreshDateTime).format('YYYY-MM-DD HH:mm:ss') : ''}
          </Tag>
        ) : null}
        {noDataInDB === true || errorInAPI === true ? (
          noDataInDB === true ? (
            <NoRecordComponent />
          ) : (
            <ErrorComponent />
          )
        ) : (
          <ReactEcharts
            id={Math.ceil(Math.random(100, 100000) * 1000)}
            option={this.state.option}
            style={{
              height: '100%',
              width: '100%'
            }}
            className="react_for_echarts"
          />
        )}
      </Fragment>
    );
  }

  getOption = () => {
    // let theme = this.props.selectedThemeing;
    // let range = '';
    // let { dateExists, monthExists } = this.props.graphprops;
    // if (dateExists && dateExists === 'true') {
    //    let range = moment(this.props.selectionDate, 'YYYY-MM-DD 00:00:00').format('YYYY-MM');
    // } else if (monthExists && monthExists === 'true') {
    //   //  let range = this.props.selectionMonth;
    // }
    let option = {
      tooltip: {
        position: 'top'
      },

      // animation: false,
      grid: {
        height: '50%',
        top: '10%'
      },
      xAxis: {
        type: 'category',
        nameLocation: 'middle',
        name: 'Predicted Label',
        data: [0, 1],
        splitArea: {
          show: true
        }
      },
      yAxis: {
        type: 'category',
        name: 'True Label',
        nameLocation: 'middle',
        data: [0, 1],
        splitArea: {
          show: true
        }
      },
      visualMap: {
        min: 0,
        max: 1,
        calculable: true,
        orient: 'horizontal',
        left: 'center',
        bottom: '15%',
        show: false
      },
      series: [
        {
          name: 'heatmap',
          type: 'heatmap',
          color: '#91cc75',
          label: {
            show: true,
            position: 'inside'
          },
          itemStyle: {
            color: 'grey'
          },
          // data:[]
          data: this.state.seriesData
        }
      ]
    };
    return option;
  };
}

const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache(),
  selectedThemeing: makeSelectThemeing()
});

export default connect(mapStateToProps)(Heatmap);
