import React from 'react';
import moment from 'moment';
import axios from 'axios';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';

import { getTranslationCache } from '../../../selectors/language';
import localStorage from '../../../utils/localStorage';
import { getCurrentTimezone } from '../../../selectors/layout';
import ReactEcharts from '../../Dashboard/Utils/Echarts/Echarts';
import { getGraphData } from '../apiCalls';
import { Radio, Select, DatePicker } from 'antd';
import { constants } from '../Utils/constants';
import Table from '../Widget/Table';
const { RangePicker } = DatePicker;
const color = [
  'orange',
  'tomato',
  'steelblue',
  'green',
  'pink',
  'voilet',
  'yellow',
  'red',
  'blue',
  'gray'
];
class AllGraphView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      SelectedInterval:
        this.props && this.props.payload && this.props.payload.Interval
          ? this.props.payload.Interval
          : '',
      operatorList: constants && constants.operatorList ? constants.operatorList : [],
      frequencyList: constants && constants.frequencyList ? constants.frequencyList : [],
      selectedOperators:
        this.props && this.props.payload && this.props.payload.Operator
          ? this.props.payload.Operator
          : '',
      payload: this.props && this.props.payload ? this.props.payload : {},
      translationCache: props.translationCache || [],
      GraphType: 'line',
      FilterData: [],
      AllFilterData: [],
      option: {
        xAxis: {
          type: 'category',
          data: []
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: [],
            type: 'line'
          }
        ]
      }
    };
  }
  componentDidMount() {
    this.generateData();
  }
  componentDidUpdate(prevprops) {
    if (prevprops.payload !== this.props.payload) {
      this.generateData();
    }
    if (
      prevprops.translationCache !== this.props.translationCache ||
      prevprops.userProfile !== this.props.userProfile
    ) {
      this.setState({
        translationCache: this.props.translationCache,
        userProfile: this.props.userProfile
      });
    }
  }

  generateData = async (test) => {
    const graph = this;
    let { payload } = this.state;
    let payload1 = test ? test : payload;
    let json = await getGraphData(payload1);
    let GraphType = payload.GraphType;
    this.prepareData(json, payload1);
    const legends = [...new Set(json.map((item) => item.Name))];
    const xAxis = [...new Set(json.map((item) => item.DateTime))];

    let series =
      legends &&
      Array.isArray(legends) &&
      legends.map((xAxisName) => {
        let data = json
          .filter((e) => e.Name === xAxisName)
          .map((jsonData) => {
            return jsonData.Value;
          });
        return {
          name: xAxisName,
          type: GraphType,
          data
        };
      });

    let option = {
      tooltip: {
        trigger: 'axis'
      },
      legend: {
        data: legends
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      toolbox: {
        feature: {
          myTool1: {
            show: true,
            title: 'custom extension method 1',
            icon: 'path://M854.6 288.6L639.4 73.4c-6-6-14.1-9.4-22.6-9.4H192c-17.7 0-32 14.3-32 32v832c0 17.7 14.3 32 32 32h640c17.7 0 32-14.3 32-32V311.3c0-8.5-3.4-16.7-9.4-22.7zM790.2 326H602V137.8L790.2 326zm1.8 562H232V136h302v216a42 42 0 0 0 42 42h216v494zM514.1 580.1l-61.8-102.4c-2.2-3.6-6.1-5.8-10.3-5.8h-38.4c-2.3 0-4.5.6-6.4 1.9-5.6 3.5-7.3 10.9-3.7 16.6l82.3 130.4-83.4 132.8a12.04 12.04 0 0 0 10.2 18.4h34.5c4.2 0 8-2.2 10.2-5.7L510 664.8l62.3 101.4c2.2 3.6 6.1 5.7 10.2 5.7H620c2.3 0 4.5-.7 6.5-1.9 5.6-3.6 7.2-11 3.6-16.6l-84-130.4 85.3-132.5a12.04 12.04 0 0 0-10.1-18.5h-35.7c-4.2 0-8.1 2.2-10.3 5.8l-61.2 102.3z',

            onclick: function () {
              graph.downLoadExcel();
            }
          },
          saveAsImage: {}
        }
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: xAxis
      },
      yAxis: {
        type: 'value'
      },
      series: series
    };
    this.setState({
      option
    });
  };
  prepareData = async (json, trendData) => {
    let { option, GraphType } = this.state;
    let xaxis = [];
    let series = [];
    let reportData =
      json &&
      Array.isArray(json) &&
      json.map((data) => {
        return {
          ...data,
          DateTime: moment
            .utc(data.DateTime, 'YYYY-MM-DD HH:mm:ss')
            .tz(this.props.currentTimeZone)
            .format('YYYY-MM-DD HH:mm')
        };
      });
    let { fromDate, toDate, fromDate1, toDate1, fromDate2, toDate2 } = {};
    let reportName = await this.getReportNametoDownload(
      reportData,
      trendData.Type,
      { fromDate, toDate },
      { fromDate1, toDate1 },
      { fromDate2, toDate2 }
    );
    this.setState({
      reportData,
      reportName: reportName && reportName.file ? reportName.file : ''
    });
    json &&
      json &&
      Array.isArray(json) &&
      json.map((data) => {
        data = {
          ...data,
          DateTime: moment
            .utc(data.DateTime, 'YYYY-MM-DD HH:mm:ss')
            .tz(this.props.currentTimeZone)
            .format('YYYY-MM-DD HH:mm')
        };
        if (!xaxis.includes(data.DateTime)) {
          xaxis.push(data.DateTime);
        }
        if (series.map((data) => data.name).includes(data.Name)) {
          let index = series.map((data) => data.name).indexOf(data.Name);
          series[index].data.push([data.DateTime, data.Value]);
        } else {
          let obj = {
            name: data.Name,
            data: [[data.DateTime, data.Value]],
            type: GraphType && GraphType !== 'area' ? GraphType : 'line',
            ...(GraphType && GraphType === 'area' ? { areaStyle: {} } : {})
          };
          series.push(obj);
        }
        return {};
      });
    option.xAxis.data = xaxis;
    option.series = series;
    this.setState({
      option
    });
  };
  downLoadExcel = () => {
    const { reportName } = this.state;
    const link = document.createElement('a');
    link.href = `${process.env.REACT_APP_Primary_IP}/api/reports/${reportName}`;
    link.setAttribute('download', reportName); //or any other extension
    document.body.appendChild(link);
    link.click();
  };
  getReportNametoDownload(excelData, Type, SelectedDate) {
    let payload = {
      PortName: 'Rubus',
      Name: Type && Type ? Type : 'Trend',
      Consumption: 'Trend',
      Data: excelData || [],
      ReportType: 'singleHierarchy',
      GeneratedDate: moment().format('YYYY-MM-DD HH:mm:ss'),
      SelectedDate:
        moment
          .utc(SelectedDate.fromDate)
          .tz(this.props.currentTimeZone)
          .format('YYYY-MM-DD HH:mm') +
        '~' +
        moment.utc(SelectedDate.toDate).tz(this.props.currentTimeZone).format('YYYY-MM-DD HH:mm'),
      SelectionType: 'dateRange'
    };
    let siteId = localStorage.get('currentSite');
    let accessToken = localStorage.get('accessToken');
    let options = {};
    options = {
      method: 'POST',
      url: `/api/file/generateExcel?SiteId=${siteId}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      },
      data: JSON.stringify(payload)
    };
    return axios(options)
      .then(({ data }) => {
        return data;
      })
      .catch(() => {
        return '';
      });
  }
  onChangeFromDate_ToDate1 = (selectedFromDate_ToDate1) => {
    let { payload } = this.state;
    payload = {
      ...payload,
      FromDate: moment(selectedFromDate_ToDate1[0]).utc().format('YYYY-MM-DD HH:mm:ss'), //conversion to utc
      ToDate: moment(selectedFromDate_ToDate1[1]).utc().format('YYYY-MM-DD HH:mm:ss')
    };
    // FromDate: moment(selectedFromDate_ToDate1[0]).utc().format('YYYY-MM-DD HH:mm:ss'), //conversion to utc
    // ToDate: moment(selectedFromDate_ToDate1[1]).utc().format('YYYY-MM-DD HH:mm:ss')
    this.setState({
      selectedFromDate_ToDate1
    });
    this.generateData(payload);
    this.setState({ payload });
  };
  onIntervalChange = async (Interval) => {
    let { payload } = this.state;
    if (payload && payload.GraphType !== 'table') {
      payload = {
        ...payload,
        Interval
      };
      this.generateData(payload);
      this.setState({ payload, SelectedInterval: Interval });
    } else {
      payload = {
        ...payload,
        Interval
      };
      let json = await getGraphData(payload);
      this.setState({ json: json, SelectedInterval: Interval, payload });
    }
  };
  onCheckboxChange = async (Operator) => {
    let { payload } = this.state;
    if (payload && payload.GraphType !== 'table') {
      payload = {
        ...payload,
        Operator
      };
      this.generateData(payload);
      this.setState({ payload, selectedOperator: Operator });
    } else {
      payload = {
        ...payload,
        Operator
      };
      let json = await getGraphData(payload);
      this.setState({ payload, json, selectedOperator: Operator });
    }
    // let { payload } = this.state;
    // payload = {
    //   ...payload,
    //   Operator//   // let { selectedOperators } = this.state;
    //   let { payload } = this.state;
    //   payload = {
    //     ...payload,
    //     Operator
    //   };
    //   this.generateData(payload);
    //   this.setState({ payload, selectedOperators: Operator });
    //   // if (e.target.checked === true) {
    //   //   this.setState({ selectedOperators: e.target.value });
    //   //   let { payload } = this.state;
    //   //   payload = {
    //   //     ...payload,
    //   //     Operator: e.target.value
    //   //   };
    //   //   this.setState({ payload });
    //   //   this.generateData(payload);
    //   // }
    //   // else if(e.target.checked === false)
    //   // {
    //   //   // let data = checkedArray.filter((val) => val !== e.target.value);
    //   // //   this.setState({ checkedArray: data });
    //   // //   this.props.handleDropdownChange(data);
    //   // }

    //   // if (e.target.checked === true) {
    //   //   checkedArray.push(e.target.value);
    //   //   this.setState({ checkedArray });
    //   //   this.props.handleDropdownChange(checkedArray);
    //   // } else if (e.target.checked === false) {
    //   //   let data = checkedArray.filter((val) => val !== e.target.value);
    //   //   this.setState({ checkedArray: data });
    //   //   this.props.handleDropdownChange(data);
    //   // }
    // };
    // };
    // this.generateData(payload);
    // this.setState({ payload, selectedOperator: Operator });
  };

  radioOnChange = async (e) => {
    let GraphType = e.target.value;
    let { payload } = this.state;
    if (GraphType !== 'table') {
      payload = {
        ...payload,
        GraphType
      };
      this.generateData(payload);
      this.setState({ payload, GraphType });
    } else {
      payload = {
        ...payload,
        GraphType
      };
      let json = await getGraphData(payload);
      this.setState({ json: json, payload, GraphType });
    }
  };

  translation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };
  render() {
    let {
      SelectedInterval,
      selectedOperators,
      GraphType,
      payload,
      frequencyList,
      operatorList,
      json
    } = this.state;
    return (
      <>
        {/* <div style={{ margin: '5px 0px', display: 'flex', justifyContent: 'flex-end' }}>
          {constants &&
            Array.isArray(constants.operatorList) &&
            constants.operatorList &&
            constants.operatorList.map((operator, index) => {
              return (
                <Checkbox
                  key={operator.label}
                  // value={operator}
                  defaultValue={selectedOperators}
                  checked={operator.value === selectedOperators}
                  onChange={(e) => this.onCheckboxChange(e, index)}
                >
                  {operator.label}
                </Checkbox>
              );
            })}
        </div> */}
        <div>
          <RangePicker
            showTime={{ format: 'HH:mm' }}
            format="YYYY-MM-DD HH:mm"
            style={{ width: '330px' }}
            placeholder={[this.translation('Start DateTime'), this.translation('End DateTime')]}
            defaultValue={[
              moment.utc(payload.FromDate).local(),
              moment.utc(payload.ToDate).local()
            ]}
            onChange={this.onChangeFromDate_ToDate1}
            onOk={this.onOk}
            disabledDate={this.disabledDate}
          />
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', margin: '5px 0px' }}>
          <Radio.Group onChange={(e) => this.radioOnChange(e)} value={GraphType}>
            <Radio.Button value={'line'}>Line</Radio.Button>
            <Radio.Button value={'bar'}>Bar</Radio.Button>
            <Radio.Button value={'area'}>Area</Radio.Button>
            <Radio.Button value={'table'}>Table</Radio.Button>
          </Radio.Group>
          {payload && payload.Type === 'historic' ? (
            <>
              <Select
                showSearch
                style={{ width: 100 }}
                value={SelectedInterval}
                defaultValue={SelectedInterval}
                placeholder="Select Interval"
                onChange={this.onIntervalChange}
                options={frequencyList}
              />
              <Select
                showSearch
                style={{ width: 100 }}
                value={selectedOperators}
                defaultValue={selectedOperators}
                placeholder="Select Operator"
                onChange={this.onCheckboxChange}
                options={operatorList}
              />
            </>
          ) : (
            ''
          )}
          {/* <Select
            // showSearch
            defaultValue={SelectedInterval}
            placeholder="Select interval"
            onChange={this.onIntervalChange}
            // onSearch={onSearch}
            // filterOption={filterOption}
            options={[
              {
                value: '1m',
                label: '1m'
              },
              {
                value: '1h',
                label: '1h'
              }
            ]}
          /> */}
        </div>
        {GraphType === 'table' ? (
          <Table
            panelData={{
              graph: 'trends',
              trendData: json,
              colors: color,
              update: this.state.update,
              GraphType
            }}
            currentTimeZone={this.props.currentTimeZone}
            className="trendTable"
            dataSource={this.state.json}
          />
        ) : (
          <ReactEcharts
            option={this.state.option}
            notMerge={true}
            lazyUpdate={true}
            style={{ height: '80%', width: '100%' }}
            className="react_for_echarts"
            theme="dark"
          />
        )}
      </>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  currentTimeZone: getCurrentTimezone(),
  translationCache: getTranslationCache()
});
export default connect(mapStateToProps)(AllGraphView);
