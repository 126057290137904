import React from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';

import { getTranslationCache } from '../../selectors/language';
import { getUserProfile } from '../../selectors/layout';
import { Bubble, Container, Content, Sender } from './CSS/styles';

class ChatMessage extends React.Component {
  timeTicket = null;
  requestCheck = true;

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let { isUser, content, sender } = this.props;
    return (
      <>
        <Container isUser={isUser}>
          <Bubble isUser={isUser}>
            <Sender>{sender}</Sender>
            <Content>
              {content
                .toString()
                .split('\n')
                .map((line, index) =>
                  line === '' ? <br key={index} /> : <div key={index}>{line}</div>
                )}
            </Content>
          </Bubble>
        </Container>
      </>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  userProfile: getUserProfile(),
  translationCache: getTranslationCache()
});
export default connect(mapStateToProps)(ChatMessage);
