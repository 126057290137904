import { useEffect } from 'react';
import L from 'leaflet';
import { useLeaflet } from 'react-leaflet';
import 'leaflet.markercluster/dist/leaflet.markercluster';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';

// import Red_crane from './images/Red_crane.png';
// import Green_crane from './images/Green_crane.png';
// import Yellow_crane from './images/Yellow_crane.png';

const markerClusters = L.markerClusterGroup();
const MarkerCluster = ({ markers, parentCallback }) => {
  const { map } = useLeaflet();
  useEffect(() => {
    markerClusters.clearLayers();
    markers &&
      Array.isArray(markers) &&
      markers.forEach((mark) => {
        let { position, status, title, asset } = mark;
        const popupContent = '<h2>' + title + '</h2>';
        let imageFile = '';
        if (asset === 'RS') {
          imageFile = `/api/masterImages/RS.png`;
        } else if (asset === 'ITV') {
          imageFile = `/api/masterImages/ITV.png`;
        } else if (asset === 'ECH') {
          imageFile = `/api/masterImages/ECH.png`;
        } else if (asset === 'GIS Tracking') {
          imageFile = `/api/masterImages/GIS Tracking.png`;
        } else if (asset === 'MHC' || asset === 'MHCS1') {
          imageFile = `/api/masterImages/ST_MHC_V1.png`;
        } else if (asset === 'QC' || asset === 'QCS1' || asset === 'QCS2') {
          imageFile = `/api/masterImages/ST_QC_V1.png`;
        } else if (asset === 'RTGS1' || asset === 'RTGS2' || asset === 'RTG') {
          imageFile = `/api/masterImages/ST_RTG_V1.png`;
        }

        let color = 'white';
        if (status && status === 'Running') {
          color = '#27A82A';
        } else if (status && status === 'StandBy') {
          color = '#858282';
        } else if (status && status === 'Idle') {
          color = '#E3E30B';
        }
        L.marker(new L.LatLng(position.lat, position.lng), {
          icon: new L.DivIcon({
            className: 'my-div-icon',
            html:
              '<div ><img height="60" weight="60" class="my-div-image" src=' +
              imageFile +
              '></img' +
              `<span>` +
              '<span style="color: black;font-weight: bold;font-size: 13px;margin-left:25px;">' +
              '<span class="circle-css" style="background-color:' +
              color +
              '"> ' +
              '<span style="margin-left: 15px;">' +
              title +
              '</span>' +
              '</span>' +
              '</span></span></div>'
          })
          // icon: new L.Icon({
          //   iconUrl: imageFile,
          //   iconSize: new L.Point(60, 75)
          // })
        })
          .addTo(markerClusters)
          // .bindPopup(popupContent)
          .bindTooltip(popupContent)
          .on('click', function () {
            parentCallback(mark);
          });
      });
    map.addLayer(markerClusters);
  }, [markers, map]);

  // map.on('moveend', () => {
  //   const start = window.performance.now();

  //   addMarkers();
  //   const markersToAdd = [];
  //   markerClusters.clearLayers();
  //   markers &&
  //     Array.isArray(markers) &&
  //     markers.forEach((mark) => {
  //       let { position, title, image } = mark;
  //       const popupContentOn = '<h2>' + title + '</h2>';
  //       let imageFile = Green_crane;
  //       if (image === 'Green_crane') {
  //         imageFile = Green_crane;
  //       } else if (image === 'Red_crane') {
  //         imageFile = Red_crane;
  //       } else if (image === 'Yellow_crane') {
  //         imageFile = Yellow_crane;
  //       }
  //       const markerToAdd = L.marker(new L.LatLng(position.lat, position.lng), {
  //         icon: new L.DivIcon({
  //           className: 'my-div-icon',
  //           html:
  //             '<img height="60" weight="80" class="my-div-image" src=' +
  //             imageFile +
  //             '/>' +
  //             '<span style="color: black;font-weight: bold;font-size: 15px;">' +
  //             title +
  //             '</span>'
  //         })
  //         // icon: new L.Icon({
  //         //   iconUrl: imageFile,
  //         //   iconSize: new L.Point(60, 75)
  //         // })
  //       })
  //         .addTo(markerClusters)
  //         // .bindPopup(() => { parentCallback(mark,2) });
  //         .on('click', function () {
  //           parentCallback(mark, 1);
  //         });

  //       markerToAdd.bindTooltip(popupContentOn);

  //       if (markerToAdd !== undefined) {
  //         markersToAdd.push(markerToAdd);
  //       }
  //     });

  //   markerClusters.addLayers(markersToAdd);
  //   const end = window.performance.now();
  // });

  return null;
};

export default MarkerCluster;
