import React, { Component } from 'react';
// import _ from 'lodash';
// import moment from 'moment';
import Highlighter from 'react-highlight-words';
import Flowchart from 'flowchart-react';
import { Button, Row, Col, Tag, Input, Popconfirm, Tooltip, Drawer, message } from 'antd';
import {
  NodeExpandOutlined,
  SearchOutlined,
  DeleteOutlined,
  EditOutlined,
  QuestionCircleOutlined,
  ApartmentOutlined
} from '@ant-design/icons';
import { connect } from 'react-redux';
import ListView from '../../../Utils/ListView';
import { createStructuredSelector } from 'reselect';
import { getTranslationCache } from '../../../../../selectors/language';
import history from '../../../../../commons/history';
import { encode as base64_encode } from 'base-64';
import {
  getWorkOrdersList,
  // getWorkOrderById,
  deleteWorkOrder,
  changeWorkOrderStatus,
  workOrderStatus,
  getWorkFlow,
  getUsersList
} from '../../../Utils/FormCalls';
import themeSettings from '../../../Utils/themeSettings.json';
import translation from '../../../Utils/translation';
import { StyledComponent } from '../../../CSS/style';
import { constant } from '../../../Utils/constants';

class WorkOrderList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userList: [],
      dataSource: [],
      activeKey: '1',
      editWork: false,
      initate: false,
      form: {},
      visible: false,
      workFlow: {},
      selectedId: '',
      config: {
        viewTypes: ['add', 'card', 'table'],
        defaultViewType: 'table',

        filterType: 'ByAttributeValue',
        attributeList: ['WorkOrder', 'Description'],

        firstTitle: 'WorkOrder',
        secondTitle: 'CreatedBy',
        thirdTitle: 'newCreatedTime',

        actions: ['edit', 'delete', 'details'],
        edit: {
          byAttribute: 'Id',
          redirectURL: '/rubus/AMM/WorkOrderDetails',
          redirectDetailsURL: '/rubus/AMM/WorkOrderTaskDetails'
        },
        deletee: {
          byAttribute: 'Id'
        }
      }
    };
  }
  componentDidMount = async () => {
    this._getAdhocDataSource();
  };

  componentDidUpdate = async (prevProps) => {
    if (prevProps.translationCache !== this.props.translationCache) {
      this.setState({
        translationCache: this.props.translationCache
      });
    }
    let searchParams = new URLSearchParams(this.props.history.location.search);
    let newParams = Object.fromEntries([...searchParams]);
    if (this.state.selectedId !== newParams.id && newParams.id) {
      this.editWorkOrder(newParams.id);
    }
  };

  _getAdhocDataSource = async () => {
    let dataSource = await getWorkOrdersList();
    this.setState({
      dataSource,
      activeKey: '1',
      editWork: false,
      selectedId: '',
      form: {}
    });
  };
  editWorkOrder = async (record) => {
    let { config } = this.state;
    history.push({ pathname: `${config.edit.redirectURL}/${base64_encode(record)}` });
  };
  handleCancel = () => {
    this.setState({ visible: false });
  };
  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          //  icon={<SearchOutlined/>}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          // type="link"
          size="small"
          style={{ width: 90 }}
          onClick={() => {
            clearFilters();
            confirm({
              closeDropdown: true
            });
          }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        type="search"
        style={{ color: filtered ? '#1890ff' : undefined, fontSize: '130%' }}
      />
    ),
    onFilter: (value, record) =>
      record &&
      record[dataIndex] &&
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text && text.toString()}
      />
    )
  });

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };

  _deleteById = async (id) => {
    await deleteWorkOrder(id);
    let dataSource = await getWorkOrdersList();
    this.setState({
      dataSource,
      activeKey: '1',
      initate: false
      // form: {}
    });
  };
  changeStatus = async (id) => {
    await changeWorkOrderStatus(id);
    let dataSource = await getWorkOrdersList();
    this.setState({
      dataSource,
      activeKey: '1',
      initate: false
      // form: {}
    });
  };
  viewWorkFlow = async () => {
    let { form } = this.state;
    this.setState({ visible: true });
    let NodeData = await getWorkFlow(form && form.WorkFlowId);
    this.setState({ workFlow: NodeData[0].Node });
  };
  initateWorkOrder = async () => {
    let { form } = this.state;
    let payload = {
      Id: form.Id,
      WorkFlowId: form.WorkFlowId,
      WfStatus: 'Submit',
      WorkTypeId: form.WorkTypeId,
      WorkOrderName: form.WorkOrder,
      RoleId: 1
    };
    let initateWO = await workOrderStatus(payload);
    message.info(`${initateWO.message}`);
    this.setState({ initate: true });
  };

  _getUserList = async () => {
    let userList = await getUsersList();
    this.setState({ userList });
  };
  render() {
    const { dataSource, form, activeKey, editWork, visible, workFlow, initate, userList } =
      this.state;
    let { translationCache } = this.props;
    let columns = [
      {
        title: translation(translationCache, constant.workOrder),
        dataIndex: 'WorkOrder',
        key: 'WorkOrder',
        filter: true,
        ...this.getColumnSearchProps('WorkOrder')
      },
      {
        title: translation(translationCache, constant.description),
        dataIndex: 'Description',
        key: 'Description',
        ...this.getColumnSearchProps('Description', 'Description')
      },
      {
        title: translation(translationCache, constant.site),
        dataIndex: 'SiteName',
        key: 'SiteName',
        filter: true
      },
      {
        title: translation(translationCache, constant.status),
        dataIndex: 'Status',
        key: 'Status',
        render: (text, record) => (
          <>
            <Tooltip title={`Click To ${text === 'Active' ? 'Inactive' : 'Active'}`}>
              <span onClick={() => this.changeStatus(record.Id)}>
                <Tag
                  color={
                    text && text === 'Active' ? '#87d068' : text === 'Inactive' ? '#ff4d4f' : 'gold'
                  }
                  key={text}
                >
                  {text}
                </Tag>
              </span>
            </Tooltip>
          </>
        )
      },
      {
        title: translation(translationCache, constant.action),
        width: '600',
        render: (text, record) => (
          <>
            <Button
              disabled={record.Status === 'Inactive' ? true : false}
              style={{ marginRight: '5px' }}
              className="ant-btn"
              onClick={() => {
                this.editWorkOrder(record.Id);
              }}
              size="middle"
            >
              <Tooltip title={'Edit'}>
                <EditOutlined />
              </Tooltip>
            </Button>
            <Popconfirm
              title={translation(
                translationCache,
                'Are you sure that you want to delete the WorkOrder'
              )}
              icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
              onConfirm={() => this._deleteById(record.Id)}
              okText={translation(translationCache, constant.yes)}
              cancelText={translation(translationCache, constant.no)}
            >
              <Button className="ant-btn" size="middle">
                <span style={{ color: '#ff4d4f' }}>
                  {' '}
                  <Tooltip title={'Delete'}>
                    <DeleteOutlined />
                  </Tooltip>
                </span>
              </Button>
            </Popconfirm>
          </>
        )
      }
    ];
    let config = {
      title: translation(translationCache, 'WorkOrder'),

      viewTypes: ['add', 'card', 'table'],
      defaultViewType: 'table',

      filterType: 'ByAttributeValue',
      attributeList: ['WorkOrder', 'Description'],

      firstTitle: 'WorkOrder',
      secondTitle: 'CreatedBy',
      thirdTitle: 'newCreatedTime',

      actions: ['edit', 'delete', 'details'],
      edit: {
        byAttribute: 'Id',
        redirectURL: '/rubus/AMM/WorkOrderDetails',
        redirectDetailsURL: '/rubus/AMM/WorkOrderTaskDetails'
      },
      deletee: {
        byAttribute: 'Id'
      }
    };
    return (
      <StyledComponent
        theme={themeSettings}
        style={{ padding: '0px 30px', minHeight: window.innerHeight - 113 }}
      >
        <Drawer
          // width={'1400px'}
          width={window.innerWidth > 19000 ? 18000 : window.innerWidth - 100}
          visible={visible}
          onClose={this.handleCancel}
          title="WorkFlow Flow Chart"
        >
          <Flowchart
            showToolbar={false}
            style={{ width: '100%', height: 500 }}
            nodes={workFlow && workFlow.nodes}
            connections={workFlow && workFlow.connections}
          />
        </Drawer>
        <Row style={{ display: 'flex', flexDirection: 'row-reverse' }}>
          <Col style={{ width: '45px' }}>
            {editWork === true ? (
              <Tooltip title="View WorkFlow">
                <Button
                  type="primary"
                  // disabled={form && form.RecordStatus === null ? false : true}
                  onClick={() => {
                    this.viewWorkFlow();
                  }}
                  shape="circle"
                  icon={<ApartmentOutlined />}
                />
              </Tooltip>
            ) : null}
          </Col>
          <Col style={{ width: '45px' }}>
            {editWork === true ? (
              <Tooltip title="Initiate WorkFlow">
                <Button
                  type="primary"
                  disabled={form && form.RecordStatus === null && initate === false ? false : true}
                  onClick={() => {
                    this.initateWorkOrder();
                  }}
                  shape="circle"
                  icon={<NodeExpandOutlined />}
                />
              </Tooltip>
            ) : null}
          </Col>
          <Col>
            {activeKey === '2' && editWork === false ? (
              <>
                <Button
                  type="primary"
                  size="large"
                  className="saveButton"
                  onClick={() => {
                    this.setState({
                      activeKey: '2',
                      form: {}
                    });
                  }}
                >
                  {translation(this.translationCache, constant.reset)}
                </Button>{' '}
              </>
            ) : null}
          </Col>
        </Row>
        <ListView
          columns={columns}
          dataSource={dataSource}
          userList={userList}
          config={config}
          delete={this._deleteById}
        />
      </StyledComponent>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache()
});
export default connect(mapStateToProps)(WorkOrderList);
