import axios from 'axios';
import localStorage from '../../utils/localStorage';

export function getGraphData(payload) {
  const token = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  let Authorization = 'Bearer ' + token;
  const getProcessArea = {
    method: 'POST',
    url: `/api/trends/list?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    },
    data: payload
  };
  return axios(getProcessArea)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}
