import React from 'react';
import moment from 'moment';
import axios from 'axios';
import { Tooltip, Table, Input, Button } from 'antd';
import { DownloadOutlined, SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import NoRecords from '../Utils/NoRecords';
import localStorage from '../../../utils/localStorage';

export default class TrendTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      option: {},
      data: [],
      language: props.language || 'English',
      reportName: '',
      dataSource: []
    };
  }

  async componentDidMount() {
    this.prepareData();
  }

  componentDidUpdate = (prevprops) => {
    if (prevprops.panelData !== this.props.panelData) {
      this.prepareData(this.props.graphType);
    }
  };

  getReportNametoDownload(excelData, type, SelectedDate, date1) {
    let payload = {};
    if (type === 'timeRange') {
      payload = {
        PortName: 'Rubus',
        Name: type && type ? type : 'Trends',
        Consumption: 'Trend',
        Data: excelData || [],
        ReportType: 'singleHierarchy',
        GeneratedDate: moment().format('YYYY-MM-DD HH:mm:ss'),
        SelectedDate:
          moment.utc(date1.fromDate1).tz(this.props.currentTimeZone).format('YYYY-MM-DD HH:mm') +
          '~' +
          moment.utc(date1.toDate1).tz(this.props.currentTimeZone).format('YYYY-MM-DD HH:mm'),
        SelectionType: 'dateRange'
      };
    } else {
      payload = {
        PortName: 'Rubus',
        Name: type && type ? type : 'Trend',
        Consumption: 'Trend',
        Data: excelData || [],
        ReportType: 'singleHierarchy',
        GeneratedDate: moment().format('YYYY-MM-DD HH:mm:ss'),
        SelectedDate:
          moment
            .utc(SelectedDate.fromDate)
            .tz(this.props.currentTimeZone)
            .format('YYYY-MM-DD HH:mm') +
          '~' +
          moment.utc(SelectedDate.toDate).tz(this.props.currentTimeZone).format('YYYY-MM-DD HH:mm'),
        SelectionType: 'dateRange'
      };
    }

    let siteId = localStorage.get('currentSite');
    let accessToken = localStorage.get('accessToken');
    let options = {};
    options = {
      method: 'POST',
      url: `/api/file/generateExcel?SiteId=${siteId}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      },
      data: JSON.stringify(payload)
    };
    return axios(options)
      .then(({ data }) => {
        return data;
      })
      .catch(() => {
        return '';
      });
  }

  prepareData = async () => {
    const { panelData } = this.props;
    let dataSource = [];
    //mock table data
    // panelData.trendData = [
    //   {
    //     "DateTime": "2023-12-05 10:44:08",
    //     "Name": "Wind speed (QC1)",
    //     "Value": 12.86,
    //     "Description": "Wind speed (QC1)",
    //     "DeviceName": "QC1",
    //     "ParameterName": "Wind speed"
    //   },
    //   {
    //     "DateTime": "2023-12-08 10:44:08",
    //     "Name": "Wind speed (QC1)",
    //     "Value": 12.94,
    //     "Description": "Wind speed (QC1)",
    //     "DeviceName": "QC1",
    //     "ParameterName": "Wind speed"
    //   },
    //   {
    //     "DateTime": "2023-12-09 10:44:08",
    //     "Name": "Wind speed (QC1)",
    //     "Value": 13,
    //     "Description": "Wind speed (QC1)",
    //     "DeviceName": "QC1",
    //     "ParameterName": "Wind speed"
    //   },
    //   {
    //     "DateTime": "2023-12-10 10:44:08",
    //     "Name": "Wind speed (QC1)",
    //     "Value": 13.18,
    //     "Description": "Wind speed (QC1)",
    //     "DeviceName": "QC1",
    //     "ParameterName": "Wind speed"
    //   },
    //   {
    //     "DateTime": "2023-12-11 10:44:08",
    //     "Name": "Wind speed (QC1)",
    //     "Value": 13.07,
    //     "Description": "Wind speed (QC1)",
    //     "DeviceName": "QC1",
    //     "ParameterName": "Wind speed"
    //   },
    //   {
    //     "DateTime": "2023-12-12 10:44:08",
    //     "Name": "Wind speed (QC1)",
    //     "Value": 12.89,
    //     "Description": "Wind speed (QC1)",
    //     "DeviceName": "QC1",
    //     "ParameterName": "Wind speed"
    //   },{
    //     "DateTime": "2023-12-09 10:44:08",
    //     "Name": "Wind speed (QC1)",
    //     "Value": 13,
    //     "Description": "Wind speed (QC1)",
    //     "DeviceName": "QC1",
    //     "ParameterName": "Wind speed"
    //   },
    //   {
    //     "DateTime": "2023-12-10 10:44:08",
    //     "Name": "Wind speed (QC1)",
    //     "Value": 13.18,
    //     "Description": "Wind speed (QC1)",
    //     "DeviceName": "QC1",
    //     "ParameterName": "Wind speed"
    //   },
    //   {
    //     "DateTime": "2023-12-09 10:44:08",
    //     "Name": "Wind speed (QC1)",
    //     "Value": 13,
    //     "Description": "Wind speed (QC1)",
    //     "DeviceName": "QC1",
    //     "ParameterName": "Wind speed"
    //   },
    //   {
    //     "DateTime": "2023-12-10 10:44:08",
    //     "Name": "Wind speed (QC1)",
    //     "Value": 13.18,
    //     "Description": "Wind speed (QC1)",
    //     "DeviceName": "QC1",
    //     "ParameterName": "Wind speed"
    //   },{
    //     "DateTime": "2023-12-09 10:44:08",
    //     "Name": "Wind speed (QC1)",
    //     "Value": 13,
    //     "Description": "Wind speed (QC1)",
    //     "DeviceName": "QC1",
    //     "ParameterName": "Wind speed"
    //   },
    //   {
    //     "DateTime": "2023-12-10 10:44:08",
    //     "Name": "Wind speed (QC1)",
    //     "Value": 13.18,
    //     "Description": "Wind speed (QC1)",
    //     "DeviceName": "QC1",
    //     "ParameterName": "Wind speed"
    //   }

    // ]

    if (
      panelData &&
      panelData.trendData &&
      Array.isArray(panelData.trendData) &&
      panelData.trendData.length > 0
    ) {
      let columnsData = panelData.trendData[0] ? Object.keys(panelData.trendData[0]) : [];

      let columns = columnsData.map((column) => {
        return {
          title: column,
          dataIndex: column,
          key: column,
          width: 120,
          height: 100,
          ...this.getColumnSearchProps(column),
          sorter: (a, b) => a.Value - b.Value
        };
      });
      dataSource = panelData.trendData.map((data) => {
        return {
          ...data,
          DateTime: moment
            .utc(data.DateTime, 'YYYY-MM-DD HH:mm:ss')
            .tz(this.props.currentTimeZone)
            .format('YYYY-MM-DD HH:mm')
        };
      });

      let { fromDate, toDate, fromDate1, toDate1, fromDate2, toDate2 } = {};
      let reportName = await this.getReportNametoDownload(
        dataSource,
        panelData.type,
        { fromDate, toDate },
        { fromDate1, toDate1 },
        { fromDate2, toDate2 }
      );

      this.setState({
        columns,
        dataSource,
        reportName: reportName && reportName.file ? reportName.file : ''
      });
    }
  };

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };
  handleReset = (clearFilters, confirm) => {
    clearFilters();
    this.handleSearch([], confirm);
  };
  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={'Search'}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters, confirm)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined, fontSize: '130%' }} />
    ),
    onFilter: (value, record) =>
      record &&
      record[dataIndex] &&
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text && text.toString()}
      />
    )
  });
  render() {
    let { reportName, dataSource, columns } = this.state;
    return (
      <div>
        <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
          <Tooltip placement="top" title={'Download'}>
            <a href={`${process.env.REACT_APP_Primary_IP}/api/reports/${reportName}`}>
              {' '}
              <DownloadOutlined style={{ fontSize: '25px' }} />
            </a>
          </Tooltip>{' '}
        </div>
        {dataSource && Array.isArray(dataSource) && dataSource.length > 0 ? (
          <Table
            className="trendTable"
            dataSource={dataSource && Array.isArray(dataSource) ? dataSource : []}
            columns={columns}
            pagination={true}
            // pagination={
            //   dataSource && dataSource.length <= tableSize ? false : { pageSize: tableSize }
            // }
            scroll={{ x: 1300, y: 200 }}
            bordered={true}
            // style={{ height: '303px',
            //   width: '498px' }}
          />
        ) : (
          <NoRecords />
        )}
      </div>
    );
  }
}
