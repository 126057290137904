import React, { Component } from 'react';
import _ from 'lodash';
import { Form, message, Select, DatePicker, Input } from 'antd';
import {
  getDeviceList,
  getOrganisationsList,
  getSitesList,
  submitWorkOrder,
  getJobPlansList,
  getJobPlanById,
  getLocationByAsset,
  getWorkTypes,
  getWorkFlows,
  getStatus,
  getWorkOrderById,
  getPMList,
  getUsersList
} from '../../../Utils/FormCalls';
import {
  StyledBox,
  StyledBoxLabel,
  StyledButton,
  StyledComponent,
  StyledForm,
  StyledSubForm
  // StyledSubHeading
} from '../../../CSS/style';
import { decode as base64_decode } from 'base-64';
import WorkOrderJobPlanes from './WorkOrderJobPlanes';
import history from '../../../../../commons/history';
import FileBase64 from '../../../Utils/FileUpload';
import moment from 'moment';
import localStorage from '../../../../../utils/localStorage';
import { constant } from '../../../Utils/constants';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import themeSettings from '../../../Utils/themeSettings.json';
import translation from '../../../Utils/translation';
import { getTranslationCache } from '../../../../../selectors/language';
import InputComponent from '../../../Widgets/InputComponent';
const fileData = new FormData();
const { Option } = Select;
const { TextArea } = Input;
class WorkOrderForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deviceList: [],
      organisationsList: [],
      sitesList: [],
      workTypesList: [],
      workFlowsList: [],
      statusList: [],
      usersList: [],
      formObject:
        props.formObject && Object.keys(props.formObject).length > 0 ? props.formObject : {},
      activeKey: '1',
      selectedJobPlan:
        props.formObject && props.formObject.JobPlanName ? props.formObject.JobPlanName : '',
      jobplanActivekey: '1',
      translationCache: props.translationCache || {},
      fileUploadList: []
    };
  }
  workOrderRef = React.createRef();

  componentDidMount = async () => {
    this._getDataById();
    let sitesList = await getSitesList();
    let formObject = this.workOrderRef.current.getFieldsValue();
    formObject = {
      ...formObject,
      SiteId: localStorage.get('currentSite') ? parseInt(localStorage.get('currentSite')) : ''
    };
    this.workOrderRef.current.setFieldsValue(formObject);
    this.setState({ sitesList, formObject });
    let deviceList = await getDeviceList();
    this.setState({ deviceList });
    let organisationsList = await getOrganisationsList();
    this.setState({ organisationsList });
    let JobPlansList = await getJobPlansList('Active');
    this.setState({ JobPlansList });
    let workTypesList = await getWorkTypes();
    this.setState({ workTypesList });
    let usersList = await getUsersList();
    this.setState({ usersList: usersList });
    let Status = await getStatus();
    this.setState({ statusList: Status });
    let PMList = await getPMList();
    this.setState({ PMList });
  };

  componentDidUpdate = async (prevProps) => {
    if (
      this.props.formObject &&
      Object.keys(this.props.formObject).length === 0 &&
      Object.keys(this.state.formObject).length === 0
    ) {
      this.onReset();
    }
    if (this.props.formObject && Object.keys(this.props.formObject).length === 0) {
      this.workOrderRef.current.setFieldsValue(this.props.formObject);
    }
    if (prevProps.translationCache !== this.props.translationCache) {
      this.setState({
        translationCache: this.props.translationCache
      });
    }
  };
  _getDataById = async (Id) => {
    let formObject = {};
    let path = history.location.pathname.split('/rubus/AMM/FieldDataMeasurementDetails/');
    let formId = base64_decode(path[1]);
    if (formId !== 'new' && !Id) {
      formObject = await getWorkOrderById(base64_decode(path[1]), 'withId');
      _.cloneDeepWith(formObject, (value) => {
        return value !== null
          ? {
              ...value,
              ...(value['RecordedTime']
                ? (value['RecordedTime'] = moment
                    .utc(value.RecordedTime, 'YYYY-MM-DD HH:mm:ss')
                    .local())
                : null),

              ...(value['JobPlan'] &&
                value['JobPlan']['TaskData'] &&
                value['JobPlan']['TaskData'].map((item, i) => {
                  return value['JobPlan']['TaskData'][i]
                    ? (value['JobPlan']['TaskData'][i]['StartTime']
                        ? (value['JobPlan']['TaskData'][i]['StartTime'] = moment
                            .utc(
                              value['JobPlan']['TaskData'][i]['StartTime'],
                              'YYYY-MM-DD HH:mm:ss'
                            )
                            .local())
                        : null,
                      value['JobPlan']['TaskData'][i]['EndTime']
                        ? (value['JobPlan']['TaskData'][i]['EndTime'] = moment
                            .utc(value['JobPlan']['TaskData'][i]['EndTime'], 'YYYY-MM-DD HH:mm:ss')
                            .local())
                        : null,
                      value['JobPlan']['TaskData'][i]['MeasurementDate']
                        ? (value['JobPlan']['TaskData'][i]['MeasurementDate'] = moment
                            .utc(
                              value['JobPlan']['TaskData'][i]['MeasurementDate'],
                              'YYYY-MM-DD HH:mm:ss'
                            )
                            .local())
                        : null,
                      value['JobPlan']['TaskData'][i]['EstimatedDuration']
                        ? (value['JobPlan']['TaskData'][i]['EstimatedDuration'] = moment
                            .utc(
                              value['JobPlan']['TaskData'][i]['EstimatedDuration'],
                              'YYYY-MM-DD HH:mm:ss'
                            )
                            .local())
                        : null)
                    : null;
                }))
            }
          : _.noop();
      });
    } else if (Id) {
      formObject = await getWorkOrderById(Id, 'withId');
      _.cloneDeepWith(formObject, (value) => {
        return value !== null
          ? {
              ...value,
              ...(value['RecordedTime']
                ? (value['RecordedTime'] = moment
                    .utc(value.RecordedTime, 'YYYY-MM-DD HH:mm:ss')
                    .local())
                : null),

              ...(value['JobPlan'] &&
                value['JobPlan']['TaskData'] &&
                value['JobPlan']['TaskData'].map((item, i) => {
                  return value['JobPlan']['TaskData'][i]
                    ? (value['JobPlan']['TaskData'][i]['StartTime']
                        ? (value['JobPlan']['TaskData'][i]['StartTime'] = moment
                            .utc(
                              value['JobPlan']['TaskData'][i]['StartTime'],
                              'YYYY-MM-DD HH:mm:ss'
                            )
                            .local())
                        : null,
                      value['JobPlan']['TaskData'][i]['EndTime']
                        ? (value['JobPlan']['TaskData'][i]['EndTime'] = moment
                            .utc(value['JobPlan']['TaskData'][i]['EndTime'], 'YYYY-MM-DD HH:mm:ss')
                            .local())
                        : null,
                      value['JobPlan']['TaskData'][i]['MeasurementDate']
                        ? (value['JobPlan']['TaskData'][i]['MeasurementDate'] = moment
                            .utc(
                              value['JobPlan']['TaskData'][i]['MeasurementDate'],
                              'YYYY-MM-DD HH:mm:ss'
                            )
                            .local())
                        : null,
                      value['JobPlan']['TaskData'][i]['EstimatedDuration']
                        ? (value['JobPlan']['TaskData'][i]['EstimatedDuration'] = moment
                            .utc(
                              value['JobPlan']['TaskData'][i]['EstimatedDuration'],
                              'YYYY-MM-DD HH:mm:ss'
                            )
                            .local())
                        : null)
                    : null;
                }))
            }
          : _.noop();
      });
    }
    this.handleChange(
      formObject && formObject.JobPlan && formObject.JobPlan.JobPlanName,
      'JobPlanName'
    );
    this.handleChange(
      formObject && formObject.JobPlan && formObject.JobPlan.JobPlanName,
      'JobPlanDescription'
    );

    this.setState({
      formObject
    });
    if (formObject && this.workOrderRef.current) {
      this.workOrderRef.current.setFieldsValue(formObject);
    }
  };

  handleSelect = (value, mainIndex, subindex, keyName) => {
    let list = {};
    let TaskData = list;
    TaskData.map((item, index) => {
      if (index === mainIndex) {
        item[keyName][subindex]['TotalQuantity'] = value;
      }
      return {};
    });
  };
  handleChange = async (value, key) => {
    let { formObject, deviceList, PMList, JobPlansList } = this.state;

    formObject = {
      ...formObject,
      [key]: value
    };
    if (key === 'DeviceId') {
      let selectedDevice = deviceList.filter((item) => item.Id === value);
      let location = await getLocationByAsset(selectedDevice[0]);
      formObject = {
        ...formObject,
        [key]: value,
        EquipmentDescription: selectedDevice[0].Name,
        LocationId: location.Name
      };
    } else if (key === 'PM') {
      let selectedDevice = PMList.filter((item) => item.Id === value);
      formObject = {
        ...formObject,
        [key]: value,
        PMDescription: selectedDevice[0].Name
      };
    } else if (key === 'JobPlanId') {
      let selectedDevice = JobPlansList.filter((item) => item.Id === value);
      formObject = {
        ...formObject,
        [key]: value,
        JobPlanDescription: selectedDevice[0].JobPlanDescription
      };
    } else if (key === 'WorkTypeId') {
      var workFlowsList = await getWorkFlows(value);
    }

    this.workOrderRef.current !== null && this.workOrderRef.current.setFieldsValue(formObject);

    this.setState({ [key]: value, formObject, workFlowsList });
  };

  // fileupload = async (values, key, fileDataObject) => {
  //   let formObject = this.workOrderRef.current.getFieldsValue();
  //   let { fileUploadList } = this.state;
  //   if (formObject && formObject[key]) {
  //     formObject[key].push(...values);
  //   } else if (formObject[key] !== undefined) {
  //     formObject[key] = values;
  //   } else {
  //     formObject[key].push({ [key]: values });
  //   }
  //   let WO = [...(fileUploadList && fileUploadList.WO ? fileUploadList.WO : [])];
  //   WO.push({ fileDataObject });
  //   fileUploadList = {
  //     ...fileUploadList,
  //     WO
  //   };
  //   this.workOrderRef.current.setFieldsValue(formObject);
  //   this.setState({ formObject, fileUploadList });
  // };
  fileupload = async (values, key, fileDataObject) => {
    let formObject = this.workOrderRef.current.getFieldsValue();
    let { fileUploadList } = this.state;
    if (formObject && formObject[key]) {
      formObject[key].push(...values);
    } else if (formObject[key] !== undefined) {
      formObject[key] = values;
    } else {
      formObject[key].push({ [key]: values });
    }
    formObject[key] =
      formObject[key] &&
      formObject[key].reduce((acc, current) => {
        const x = acc.find((item) => item.Name === current.Name);
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, []);

    formObject = {
      ...formObject,
      [key]: formObject[key]
    };
    fileData.append('files', fileDataObject);
    let WO = [...(fileUploadList && fileUploadList.WO ? fileUploadList.WO : [])];
    WO.push({ fileDataObject });
    fileUploadList = {
      ...fileUploadList,
      WO
    };
    if (this.workOrderRef.current) this.workOrderRef.current.setFieldsValue(formObject);
    this.setState({ formObject, fileData, fileUploadList });
  };
  onFinish = async (values, key, fileData) => {
    let validateTaskData = true;
    // let validateActualTask = true;
    // let t1 =
    values &&
      values['TaskData'] &&
      values['TaskData'].map((val, index) => {
        if (
          values['TaskData'][index] &&
          Object.keys(values['TaskData'][index]).includes('TaskName') &&
          Object.keys(values['TaskData'][index]).includes('TaskDescription')
        ) {
          let formObject = this.workOrderRef.current.getFieldsValue();
          formObject = {
            ...formObject,
            [key]: {
              ...formObject[key],
              ...values
            }
          };
          this.setState({ formObject, fileData });
          this.workOrderRef.current.setFieldsValue(formObject);
        } else {
          validateTaskData = false;
          message.error('Please enter Mandatory fileds in Planned Task');
        }
        return {};
      });
    this.setState({
      noJobplan: validateTaskData
    });
    values &&
      values['ActualTaskData'] &&
      values['ActualTaskData'].map((val, index) => {
        if (
          values['ActualTaskData'][index] &&
          Object.keys(values['ActualTaskData'][index]).includes('TaskName') &&
          Object.keys(values['ActualTaskData'][index]).includes('TaskDescription')
        ) {
          let formObject = this.workOrderRef.current.getFieldsValue();
          formObject = {
            ...formObject,
            [key]: {
              ...formObject[key],
              ...values
            }
          };
          this.setState({ formObject, fileData });
          this.workOrderRef.current.setFieldsValue(formObject);
        } else {
          validateTaskData = false;
          message.error('Please enter Mandatory fileds in Actual Task');
        }
        return {};
      });
    this.setState({
      noJobplan: validateTaskData
    });
  };
  nojobpaln = () => {
    this.setState({
      noJobplan: false
    });
  };
  onFormsubmit = async () => {
    let { formObject, noJobplan, fileUploadList } = this.state;
    let formdata = this.workOrderRef.current.getFieldsValue();
    this.setState({
      noJobplan: false
    });
    formObject = {
      ...this.state.formObject,
      Summary: formdata.Summary
    };
    if (
      noJobplan === true &&
      formObject.JobPlan &&
      formObject.JobPlan.TaskData &&
      Array.isArray(formObject.JobPlan.TaskData) &&
      formObject.JobPlan.TaskData.length === 0
    ) {
      message.info('Please Provide Atleast One Task');
    } else if (noJobplan === true) {
      let statusResponse = await submitWorkOrder(formObject, fileUploadList);
      if (statusResponse && statusResponse.message) {
        this._getDataById(statusResponse.Id);
        message.success('WorkOrder Submitted Successfully');
      } else if (statusResponse && statusResponse.Error) {
        message.error(statusResponse.Error);
      }
    } else {
      if (noJobplan === false) message.error('Please save before you submit');
    }
  };
  onReset = () => {
    // this.formRef.current.resetFields();
    this.workOrderRef.current.resetFields();
  };
  // onFinishFailed = () => {
  //   message.error('Please enter mandatory fields');
  // };
  jobPlanOnChange = async (value) => {
    let jobplanData = await getJobPlanById(value, 'withoutId');
    let formObject =
      this.workOrderRef.current !== null && this.workOrderRef.current.getFieldsValue()
        ? this.workOrderRef.current.getFieldsValue()
        : {};
    _.cloneDeepWith(jobplanData['TaskData'], (value) => {
      return value !== null && value.StartTime
        ? {
            ...value,
            ...(value['StartTime'] = moment.utc(value.StartTime, 'YYYY-MM-DD HH:mm:ss')),
            ...(value['EndTime'] = moment.utc(value.EndTime, 'YYYY-MM-DD HH:mm:ss'))
          }
        : _.noop();
    });
    formObject = { ...formObject, JobPlan: jobplanData };
    this.workOrderRef.current.setFieldsValue(formObject);
    this.setState({ selectedJobPlan: value, formObject });
  };
  datepickerchange = (date, dateString, index, key) => {
    // this.workOrderJobPlanRef.current.setFieldsValue(key, moment(dateString).format("YYYY-MM-DD HH:mm:ss"))
    let formObject = this.workOrderJobPlanRef.current.getFieldsValue();
    let EstimatedDuration = null;
    if (
      key &&
      key === 'EndTime' &&
      this.workOrderJobPlanRef.current.getFieldValue() &&
      this.workOrderJobPlanRef.current.getFieldValue().TaskData &&
      this.workOrderJobPlanRef.current.getFieldValue().TaskData[index] &&
      this.workOrderJobPlanRef.current.getFieldValue().TaskData[index]['StartTime']
    ) {
      let startTime =
        this.workOrderJobPlanRef.current.getFieldValue() &&
        this.workOrderJobPlanRef.current.getFieldValue().TaskData &&
        this.workOrderJobPlanRef.current.getFieldValue().TaskData[index] &&
        this.workOrderJobPlanRef.current.getFieldValue().TaskData[index]['StartTime'];
      let endTime = date;
      // EstimatedDuration = moment.utc(moment(endTime, "HH:mm:ss").diff(moment(startTime, "HH:mm:ss"))).format("HH:mm:ss")
      let ms = moment(endTime, 'YYYY-MM-DD HH:mm').diff(moment(startTime, 'YYYY-MM-DD HH:mm'));
      let d = moment.duration(ms);
      EstimatedDuration = Math.floor(d.asHours()) + moment.utc(ms).format(':mm:ss');
    } else if (
      key &&
      key === 'StartTime' &&
      this.workOrderJobPlanRef.current.getFieldValue() &&
      this.workOrderJobPlanRef.current.getFieldValue().TaskData &&
      this.workOrderJobPlanRef.current.getFieldValue().TaskData[index] &&
      this.workOrderJobPlanRef.current.getFieldValue().TaskData[index]['EndTime']
    ) {
      let endTime =
        this.workOrderJobPlanRef.current.getFieldValue() &&
        this.workOrderJobPlanRef.current.getFieldValue().TaskData &&
        this.workOrderJobPlanRef.current.getFieldValue().TaskData[index] &&
        this.workOrderJobPlanRef.current.getFieldValue().TaskData[index]['EndTime'];
      let startTime = date;
      // EstimatedDuration = moment.utc(moment(endTime, "HH:mm:ss").diff(moment(startTime, "HH:mm:ss"))).format("HH:mm:ss")
      let ms = moment(endTime, 'YYYY-MM-DD HH:mm').diff(moment(startTime, 'YYYY-MM-DD HH:mm'));
      let d = moment.duration(ms);
      EstimatedDuration = Math.floor(d.asHours()) + moment.utc(ms).format(':mm:ss');
    }
    formObject['TaskData'][index]['EstimatedDuration'] = EstimatedDuration;
    this.workOrderJobPlanRef.current.setFieldsValue(formObject);
  };
  render() {
    let {
      deviceList,
      sitesList,
      formObject,
      selectedJobPlan,
      workTypesList,
      statusList,
      usersList,
      JobPlansList,
      translationCache
    } = this.state;
    const newLocal = true;
    /* eslint-disable no-template-curly-in-string */
    const validateMessages = {
      required: '${label} is required!',
      types: {
        email: '${label} is not a valid email!',
        number: '${label} is not a valid number!'
      },
      number: {
        range: '${label} must be between ${min} and ${max}'
      },
      pattern: {
        mismatch: '${label} does not match pattern'
      }
    };
    return (
      <>
        <StyledComponent theme={themeSettings} style={{ minHeight: window.innerHeight - 143 }}>
          <StyledForm
            name="FieldData"
            theme={themeSettings}
            ref={this.workOrderRef}
            onFinish={this.onFormsubmit}
            onKeyDown={(e) => {
              e.key === 'Enter' && e.preventDefault();
            }}
            // onFinishFailed={this.onFinishFailed}
            initialValues={formObject}
            layout="horizontal"
            labelCol={{ flex: '130px' }}
            labelAlign="right"
            labelWrap
            //wrapperCol={{ flex: 1 }}
            validateMessages={validateMessages}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'right',
                marginRight: '20px'
                // marginTop: '20px'
              }}
            >
              <Form.Item>
                <StyledButton theme={themeSettings} htmlType="submit">
                  {translation(translationCache, constant.submit)}
                </StyledButton>
              </Form.Item>
            </div>
            <StyledBox>
              <div
                style={{
                  display: 'flex',
                  flexWrap: 'wrap'
                }}
              >
                <Form.Item name="Id"></Form.Item>
                <Form.Item
                  label={translation(translationCache, constant.workOrder)}
                  name="WorkOrder"
                  style={{
                    width: '100%',
                    flex: '50%',
                    marginTop: '20px',
                    opacity: newLocal ? 0.75 : 1,
                    pointerEvents: newLocal ? 'none' : 'initial'
                  }}
                  rules={[{ required: true }]}
                >
                  <InputComponent
                    disabled={true}
                    placeholder={translation(translationCache, 'Enter WorkOrderName')}
                    value={'WO-11'}
                    style={{ width: '70%' }}
                    onChange={(e) => this.handleChange(e.target.value, 'WorkOrder')}
                  />
                </Form.Item>
                <Form.Item
                  label={translation(translationCache, constant.workOrderDescription)}
                  name="Description"
                  style={{
                    width: '100%',
                    flex: '50%',
                    marginTop: '20px',
                    opacity: newLocal ? 0.75 : 1,
                    pointerEvents: newLocal ? 'none' : 'initial'
                  }}
                  rules={[{ required: false }]}
                >
                  <InputComponent
                    style={{ width: '70%' }}
                    placeholder={translation(translationCache, 'Enter WorkOrderDescription')}
                    onChange={(e) => this.handleChange(e.target.value, 'Description')}
                  />
                </Form.Item>
                <Form.Item name="Device"></Form.Item>
                <Form.Item
                  style={{
                    width: '100%',
                    flex: '50%',
                    opacity: newLocal ? 0.75 : 1,
                    pointerEvents: newLocal ? 'none' : 'initial'
                  }}
                  label={translation(translationCache, constant.equipment)}
                  name="DisplayCode"
                  rules={[{ required: true }]}
                >
                  <Select
                    style={{ width: '70%' }}
                    placeholder={translation(translationCache, 'Select Equipment')}
                    onChange={(value) => this.handleChange(value, 'DeviceId')}
                  >
                    {deviceList &&
                      Array.isArray(deviceList) &&
                      deviceList.map((device, index) => {
                        return (
                          <Option key={index} value={device.Id}>
                            {' '}
                            {device.Name}{' '}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
                <Form.Item
                  style={{
                    width: '100%',
                    flex: '50%',
                    opacity: newLocal ? 0.75 : 1,
                    pointerEvents: newLocal ? 'none' : 'initial'
                  }}
                  label={translation(translationCache, constant.equipmentDescription)}
                  name="EquipmentDescription"
                  rules={[{ required: true }]}
                >
                  <InputComponent
                    style={{ width: '70%' }}
                    placeholder={translation(translationCache, 'Enter Equipment Description')}
                    disabled={true}
                  />
                </Form.Item>
                <Form.Item
                  style={{
                    width: '100%',
                    flex: '50%',
                    opacity: newLocal ? 0.75 : 1,
                    pointerEvents: newLocal ? 'none' : 'initial'
                  }}
                  label={translation(translationCache, constant.location)}
                  name="LocationId"
                  rules={[{ required: true }]}
                >
                  <InputComponent
                    style={{ width: '70%' }}
                    placeholder={translation(translationCache, 'Enter Location')}
                    disabled={true}
                  />
                </Form.Item>
                <Form.Item
                  style={{
                    width: '100%',
                    flex: '50%',
                    opacity: newLocal ? 0.75 : 1,
                    pointerEvents: newLocal ? 'none' : 'initial'
                  }}
                  label={translation(translationCache, constant.locationDescription)}
                  name="LocationDescription"
                  rules={[{ required: true }]}
                >
                  <InputComponent
                    style={{ width: '70%' }}
                    placeholder={translation(translationCache, 'Enter Location Description')}
                    disabled={true}
                  />
                </Form.Item>
                <Form.Item
                  style={{
                    width: '100%',
                    flex: '50%',
                    opacity: newLocal ? 0.75 : 1,
                    pointerEvents: newLocal ? 'none' : 'initial'
                  }}
                  label={translation(translationCache, constant.createdBy)}
                  name="CreatedBy"
                >
                  <Select
                    placeholder={translation(translationCache, 'Select CreatedBy')}
                    style={{ width: '70%' }}
                  >
                    {usersList &&
                      Array.isArray(usersList) &&
                      usersList.map((item, index) => {
                        return (
                          <Option key={index} value={item.Id}>
                            {' '}
                            {item.FirstName}{' '}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>

                <Form.Item
                  label={translation(translationCache, constant.createdDate)}
                  style={{
                    width: '100%',
                    flex: '50%',
                    opacity: newLocal ? 0.75 : 1,
                    pointerEvents: newLocal ? 'none' : 'initial'
                  }}
                  name={'RecordedTime'}
                >
                  <DatePicker
                    style={{ width: '70%' }}
                    format="YYYY-MM-DD HH:mm:ss"
                    // inputReadOnly={false}
                    // onChange={(date, dateString) => {
                    //   this.datepickerchange(date, dateString, key, 'StartTime');
                    // }}
                    showTime={{
                      defaultValue: moment('00:00:00', 'HH:mm:ss')
                    }}
                    disabledDate={(d) =>
                      !d || d.isAfter(moment().add(1, 'day').format('YYYY-MM-DD'))
                    }
                  />
                </Form.Item>
                <Form.Item
                  style={{
                    width: '100%',
                    flex: '50%',
                    opacity: newLocal ? 0.75 : 1,
                    pointerEvents: newLocal ? 'none' : 'initial'
                  }}
                  label={translation(translationCache, 'Status')}
                  name="StatusId"
                >
                  <Select
                    style={{ width: '70%' }}
                    placeholder={translation(translationCache, 'Select Status')}
                    onChange={(value) => this.handleChange(value, 'StatusId')}
                  >
                    {statusList.map((status, index) => {
                      return (
                        <Option key={index} value={status.Id}>
                          {' '}
                          {status.Status}{' '}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>

                <Form.Item
                  style={{
                    width: '100%',
                    flex: '50%',
                    opacity: newLocal ? 0.75 : 1,
                    pointerEvents: newLocal ? 'none' : 'initial'
                  }}
                  label={translation(translationCache, constant.site)}
                  name="SiteId"
                  disabled={formObject && formObject.Id ? true : false}
                  rules={[{ required: true }]}
                >
                  <Select
                    style={{ width: '70%' }}
                    placeholder={translation(translationCache, 'Select Site')}
                    onChange={(value) => this.handleChange(value, 'SiteId')}
                  >
                    {sitesList &&
                      Array.isArray(sitesList) &&
                      sitesList.map((site, index) => {
                        return (
                          <Option key={index} value={site.Id}>
                            {' '}
                            {site.Name}{' '}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
                <Form.Item
                  style={{
                    width: '100%',
                    flex: '50%',
                    opacity: newLocal ? 0.75 : 1,
                    pointerEvents: newLocal ? 'none' : 'initial'
                  }}
                  label={translation(translationCache, constant.workType)}
                  name="WorkTypeId"
                  rules={[{ required: true }]}
                >
                  <Select
                    style={{ width: '70%' }}
                    placeholder={translation(translationCache, 'Select Work Type')}
                    onChange={(value) => this.handleChange(value, 'WorkTypeId')}
                  >
                    {workTypesList.map((workType, index) => {
                      return (
                        <Option key={index} value={workType.Id}>
                          {' '}
                          {workType.WorkType}{' '}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
                <Form.Item
                  style={{ width: '100%', flex: '50%' }}
                  name="Attachments"
                  label={translation(translationCache, 'Attachments')}
                >
                  <FileBase64
                    className={'formuploadInput'}
                    downloadClassName={'formdownloadInput'}
                    Attachments={formObject.Attachments}
                    fileUpload={(values, key, fileDataObject) => {
                      this.fileupload(values, key, fileDataObject);
                    }}
                  ></FileBase64>
                </Form.Item>
                {/* <Form.Item
                    style={{ width: '100%', flex: '50%' }}
                    label="PM"
                    name="PM"
                    rules={[{ required: true}]}
                  >
                    <Select
                      style={{ width: '70%' }}
                      placeholder={translation(translationCache,"Select PM")}
                      onChange={(value) => this.handleChange(value, 'PM')}
                    >
                      {PMList && PMList.map((item, index) => {
                        return (
                          <Option key={index} value={item.Id}>
                            {' '}
                            {item.Name}{' '}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  <Form.Item
                  style={{ width: '100%', flex: '50%' }}
                  label={translation(translationCache, constant.pmDescription)}
                  name="PMDescription"
                >
                  <InputComponent
                    style={{ width: '70%' }}
                    placeholder={translation(translationCache,"Enter PM Description")}
                    disabled={true}
                  />
                </Form.Item> */}

                {/* <Form.Item
                  style={{
                    width: '100%',
                    flex: '50%',
                    opacity: newLocal ? 0.75 : 1,
                    pointerEvents: newLocal ? 'none' : 'initial'
                  }}
                  label={translation(translationCache, constant.pmName)}
                  name="PMName"
                >
                  <InputComponent
                    style={{ width: '70%' }}
                    placeholder={translation(translationCache, 'Enter Description')}
                    onChange={(e) => this.handleChange(e.target.value, 'Description')}
                  />
                </Form.Item>
                <Form.Item
                  style={{
                    width: '100%',
                    flex: '50%',
                    opacity: newLocal ? 0.75 : 1,
                    pointerEvents: newLocal ? 'none' : 'initial'
                  }}
                  label={translation(translationCache, constant.pmDescription)}
                  name="PMDescription"
                >
                  <InputComponent
                    style={{ width: '70%' }}
                    placeholder={translation(translationCache, 'Enter Description')}
                    onChange={(e) => this.handleChange(e.target.value, 'Description')}
                  />
                </Form.Item> */}
                <Form.Item
                  label={translation(translationCache, constant.summary)}
                  name={'Summary'}
                  style={{ width: '100%', flex: '50%' }}
                >
                  <TextArea
                    rows={3}
                    style={{ width: '70%' }}
                    placeholder={translation(translationCache, 'Enter Summary')}
                  />
                </Form.Item>
                <Form.Item style={{ width: '100%', flex: '50%' }}>
                  <InputComponent style={{ display: 'none' }} />
                </Form.Item>
              </div>
            </StyledBox>
            <StyledBox>
              <StyledBoxLabel>{translation(translationCache, 'Jobplan Details')}</StyledBoxLabel>
              <StyledSubForm theme={themeSettings}>
                {/* <StyledSubHeading theme={themeSettings}>Task Details</StyledSubHeading> */}

                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'right',
                    marginRight: '20px'
                    // marginTop: '20px'
                  }}
                >
                  <Form.Item
                    style={{
                      width: '100%',
                      flex: '50%',
                      opacity: newLocal ? 0.75 : 1,
                      pointerEvents: newLocal ? 'none' : 'initial'
                    }}
                    label={translation(translationCache, constant.jobPlan)}
                    name="JobPlanName"
                  >
                    <Select
                      style={{ width: '70%' }}
                      placeholder={translation(translationCache, 'Select JobPlan')}
                      onChange={(value) => this.handleChange(value, 'JobPlanName')}
                    >
                      {JobPlansList &&
                        Array.isArray(JobPlansList) &&
                        JobPlansList.map((jobplan, index) => {
                          return (
                            <Option key={index} value={jobplan.Id}>
                              {jobplan.JobPlanName}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    style={{
                      width: '100%',
                      flex: '50%',
                      opacity: newLocal ? 0.75 : 1,
                      pointerEvents: newLocal ? 'none' : 'initial'
                    }}
                    label={translation(translationCache, constant.jobplanDescription)}
                    name="JobPlanDescription"
                  >
                    <InputComponent
                      style={{ width: '70%' }}
                      placeholder={translation(translationCache, 'Enter Description')}
                      onChange={(e) => this.handleChange(e.target.value, 'Description')}
                    />
                  </Form.Item>
                </div>

                <Form.Item name="JobPlan">
                  {selectedJobPlan === '' ? (
                    <WorkOrderJobPlanes
                      noJobplan={this.nojobpaln}
                      fileData={this.state.fileData}
                      onFinish={this.onFinish}
                      // onFinishFailed={this.onFinishFailed}
                      formObject={formObject['JobPlan']}
                      fileUploadList={this.state.fileUploadList}
                      fileUploadListBack={(fileUploadList) => this.setState({ fileUploadList })}
                    ></WorkOrderJobPlanes>
                  ) : null}
                </Form.Item>
                {/* </TabPane> */}
                {/* </StyledTabs> */}
              </StyledSubForm>
            </StyledBox>
          </StyledForm>
        </StyledComponent>
      </>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache()
});
export default connect(mapStateToProps)(WorkOrderForm);
