import localStorage from '../../utils/localStorage';

export default class translation {
  static getTranslationCache(language) {
    try {
      let SiteId = localStorage.get('currentSite');
      const accessToken = localStorage.get('accessToken');
      // if (!SiteId) {
      //   SiteId = '1';
      // }
      return fetch(`/api/language/LanguageFilter?SiteId=${SiteId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`
        },
        body: JSON.stringify({
          Language: language || 'Japanese'
        })
      }).then(responseToken);
    } catch (err) {
      throw err;
    }
    function responseToken(response) {
      return response.json();
    }
  }
}
