import React from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import GraphView from './GraphView';
import { GraphTitle } from '../Utils/CSS/styles';
import { constant } from '../Utils/constants';

class GraphComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      FilterData: [],
      operatorList: constant && constant.operatorList ? constant.operatorList : []
      // selectedOperator: ''
    };
  }

  componentDidUpdate(prevprops) {
    if (prevprops.FilterData !== this.props.FilterData) {
      this.setState({ FilterData: this.props.FilterData });
    }
  }
  render() {
    let { FilterData } = this.state;
    return (
      <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
        {FilterData &&
          Array.isArray(FilterData) &&
          FilterData.map((filter, index) => {
            return (
              <div style={{ margin: '5px', width: '500px', height: '400px' }} key={index}>
                <GraphTitle>
                  {filter.DeviceName}-{filter.ParameterName}
                </GraphTitle>
                <div
                  style={{ margin: '5px 0px', display: 'flex', justifyContent: 'flex-end' }}
                ></div>
                <GraphView payload={filter} />
              </div>
            );
          })}
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  // userProfile: getUserProfile(),
  // translationCache: getTranslationCache(),
  // currentTimeZone: getCurrentTimezone()
});
export default connect(mapStateToProps)(GraphComponent);
