import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import goback from '../Utils/images/goback_1.png';
import { getTranslationCache } from '../../../selectors/language';
import themeSettings from '../../AMM/Utils/themeSettings.json';
import history from '../../../commons/history';
import AMMRouter from '../../../routes/ammRoute';
import { StyledFormType } from '../CSS/style';
import { constant } from '../Utils/constants';

class AMM extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  getFormNames = () => {
    return constant.ITEM_MASTER;
  };
  renderFormName(param) {
    if (JSON.stringify(param).match(`/rubus/AMM/ItemDetails/`) !== null) {
      return constant.ITEM_MASTER;
    } else if (JSON.stringify(param).match(`/rubus/AMM/MaterialDetails/`) !== null) {
      return constant.MATERIAL_MASTER;
    } else if (JSON.stringify(param).match(`/rubus/AMM/ToolsDetails/`) !== null) {
      return constant.TOOL_MASTER;
    } else if (JSON.stringify(param).match(`/rubus/AMM/TasksDetails/`) !== null) {
      return constant.TASK_MASTER;
    } else if (JSON.stringify(param).match(`/rubus/AMM/JobPlanDetails/`) !== null) {
      return constant.JOBPLAN;
    } else if (JSON.stringify(param).match(`/rubus/AMM/WorkOrderDetails/`) !== null) {
      return constant.WORKORDERS;
    } else if (JSON.stringify(param).match(`/rubus/AMM/PreventiveMaintenanceDetails/`) !== null) {
      return constant.PREVENTIVEMAINTENANCE;
    } else if (JSON.stringify(param).match(`/rubus/AMM/CMDetails/`) !== null) {
      return constant.CONDITIONAL_MONITORING;
    } else if (JSON.stringify(param).match(`/rubus/AMM/EWODetails/`) !== null) {
      return constant.TARGET_REPORTING;
    } else if (JSON.stringify(param).match(`/rubus/AMM/FieldDataMeasurementDetails/`) !== null) {
      return constant.FIELD_DATA_MEASUREMENT;
    } else if (JSON.stringify(param).match(`/rubus/AMM/ManPowerDetails/`) !== null) {
      return constant.MANPOWER_MASTER;
    } else if (JSON.stringify(param).match(`/rubus/AMM/InspectionDetails/`) !== null) {
      return constant.INSPECTION;
    } else if (JSON.stringify(param).match(`/rubus/AMM/FailureCodeDetails/`) !== null) {
      return constant.FAILURE_MASTER;
    } else if (JSON.stringify(param).match(`/rubus/AMM/EquipmentDetails/`) !== null) {
      return constant.EQUIPMENT_MASTER;
    } else if (JSON.stringify(param).match(`/rubus/AMM/WorkFlowDetails/`) !== null) {
      return constant.WORK_FLOW;
    } else if (JSON.stringify(param).match(`/rubus/AMM/SRDetails/`) !== null) {
      return constant.SERVICE_REQUEST;
    } else if (JSON.stringify(param).match(`/rubus/AMM/InspectionMasterDetails/`) !== null) {
      return constant.INSPECTION;
      // } else if (JSON.stringify(param).match(`/rubus/AMM/EquipmentTagDetails/`) !== null) {
      //   return constant.EQUIPMENT_TAGS;
    } else if (JSON.stringify(param).match(`/rubus/AMM/InventoryDetails/`) !== null) {
      return constant.INVENTORY;
    } else if (JSON.stringify(param).match(`/rubus/AMM/EquipmentOverviewDetails/`) !== null) {
      return constant.EQUIPMENT_OVERVIEW;
    } else if (JSON.stringify(param).match(`/rubus/AMM/ELogScheduleDetails/`) !== null) {
      return constant.ELOG_SCHEDULE;
    } else if (JSON.stringify(param).match(`/rubus/AMM/Location/`) !== null) {
      return constant.LOCATION;
    }
    return '-';
  }
  render() {
    let pathname = history.location.pathname;

    return (
      <div>
        {pathname.includes('Details') ? (
          <div>
            <div
              style={{
                background: '#1a3652',
                padding: '10px 19px',
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              <div style={{ color: '#ffb327', fontSize: '16px' }}>
                <span style={{ cursor: 'pointer' }} onClick={() => history.goBack()}>
                  <img src={goback} alt="logo" style={{ width: '25px' }} />
                </span>
              </div>
              <StyledFormType theme={themeSettings}>
                {this.renderFormName(history.location.pathname)}
              </StyledFormType>
              <div></div>
            </div>
            <AMMRouter />
          </div>
        ) : (
          <AMMRouter />
        )}
      </div>
    );
  }
}
const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache()
});
export default connect(mapStateToProps)(AMM);
