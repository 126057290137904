import React from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';

import { getTranslationCache } from '../../selectors/language';
import { getUserProfile } from '../../selectors/layout';
import { MessageList } from './CSS/styles';
import ChatMessage from './ChatMessage';
import TypingIndicator from './TypingIndicator';

class ChatBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      messages: props.messages,
      isLoading: props.isLoading
    };
  }
  componentDidMount() {
    this.setState({
      messages: this.props.messages,
      isLoading: this.props.isLoading
    });
  }

  componentDidUpdate(prevprops) {
    if (
      this.props.messages !== prevprops.messages ||
      this.props.isLoading !== prevprops.isLoading
    ) {
      this.setState({
        messages: this.props.messages,
        isLoading: this.props.isLoading
      });
    }
  }

  render() {
    let { messages, isLoading } = this.state;
    return (
      <>
        <MessageList>
          {messages.map((message, index) => (
            <ChatMessage
              key={index}
              sender={message.sender}
              content={message.content}
              isUser={message.sender.toLowerCase() === 'user'}
            />
          ))}
          <TypingIndicator isTyping={isLoading} />
        </MessageList>
      </>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  userProfile: getUserProfile(),
  translationCache: getTranslationCache()
});
export default connect(mapStateToProps)(ChatBox);
