import React from 'react';
import { Form, Select, Button, TimePicker, message } from 'antd';
import moment from 'moment';
import _ from 'lodash';
import { UpSquareOutlined } from '@ant-design/icons';
import themeSettings from './Utils/themeSettings.json';
import {
  getList,
  getListById,
  getListByType,
  submit,
  getHierarchyFromAPI,
  getDeviceList
} from './Utils/FormCalls';
import history from '../../../commons/history';

import { StyledComponent, StyledForm, StyledModal } from './style';
import HierarchyModal from './Utils/HierarchyModal';
const { Option } = Select;
const ApiList = {
  getAssetList: `/api/asset/getAssetByModule`,
  getDeviceTypeListByAsset: `/api/devicetype/getDeviceTypeByAsset`,
  getDeviceListByDeviceType: `/api/maximoasset/deviceList`,
  getFaultCodesList: `/api/maximoasset/faultCodeList`,
  Submit: `/api/maximoasset/updateAFCDetails`
};

class SRHaltTime extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formObject: {},
      stationList: [],
      deviceTypeList: [],
      deviceList: [],
      treeVisible: false,
      selectedDevices: [],
      record:
        this.props.location && this.props.location.state ? this.props.location.state.record : {}
    };
  }
  hRef = React.createRef();

  componentDidMount = async () => {
    this.getDataById();
    let formObject = this.state.record;
    let data = this.hRef.current ? this.hRef.current.getFieldsValue() : null;
    this.fieldOnChange(data.Station, 'Station');
    this.fieldOnChange(data.deviceType, 'deviceType');
    this.fieldOnChange(data.DeviceCode, 'DeviceCode');
    this.getHierarchyData();

    let path = history.location.pathname.split('/rubus/Maximo/');
    let Module = path[1].split('/') && path[1].split('/')[0];
    let deviceList = await getDeviceList();
    let stationList1 = await getList(ApiList.getAssetList, Module);
    let stationList = [
      ...(stationList1 && Array.isArray(stationList1) && stationList1.length > 0
        ? [{ AssetCode: 'All', Name: 'All' }]
        : []),
      ...stationList1
    ];
    this.setState({ stationList, formObject, deviceList });
  };

  getHierarchyData = async () => {
    let path = history.location.pathname.split('/rubus/Maximo/');
    let Module = path[1].split('/') && path[1].split('/')[0];
    let hierarchy = await getHierarchyFromAPI(Module);
    this.setState({ hierarchy });
  };

  getDataById = async () => {
    let formObject = {};
    formObject = this.state.record;
    _.cloneDeepWith(formObject, (value) => {
      return value && value !== null && value.FromTimeFrame && value.ToTimeFrame
        ? {
            ...value,
            ...(value['FromTimeFrame'] = [
              moment.utc(value['FromTimeFrame'][0], 'YYYY-MM-DD HH:mm:ss'),
              moment.utc(value['FromTimeFrame'][1], 'YYYY-MM-DD HH:mm:ss')
            ]),
            ...(value['ToTimeFrame'] = [
              moment.utc(value['ToTimeFrame'][0], 'YYYY-MM-DD HH:mm:ss'),
              moment.utc(value['ToTimeFrame'][1], 'YYYY-MM-DD HH:mm:ss')
            ])
          }
        : _.noop();
    });
    if (this.hRef.current) {
      this.hRef.current.setFieldsValue(formObject);
    }
    this.setState({
      formObject
      // datasource: formObject.Specifications
    });
  };
  fieldOnChange = async (value, key) => {
    let { formObject, deviceTypeList, deviceList, FaultCodesList } = this.state;
    let path = history.location.pathname.split('/rubus/Maximo/');
    let Module = path[1].split('/') && path[1].split('/')[0];
    if (value !== undefined) {
      if (key === 'Station') {
        let payload = {
          AssetCode: value
        };
        formObject = {
          ...formObject,
          [key]: value
        };
        let deviceTypeList1 = await getListById(ApiList.getDeviceTypeListByAsset, payload, Module);
        deviceTypeList = [
          ...(deviceTypeList1 && Array.isArray(deviceTypeList1) && deviceTypeList1.length > 0
            ? [{ AssetCode: 'All', AssetName: 'All', Code: 'All', Description: 'All' }]
            : []),
          ...deviceTypeList1
        ];
      } else if (key === 'deviceType') {
        //    let array1 = deviceTypeList.filter(function(item) {
        //         return value.includes(item.Code);
        //       });
        //       let AssetCode=[];
        //       array1.map((i)=>{
        //         AssetCode.push(i.AssetCode);
        //       });
        formObject = {
          ...formObject,
          [key]: value
        };
        let payload = {
          Module: Module,
          AssetDeviceType: value
        };
        let deviceList1 = await getListByType(ApiList.getDeviceListByDeviceType, payload);
        deviceList = [
          ...(deviceList1 && Array.isArray(deviceList1) && deviceList1.length > 0
            ? [{ AssetCode: 'All', DeviceName: 'All', DeviceCode: 'All', Description: 'All' }]
            : []),
          ...deviceList1
        ];
      } else if (key === 'DeviceCode') {
        formObject = {
          ...formObject,
          [key]: value
        };
        let payload = {
          Module: Module,
          DeviceCode: value
        };
        let FaultCodesList1 = await getListById(ApiList.getFaultCodesList, payload);
        FaultCodesList = [
          ...(FaultCodesList1 && Array.isArray(FaultCodesList1) && FaultCodesList1.length > 0
            ? [{ DeviceCode: 'All', DeviceName: 'All', Code: 'All', Description: 'All' }]
            : []),
          ...FaultCodesList1
        ];
      } else if (key === 'FromTimeFrame' || key === 'ToTimeFrame') {
        formObject = {
          ...formObject,
          [key]: value
        };
        this.setState({ formObject });
        if (this.hRef.current !== null) {
          this.hRef.current.setFieldsValue(formObject);
        }
      } else if (key === 'HaltStatus') {
        formObject = {
          ...formObject,
          [key]: value
        };
      }
    }
    // else if(key === "FaultCode"){
    //      let array1 = FaultCodesList.filter((item) =>{
    //      return value.includes(item.Code);
    //     });
    //     formObject = {
    //         ...formObject,
    //         [key]: value,
    //        ["Key"]: array1[0]["Key"]
    //     };
    // }

    if (this.hRef.current !== null) {
      this.hRef.current.setFieldsValue(formObject);
    }
    this.setState({ formObject, deviceTypeList, deviceList, FaultCodesList });
  };
  onFormsubmit = () => {
    let { selectedDeviceObject } = this.state;
    let path = history.location.pathname.split('/rubus/Maximo/');
    let Module = path[1].split('/') && path[1].split('/')[0];
    this.hRef.current
      .validateFields()
      .then(async (values) => {
        let TimeFrame = [];
        TimeFrame.push(values['FromTimeFrame'], values['ToTimeFrame']);
        let payload = {
          TimeFrame: TimeFrame,
          DeviceCode: selectedDeviceObject,
          Module: Module,
          HaltStatus: values['HaltStatus'],
          FaultCode: values['FaultCode']
        };
        let response = await submit(ApiList.Submit, payload);
        message.success(response.message);
      })
      .catch(() => {});
  };

  onModalsOk = (e) => {
    let { selectedDeviceObject } = this.state;
    e.preventDefault();
    this.fieldOnChange(selectedDeviceObject, 'DeviceCode');
    this.setState({
      treeVisible: false
    });
  };

  onModalCancel = () => {
    this.setState({
      treeVisible: false
    });
  };

  hierarchyDropdownChange = (sd, sdObject) => {
    let selectedDevice = [];
    let selectedDeviceObject = [];
    console.log('SelectedObjects', sdObject);
    selectedDeviceObject =
      sdObject &&
      sdObject
        .filter((d) => d.children && d.children.length === 0)
        .map((da) => {
          selectedDevice.push(da.key);
          return da;
        });
    console.log('===', selectedDevice, selectedDeviceObject);
    this.setState({ selectedDevice, selectedDeviceObject });
  };
  render() {
    let { formObject, selectedDevice, deviceList, FaultCodesList } = this.state;

    /* eslint-disable no-template-curly-in-string */
    const validateMessages = {
      required: '${label} is required!',
      types: {
        email: '${label} is not a valid email!',
        number: '${label} is not a valid number!'
      },
      number: {
        range: '${label} must be between ${min} and ${max}'
      }
    };
    return (
      <StyledComponent
        theme={themeSettings}
        style={{ padding: '50px 30px', minHeight: window.innerHeight - 117 }}
      >
        <StyledForm
          name="Equipment Master"
          theme={themeSettings}
          ref={this.hRef}
          onFinish={this.onFormsubmit}
          onFinishFailed={this.onFinishFailed}
          initialValues={formObject || {}}
          layout="horizontal"
          labelCol={{ flex: '250px' }}
          labelAlign="right"
          labelWrap
          // wrapperCol={{ flex: 1 }}
          validateMessages={validateMessages}
        >
          <div style={{ display: 'flex', justifyContent: 'right' }}>
            <Form.Item>
              <Button theme={themeSettings} type="primary" htmlType="submit">
                {'Submit'}
              </Button>
            </Form.Item>
          </div>

          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            <Form.Item
              name={'DeviceCode'}
              label={'Select Device'}
              rules={[{ required: false, message: 'Please Select Device!' }]}
              style={{
                width: '100%',
                flex: '100%'
              }}
            >
              <span
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start'
                }}
              >
                <Select
                  style={{
                    width: '75%',
                    opacity: 1,
                    pointerEvents: 'none'
                  }}
                  value={selectedDevice}
                  mode="multiple"
                  placeholder={'Select Device'}
                  size="default"
                >
                  {deviceList &&
                    Array.isArray(deviceList) &&
                    deviceList.map((device, index) => {
                      return (
                        <Option key={index} value={`${device.DeviceCode}`}>
                          {device.Name}::{device.DeviceCode}
                        </Option>
                      );
                    })}
                </Select>
                <Button
                  style={{ width: '5%' }}
                  type="primary"
                  onClick={() => {
                    this.setState({ treeVisible: true });
                  }}
                >
                  <UpSquareOutlined />
                </Button>
              </span>
            </Form.Item>
            <Form.Item
              label="From Time"
              name="FromTimeFrame"
              rules={[{ required: true }]}
              style={{
                width: '100%',
                flex: '100%'
              }}
            >
              <TimePicker
                style={{ width: '80%' }}
                onChange={(value) => this.fieldOnChange(value, 'FromTimeFrame')}
              ></TimePicker>
            </Form.Item>
            <Form.Item
              label="To Time"
              name="ToTimeFrame"
              rules={[{ required: true }]}
              style={{
                width: '100%',
                flex: '100%'
              }}
            >
              <TimePicker
                style={{ width: '80%' }}
                onChange={(value) => this.fieldOnChange(value, 'ToTimeFrame')}
              ></TimePicker>
            </Form.Item>
            <Form.Item
              label="Fault Code"
              name="FaultCode"
              style={{
                width: '100%',
                flex: '100%'
              }}
            >
              <Select
                placeholder="Please select Fault Code"
                style={{ width: '80%' }}
                mode={'multiple'}
                onChange={(value) => this.fieldOnChange(value, 'FaultCode')}
                disabled={formObject && formObject.Id ? true : false}
              >
                {FaultCodesList &&
                  Array.isArray(FaultCodesList) &&
                  FaultCodesList.map((item, index) => {
                    return (
                      <Option
                        key={index}
                        value={item.Code !== 'All' ? `${item.DeviceCode}$$$${item.Code}` : 'All'}
                      >
                        {item.Code !== 'All' ? `${item.Description}@${item.DeviceName}` : 'All'}
                        {/* {item.Description}@{item.DeviceName} */}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
            <Form.Item
              label="Status"
              name="HaltStatus"
              style={{
                width: '100%',
                flex: '100%'
              }}
              rules={[{ required: true }]}
            >
              <Select
                placeholder="Please select Status"
                style={{ width: '80%' }}
                onChange={(value) => this.fieldOnChange(value, 'HaltStatus')}
              >
                <Option key={'Enabled'} value={true}>
                  {'Enabled'}
                </Option>
                ;
                <Option key={'Disabled'} value={false}>
                  {'Disabled'}
                </Option>
                ;
              </Select>
            </Form.Item>
            <Form.Item
              name={'Key'}
              style={{
                width: '100%',
                flex: '100%'
              }}
            ></Form.Item>
            <StyledModal
              open={this.state.treeVisible}
              width={800}
              closable
              title={'Select Device'}
              okText={'Add'}
              onOk={this.onModalsOk}
              onCancel={this.onModalCancel}
            >
              <HierarchyModal
                selectedDevice={selectedDevice ? selectedDevice : []}
                treeData={this.state.hierarchy}
                Key="Trends"
                handleDropdownChange={this.hierarchyDropdownChange}
              />
            </StyledModal>
          </div>
        </StyledForm>
      </StyledComponent>
    );
  }
}
export default SRHaltTime;
