import React, { Component } from 'react';
import axios from 'axios';
import MonacoEditor from 'react-monaco-editor';
import { Button, Table, Drawer, Row, Input, Col, Select, Form, Collapse, message } from 'antd';
import { getUserProfile } from '../../../selectors/layout';
import { createStructuredSelector } from 'reselect';
import localStorage from '../../../utils/localStorage';
import { connect } from 'react-redux';
import { PlusOutlined, CaretRightOutlined } from '@ant-design/icons';

const { Option } = Select;
const columns = [
  {
    title: 'ID',
    dataIndex: 'Id',
    key: 'Id'
  },
  {
    title: 'SiteId',
    dataIndex: 'SiteId',
    key: 'SiteId'
  }
];
const customPanel = {
  background: '#f7f7f7',
  borderRadius: 4,
  marginBottom: 24,
  border: 0,
  overflow: 'hidden'
};
const { Panel } = Collapse;
class HierarchyUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns,
      visible: false,
      theme: 'vs-dark',
      editObj: {},
      datasource: [],
      editEditor: {},
      listType: '',
      userProfile: props && props.userProfile ? props.userProfile : {}
    };
    this.hRef = React.createRef();
    this.editRef = React.createRef();
  }
  componentDidMount() {
    this._getHierarchyList();
    this.siteList();
  }

  siteList = () => {
    let sitesArray = [];
     let siteId = localStorage.get('currentSite');
    let sitesListArray =
      this.props &&
      this.props.userProfile &&
      this.props.userProfile.belongsTo &&
      this.props.userProfile.belongsTo.sites[siteId] &&
      this.props.userProfile.belongsTo.sites;
      
    sitesArray =
      sitesListArray &&
      Object.keys(sitesListArray).map((key) => {
        return sitesListArray[key];
      });
    this.setState({
      sitesArray
    });
  };

  componentDidUpdate(prevprops) {
    if (this.props.userProfile !== prevprops.userProfile) {
      this.siteList();
    }
  }
  onClose = () => {
    if (this.hRef.current) {
      this.hRef.current.resetFields();
    }
    this.setState({
      visible: false,
      editObj: {}
    });
  };
  addNewHierarchy = () => {
    if (this.hRef.current) {
      this.hRef.current.resetFields();
    }
    this.setState({
      visible: true,
      editObj: {},
      listType: 'save',
      editEditor: {}
    });
  };
  _getHierarchyList = () => {
    const siteId = localStorage.get('currentSite');
    let accessToken = localStorage.get('accessToken');
    const healthHeaders = {
      method: 'GET',
      url: `/api/hierarchy/get?SiteId=${siteId}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    };
    axios(healthHeaders)
      .then((response) => {
        this.setState({ datasource: response.data, visible: false });
      })
      .catch(() => {});
  };
  _saveHierarchyAPI = (payload) => {
    const accessToken = localStorage.get('accessToken');
    const deviceTypeObject = {
      method: 'POST',
      url: `/api/hierarchy/create`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: payload
    };
    axios(deviceTypeObject)
      .then(() => {
        this.setState({
          visible: false
        });
        this._getHierarchyList();
      })
      .catch(function () {});
  };
  _updateHierarchyAPI = (payload) => {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    const deviceTypeObject = {
      method: 'PATCH',
      url: `/api/hierarchy/update?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: payload
    };
    axios(deviceTypeObject)
      .then(() => {
        this.setState({
          visible: false
        });
        this._getHierarchyList();
      })
      .catch(function () {});
  };
  _deleteHmiAPI = (payload) => {
    const accessToken = localStorage.get('accessToken');
    const deviceTypeObject = {
      method: 'DELETE',
      url: `/api/hierarchy/delete?Id=${payload}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    axios(deviceTypeObject)
      .then(() => {
        this.setState({
          visible: false
        });
        this._getHierarchyList();
      })
      .catch(function () {});
  };

  saveHMI = (operation) => {
    let { editObj } = this.state;
    if (operation === 'delete') {
      this.hRef.current
        .validateFields()
        .then(async (values) => {
          let Id = values.ID;
          this._deleteHmiAPI(Id);
        })
        .catch(() => {});
    } else if (operation === 'update') {
      this.hRef.current
        .validateFields()
        .then(async (values) => {
          const model = this.editRef.editor.getModel();
          const value = model.getValue();
          if (typeof JSON.parse(value) !== 'string') {
            let updateObj = {
              Id: editObj.Id,
              SiteId: values.SiteId,
              Hierarchy: JSON.parse(value)
            };
            if (this.hRef.current) {
              this.hRef.current.resetFields();
            }
            this.setState({ editObj: {}, visible: false });
            this._updateHierarchyAPI(updateObj);
          } else {
            alert('Error');
          }
        })
        .catch(() => {});
    } else if (operation === 'save') {
      this.hRef.current
        .validateFields()
        .then(async (values) => {
          const model = this.editRef.editor.getModel();
          const value = model.getValue();

          if (typeof JSON.parse(value) !== 'string') {
            let saveObj = {
              SiteId: values.SiteId,
              Hierarchy: JSON.parse(value)
            };
            if (this.hRef.current) {
              this.hRef.current.resetFields();
            }
            this.setState({ editObj: {}, visible: false });
            this._saveHierarchyAPI(saveObj);
          } else {
            alert('Error');
          }
        })
        .catch(() => {});
    }
  };

  _saveHierarchyAPI = (payload) => {
    const accessToken = localStorage.get('accessToken');
    const deviceTypeObject = {
      method: 'POST',
      url: `/api/hierarchy/create`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: payload
    };
    axios(deviceTypeObject)
      .then(() => {
        this.setState({
          visible: false
        });
        this._getHierarchyList();
      })
      .catch(function () {});
  };

  editorDidMount = (editor) => {
    setTimeout(function () {
      editor.getAction('editor.action.formatDocument').run();
    }, 300);
  };

  editorWillMount = (monaco) => {
    monaco.languages.typescript.typescriptDefaults.setCompilerOptions({});
  };
  onFinishFailed = () => {
    message.error('please enter required fields');
  };

  render() {
    let { columns, datasource, editEditor, editObj, listType, sitesArray } = this.state;
    const options = {
      selectOnLineNumbers: false
    };
    return (
      <div style={{ minHeight: window.innerHeight - 234 }}>
        <Drawer
          title="Hierarchy Upload"
          width={720}
          closable
          onClose={this.onClose}
          visible={this.state.visible}
          bodyStyle={{ paddingBottom: 80 }}
        >
          <Form
            layout="vertical"
            ref={this.hRef}
            hideRequiredMark
            name={'test'}
            // labelCol={{ span: 10 }}
            // wrapperCol={{ span: 14 }}
            initialValues={editObj || {}}
            onFinishFailed={this.onFinishFailed}
          >
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col className="gutter-row" span={18}>
                {listType === 'save' ? (
                  <div />
                ) : (
                  <Form.Item
                    label="ID :"
                    name="Id"
                    rules={[{ required: true, message: 'Please enter ID' }]}
                  >
                    <Input placeholder="Please enter ID" disabled={!editObj.Id ? false : true} />
                  </Form.Item>
                )}

                <Form.Item
                  label="SiteID :"
                  name="SiteId"
                  rules={[{ required: true, message: 'Please enter Site ID' }]}
                >
                  <Select placeholder="Please Select Site ID">
                    {sitesArray &&
                      sitesArray.map((item, index) => (
                        <Option key={index} value={item.site.Id}>
                          {item.site.Name}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form>

          <div>Enter Hierarchy JSON</div>
          <Collapse
            bordered={false}
            style={{ marginTop: '20px' }}
            // defaultActiveKey={['1']}
            expandIcon={({ isActive }) => (
              <CaretRightOutlined rotate={isActive ? 90 : 0}></CaretRightOutlined>
            )}
          >
            <Panel key="1" header={'JSON'} style={customPanel}>
              <MonacoEditor
                width="680"
                height="500"
                language="json"
                theme="vs-dark"
                defaultValue={JSON.stringify(editEditor)}
                options={options}
                ref={(el) => (this.editRef = el)}
                value={JSON.stringify(editEditor, null, '\t')}
                editorWillMount={this.editorWillMount}
                editorDidMount={this.editorDidMount}
              />
              {this.state.listType === 'save' ? (
                <Button
                  type="primary"
                  shape="round"
                  onClick={() => this.saveHMI('save')}
                  style={{ marginRight: '10px', marginTop: '10px' }}
                >
                  Create
                </Button>
              ) : (
                <Button
                  type="primary"
                  shape="round"
                  onClick={() => this.saveHMI('update')}
                  style={{ marginTop: '10px' }}
                >
                  Update
                </Button>
              )}

              {
                <Button
                  type="primary"
                  shape="round"
                  onClick={() => this.saveHMI('delete')}
                  style={{ marginTop: '10px', marginLeft: '10px' }}
                >
                  Delete
                </Button>
              }
            </Panel>
          </Collapse>
        </Drawer>
        <Button type="primary" onClick={this.addNewHierarchy} style={{ marginBottom: '10px' }}>
          {' '}
          <PlusOutlined /> Create Hierarchy
        </Button>
        <Table
          columns={columns}
          dataSource={datasource}
          onRow={(rowData) => {
            return {
              onClick: () => {
                if (this.hRef.current) {
                  this.hRef.current.setFieldsValue(rowData);
                }
                this.setState({
                  visible: true,
                  editEditor: rowData.Hierarchy,
                  editObj: rowData,
                  listType: 'update'
                });
              }
            };
          }}
          pagination={false}
        />
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  userProfile: getUserProfile()
});

export default connect(mapStateToProps)(HierarchyUpload);
