import React from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';

import { getTranslationCache } from '../../selectors/language';
import { getCurrentTimezone, getUserProfile } from '../../selectors/layout';
import GraphComponent from './Widget/GraphComponent';
import AllGraphComponent from './Widget/AllGraphComponent';

class DataComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      FilterData: this.props && this.props.filterData ? this.props.filterData : [],
      AllFilterData: this.props && this.props.allFilterData ? this.props.allFilterData : []
    };
  }

  componentDidUpdate(prevprops) {
    if (prevprops.FilterData !== this.props.FilterData) {
      this.setState({ FilterData: this.props.FilterData, AllFilterData: this.props.AllFilterData });
    }
  }

  render() {
    let { FilterData, AllFilterData } = this.state;
    return (
      <div>
        <GraphComponent FilterData={FilterData} />
        <div style={{ marginTop: '100px' }}>
          <AllGraphComponent AllFilterData={AllFilterData} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  userProfile: getUserProfile(),
  translationCache: getTranslationCache(),
  currentTimeZone: getCurrentTimezone()
});
export default connect(mapStateToProps)(DataComponent);
