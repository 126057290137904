import axios from 'axios';
import localStorage from '../../../../utils/localStorage';
import { message } from 'antd';

export const getList = (url, Module) => {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');

  let headers = {
    method: 'GET',
    url: `${url}?SiteId=${siteId}&Module=${Module}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(headers)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      if (err.response.status === 400 || err.response.status === 500) {
        message.error(err.response.data.message);
      }
      return [];
    });
};

export async function getMasterList(url, Module) {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  let Object = {};
  // if(master === "AssetMasterHierarchy")
  // {
  Object = {
    method: 'GET',
    url: `${url}?SiteId=${siteId}&Module=${Module}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  // }

  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err.response.status === 400 || err.response.status === 500) {
        message.error(err.response.data.message);
      }
      return [];
    });
}

export async function getUsersList() {
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;

  let data = {
    url: `/api/user/list`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    }
  };
  return axios(data)
    .then((response) => {
      return response.data;
    })

    .catch((err) => {
      if (err.response.status === 400 || err.response.status === 500) {
        message.error(err.response.data.message);
      }
      return [];
    });
}
export const getListById = (url, payload, Module) => {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  let headers = {
    method: 'POST',
    url: `${url}?SiteId=${siteId}&Module=${Module}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    data: payload
  };
  return axios(headers)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      if (err.response.status === 400 || err.response.status === 500) {
        message.error(err.response.data.message);
      }
      return [];
    });
};
export const getListByType = (url, payload) => {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  let headers = {
    method: 'POST',
    url: `${url}?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    data: payload
  };
  return axios(headers)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      if (err.response.status === 400 || err.response.status === 500) {
        message.error(err.response.data.message);
      }
      return [];
    });
};
export const submit = (url, payload) => {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');

  let headers = {
    method: 'POST',
    url: `${url}?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    data: payload
  };
  return axios(headers)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const getHierarchyFromAPI = (Module) => {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');

  let headers = {
    method: 'GET',
    url: `/api/maximoasset/getMaximoHierarchy?Module=${Module}&SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(headers)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};
export const getDeviceList = () => {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  let headers = {
    method: 'GET',
    url: `/api/device/list?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(headers)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      if (err.response.status === 400 || err.response.status === 500) {
        message.error(err.response.data.message);
      }
      return [];
    });
};
