import React from 'react';
import { connect } from 'react-redux';
import { List, Card, Tag, Modal, Button, Row } from 'antd';
import { createStructuredSelector } from 'reselect';
import moment from 'moment';
import axios from 'axios';

import { getChartDataWithoutSelection, getChartDataWithSelection } from '../../ChartCalls';
import { tableTranslation } from '../../../Utils/Adapter/translationAdapter';
import ReactEcharts from '../../Echarts/Echarts';
import { getTranslationCache } from '../../../../../selectors/language';
import { ErrorComponent, NoRecordComponent } from '../../Utils';
import localStorage from '../../../../../utils/localStorage';
import { arrayOfObjects } from '../../../Mock/MockJson';
import { defaultRefreshTime } from '../../constant.json';
import '../../CSS/CounterList.css';

class CardsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cardsList: [],
      translationCache: props.translationCache || [],
      refreshDateTime: moment(),
      errorInAPI: false,
      noDataInDB: false,
      last30Records: false,
      option: this.getOption()
    };
  }

  timeTicket = null;
  requestCheck = true;
  abortController = new window.AbortController();

  async componentDidMount() {
    let { graphprops } = this.props;
    if (this.timeTicket) {
      clearInterval(this.timeTicket);
    }
    this.getData(this.props);
    if (
      graphprops &&
      graphprops[graphprops.graph] &&
      graphprops[graphprops.graph].show30minsData &&
      graphprops[graphprops.graph].show30minsData === 'true'
    ) {
      this.getLast30minsData();
    }

    let refreshTime = '';
    if (this.props.graphprops.refreshTime) {
      refreshTime = this.props.graphprops.refreshTime * 60000;
    } else {
      refreshTime = defaultRefreshTime;
    }

    this.timeTicket = setInterval(() => {
      if (this.requestCheck) {
        this.getData(this.props, this.props.selectedThemeing);
      }
    }, refreshTime);
  }

  componentWillUnmount() {
    this.abortController.abort();
    clearInterval(this.timeTicket);
  }
  componentDidUpdate(prevProps) {
    if (
      prevProps.graphprops !== this.props.graphprops ||
      prevProps.selectionDate !== this.props.selectionDate ||
      prevProps.selectionDateLive !== this.props.selectionDateLive ||
      prevProps.selectionMonth !== this.props.selectionMonth ||
      prevProps.selectionWeek !== this.props.selectionWeek ||
      prevProps.DeviceCode !== this.props.DeviceCode ||
      prevProps.shift !== this.props.shift ||
      prevProps.selectionDateRange !== this.props.selectionDateRange ||
      prevProps.dayMonthYear !== this.props.dayMonthYear ||
      prevProps.dropdownSelection !== this.props.dropdownSelection ||
      prevProps.dashboardPayload !== this.props.dashboardPayload ||
      prevProps.AssetCode !== this.props.AssetCode ||
      prevProps.translationCache !== this.props.translationCache
    ) {
      this.getData(this.props);
    }
  }

  getLast30minsData = () => {
    let payload = {
      Type: 'live',
      ViewType: 'Single',
      GraphType: 'line',
      ParameterList: [
        {
          ParameterCode: 'Brake_Status_Motoring',
          DeviceCode: 'BECU_12',
          TableName: 'OnChange',
          parameterType: 'OnChange',
          parameterDescription: '-',
          parameterName: 'Drive Speed Command',
          deviceName: 'Brake_Electronic_Control_Unit'
        },
        {
          ParameterCode: 'Brake_Status_Motoring',
          DeviceCode: 'BECU_19',
          TableName: 'OnChange',
          parameterType: 'OnChange',
          parameterDescription: '-',
          parameterName: 'Drive Output Voltage',
          deviceName: 'Brake_Electronic_Control_Unit'
        },
        {
          ParameterCode: 'Brake_Status_Coasting',
          DeviceCode: 'BECU_1',
          TableName: 'OnChange',
          parameterType: 'OnChange',
          parameterDescription: '-',
          parameterName: 'Motor Speed Status',
          deviceName: 'Brake_Electronic_Control_Unit'
        }
      ],
      SiteId: '1'
    };
    const accessToken = localStorage.get('accessToken');
    const SiteId = localStorage.get('currentSite');
    const deviceTypeObject = {
      method: 'POST',
      url: `/api/trends/list?SiteId=${SiteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: payload
    };
    axios(deviceTypeObject)
      .then((response) => {
        let { option } = this.state;
        let data = response.data;
        let legendData = data.map((item) => item.Description);
        let xAxisData = data.map((item) => item.DateTime);
        let seriesData =
          legendData &&
          Array.isArray(legendData) &&
          legendData.map((legend) => {
            let dataObject =
              data &&
              Array.isArray(data) &&
              data.map((e) => {
                if (e.Description === legend) {
                  return e.Value;
                }
                return {};
              });
            return {
              name: legend,
              type: 'line',
              stack: 'Total',
              data: dataObject
            };
          });

        option = {
          ...option,
          legend: {
            data: legendData
          },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            data: xAxisData
          },
          series: seriesData
        };
        this.setState({ option });
      })
      .catch(function () {});
  };

  getData = async () => {
    this.requestCheck = false;
    let { graphprops } = this.props;
    let data = [];
    let { errorInAPI, noDataInDB } = this.state;
    errorInAPI = false;
    noDataInDB = false;
    if (graphprops && graphprops.enableMock && graphprops.enableMock === true) {
      data = arrayOfObjects;
      this.requestCheck = true;
    } else {
      if (
        (graphprops && graphprops.dashboardType === 'dynamic') ||
        (graphprops.dashboardType === 'dropdownHide' && this.props.DeviceCode) ||
        (graphprops &&
          (graphprops.dateExists === 'true' || graphprops.selection === 'datePicker') &&
          this.props.selectionDate) ||
        (graphprops && graphprops.monthExists === 'true' && this.props.selectionMonth) ||
        (graphprops && graphprops.weekExists === 'true' && this.props.selectionWeek) ||
        (graphprops && graphprops.shiftExists === 'true' && this.props.shift) ||
        (graphprops &&
          (graphprops.dateRangeExists === 'true' || graphprops.selection === 'dateRangePicker') &&
          this.props.selectionDateRange) ||
        (graphprops &&
          (graphprops.dayMonthYearExists === 'true' ||
            graphprops.selection === 'dateMonthYearPicker') &&
          this.props.dayMonthYear) ||
        (graphprops && graphprops.dashboardType === 'hierarchy' && this.props.dropdownSelection) ||
        (graphprops && this.props.dashboardPayload) ||
        (graphprops && graphprops.treeExists === 'true' && this.props.AssetCode)
      ) {
        data = await getChartDataWithSelection(
          graphprops,
          this.props.DeviceCode,
          this.props.selectionDate,
          this.props.selectionMonth,
          this.props.selectionWeek,
          this.props.shift,
          this.props.selectionDateRange,
          this.props.dayMonthYear,
          this.abortController,
          this.props.dropdownSelection,
          this.props.dashboardPayload,
          this.props.AssetCode,
          this.props.selectionDateLive,
          this.props.currentTimeZone
        );
        this.requestCheck = true;
      } else {
        data = await getChartDataWithoutSelection(
          graphprops,
          this.abortController,
          this.props.dropdownSelection,
          this.props.selectionDateLive,
          '',
          this.props.currentTimeZone
        );
        this.requestCheck = true;

        this.setState({ data: data }); //need
      }
    }
    if (
      (data && data.message) ||
      (Array.isArray(data) && data.length === 0 && graphprops.enableMock !== true)
    ) {
      if (data.message) {
        errorInAPI = true;
      } else if (data.length === 0) {
        noDataInDB = true;
      }
    } else {
      if (data && Array.isArray(data) && data.length > 0) {
        let columnsData = await tableTranslation(data, this.props.translationCache);
        let cardsList = columnsData.map((column) => {
          return {
            title: column.Description,
            value: column.Value
          };
        });
        this.setState({ cardsList });
      } else {
        this.setState({
          cardsList: [],
          refreshDateTime: moment()
        });
      }
    }
    this.setState({
      errorInAPI,
      noDataInDB
    });
  };

  getOption = () => {
    let theme = this.props.selectedThemeing;
    let option = {
      tooltip: {
        trigger: 'axis'
      },
      legend: {
        data: []
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      toolbox: {
        showTitle: false,
        itemSize: 25,
        feature: {
          saveAsImage: {
            backgroundColor: theme === 'lightTheme' ? 'black' : 'white',
            title: 'download',
            name: this.props.graphprops.title,
            iconStyle: {
              borderColor: '#8c8c8c',
              borderWidth: 2
            }
          }
        }
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: []
      },
      yAxis: {
        type: 'value'
      },
      series: []
    };
    return option;
  };

  render() {
    const { errorInAPI, noDataInDB, refreshDateTime, last30Records, option, cardsList } =
      this.state;
    let { graphprops } = this.props;
    let colors = [
      // 'steelblue',
      // '#a0d911',
      // '#faad14',
      // '#c41d7f',
      // '#006d75',
      // 'steelblue',
      // '#a0d911',
      // '#faad14',
      // '#c41d7f',
      // '#006d75'
    ];
    if (
      graphprops &&
      graphprops[graphprops.graph] &&
      graphprops[graphprops.graph].colors &&
      graphprops[graphprops.graph].colors.length > 0
    ) {
      colors = {
        ...(graphprops[graphprops.graph].colors &&
        Array.isArray(graphprops[graphprops.graph].colors)
          ? graphprops[graphprops.graph].colors
          : []),
        ...colors
      };
    }
    return (
      <>
        {graphprops && graphprops.checked === true ? (
          <Tag className="graphTag" color="blue">
            {refreshDateTime ? moment(refreshDateTime).format('YYYY-MM-DD HH:mm:ss') : ''}
          </Tag>
        ) : null}
        {last30Records ? (
          <Modal
            title="Last 30mins Data"
            open={last30Records}
            onCancel={() => {
              this.setState({ last30Records: false });
            }}
          >
            <ReactEcharts
              option={option}
              notMerge={true}
              lazyUpdate={true}
              className="react_for_echarts"
            />
          </Modal>
        ) : null}
        {graphprops &&
        graphprops[graphprops.graph] &&
        graphprops[graphprops.graph].show30minsData &&
        graphprops[graphprops.graph].show30minsData === 'true' ? (
          <Button
            type="primary"
            style={{ float: 'right' }}
            onClick={() => {
              this.setState({ last30Records: true });
            }}
          >
            Show last 30mins Data
          </Button>
        ) : null}
        {noDataInDB === true || errorInAPI === true ? (
          noDataInDB === true ? (
            <NoRecordComponent />
          ) : (
            <ErrorComponent />
          )
        ) : (
          <div style={{ padding: '10px' }}>
            {this.props &&
            this.props.graphprops &&
            this.props.graphprops[graphprops.graph] &&
            this.props.graphprops[graphprops.graph].viewType === 'listOfItems' ? (
              <List
                itemLayout="horizontal"
                dataSource={cardsList}
                renderItem={(item, index) => (
                  <List.Item>
                    <List.Item.Meta
                      style={{ marginLeft: '30px' }}
                      title={
                        <span style={{ color: colors[index], fontSize: '16px' }}>{item.title}</span>
                      }
                    />
                    <Row>
                      <div style={{ color: colors[index], fontSize: '16px' }}>{item.value}</div>
                    </Row>
                  </List.Item>
                )}
              />
            ) : null}

            {this.props &&
            this.props.graphprops &&
            this.props.graphprops[graphprops.graph] &&
            this.props.graphprops[graphprops.graph].viewType === 'listOfCards' ? (
              <List
                grid={{
                  gutter: 10,
                  column: this.props.graphprops[graphprops.graph].numberOfColumns
                    ? this.props.graphprops[graphprops.graph].numberOfColumns
                    : 3
                }}
                dataSource={cardsList}
                style={{
                  textAlign: 'center',
                  textColor: '#fff !important',
                  fontSize: '3.5em'
                }}
                renderItem={(item, index) => (
                  <List.Item>
                    <Card
                      className="counterCard"
                      title={item.title}
                      style={{ backgroundColor: colors[index] || 'black', fontSize: '1.5em' }}
                    >
                      {graphprops &&
                      graphprops[graphprops.graph] &&
                      graphprops[graphprops.graph].displayType &&
                      graphprops[graphprops.graph].displayType === 'Image' ? (
                        <img
                          src={item.value}
                          type="image"
                          alt=""
                          style={{ marginTop: '8px', height: '222px' }}
                        />
                      ) : (
                        item.value
                      )}
                    </Card>
                  </List.Item>
                )}
              />
            ) : null}
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache()
});

export default connect(mapStateToProps)(CardsList);
