import React from 'react';

import ChangeFirstPasswordComponent from './ChangeFirstPasswordComponent';
import { LoginPageBackground, LoginBox, LoginSection, Login } from './styles';

const ChangeFirstPassword = () => {
  document.title = 'Change Password - Industrial IOT Platform';
  return (
    <Login>
      <LoginSection>
        {/* <LoginPageBackground style={{ backgroundImage: `url(/api/masterImages/${process.env.REACT_APP_LOGINLOGO})` }} /> */}
        <LoginPageBackground style={{ backgroundImage: `url('/covers/kpp-login.jpg')` }} />
        <LoginBox>
          <ChangeFirstPasswordComponent />
        </LoginBox>
      </LoginSection>
    </Login>
  );
};

export { ChangeFirstPassword };
