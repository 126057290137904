import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import { Form, Input } from 'antd';
import { UserOutlined, LockOutlined, AimOutlined } from '@ant-design/icons';

import localStorage from '../../../utils/localStorage';
import rubusLogo from '../../../images/rubus_new/okulogo.jpg';
import config from '../../../constants/config.json';
import history from '../../../commons/history';
import { getTranslationCache } from '../../../selectors/language';
import { createStructuredSelector } from 'reselect';

import { HeaderLogo, Welcome, Add, LoginButton, WelcomeModule } from './styles';

class LoginComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      translationCache: props.translationCache || [],
      userToken: props.userToken || '',
      ShowSite: false,
      SiteName: ''
    };
  }
  componentDidMount() {
    let path = history.location.pathname.split('/login/');
    if (path && path[1]) {
      this.setState({ SiteName: path[1], ShowSite: false });
    } else {
      this.setState({ SiteName: "okumura",ShowSite: false });
    }
  }

  translation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };

  render() {
    let { ShowSite, SiteName } = this.state;
    const { reset } = config;
    const userToken = localStorage.get('accessToken');
    if (
      userToken !== 'undefined' &&
      userToken !== 'null' &&
      userToken !== '' &&
      userToken !== undefined &&
      userToken !== null
    ) {
      return <Redirect to="/rubus/LandingPage" />;
    }

    const onFinish = (values) => {
      let { SiteName } = this.state;
      if (ShowSite === false) {
        values = { ...values, siteName: SiteName };
      }
      localStorage.set('loggedIn', true);
      this.props.actions.userLoginAuth(values);
    };

    return (
      <section style={{ width: '100%' }}>
        <HeaderLogo>
          <img src={rubusLogo} alt="logo" style={{ height: '60px' }} />
        </HeaderLogo>

        <Welcome style={{ fontSize: '17px', color: '#444343' }}>
          {this.translation('Welcome back, sign in with your account')}
        </Welcome>
        {ShowSite === false ? <WelcomeModule>{`${_.startCase(_.toLower(SiteName))} Application`}</WelcomeModule> : null}

        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          onFinish={onFinish}
          autoComplete="off"
        >
          {ShowSite === true ? (
            <Form.Item
              name="siteName"
              wrapperCol={{ offset: 6, span: 16 }}
              rules={[{ required: true, message: this.translation('Please input your Site Name') }]}
            >
              <Input
                style={{ width: '380px' }}
                prefix={<AimOutlined />}
                placeholder={this.translation('SiteName')}
              />
            </Form.Item>
          ) : null}

          <Form.Item
            name="identifier"
            wrapperCol={{ offset: 6, span: 16 }}
            rules={[
              {
                required: true,
                message: this.translation('Please input your username/email/mobilenumber!!')
              }
            ]}
          >
            <Input
              style={{ width: '380px' }}
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder={this.translation('Username/Email/Mobilenumber!!')}
            />
          </Form.Item>

          <Form.Item
            name="password"
            wrapperCol={{ offset: 6, span: 16 }}
            rules={[{ required: true, message: this.translation('Please input your password!') }]}
          >
            <Input.Password
              prefix={<LockOutlined className="site-form-item-icon" />}
              style={{ width: '380px' }}
              placeholder={this.translation('Password')}
            />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 6, span: 16 }}>
            <LoginButton type="primary" htmlType="submit">
              {this.translation('Log in')}
            </LoginButton>
          </Form.Item>
        </Form>

        {reset ? (
          <Add>
            {this.translation('Forgot your password?')}{' '}
            <Link to={{ pathname: `/forget-password` }}>{this.translation('Reset password')}</Link>
          </Add>
        ) : (
          ''
        )}
      </section>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache()
});

export default connect(mapStateToProps)(LoginComponent);
