import React, { Component } from 'react';
import axios from 'axios';
import { Button, Input, Modal, Select, Tree } from 'antd';

import localStorage from '../../utils/localStorage';
import { DownloadOutlined, UpSquareOutlined } from '@ant-design/icons';

const { Option } = Select;

const { TreeNode } = Tree;
class SelectComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initalRender: true,
      hierarchyData: [],
      isModalVisible: false,
      deviceList: []
    };
  }

  componentDidUpdate(prevprops) {
    if (prevprops.column !== this.props.column) {
      this.setState({
        column: this.props.column
      });
    }
  }

  componentDidMount() {
    let column = this.props;
    this._getOptionList(column);
    // if (column && column.type && column.type === 'Hierarchy') {
    this.getDashboardData();
    // }
  }
  getDashboardData = async () => {
    let deviceList = await this._getDevicesList();
    this.setState({ deviceList });
  };

  _getDevicesList = () => {
    const accessToken = localStorage.get('accessToken');
    const siteId = localStorage.get('currentSite');
    const ObjectHeader = {
      method: 'GET',
      url: `/api/device/get?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    return axios(ObjectHeader)
      .then((response) => {
        return response.data;
      })
      .catch(() => {
        return [];
      });
  };

  renameProperty = (obj, oldName1, newName1, oldName2, newName2) => {
    if (obj && obj.children) {
      let children = obj.children.map((objChildren) => {
        return this.renameProperty(objChildren, oldName1, newName1, oldName2, newName2);
      });
      obj['children'] = children;
      obj[newName1] = obj[oldName1];
      delete obj[oldName1];
      obj[newName2] = obj[oldName2];
      delete obj[oldName2];
      return obj;
    } else {
      obj[newName1] = obj[oldName1];
      delete obj[oldName1];
      obj[newName2] = obj[oldName2];
      delete obj[oldName2];
      return obj;
    }
  };

  _getOptionList = (tableName) => {
    let { column, initalRender } = this.props;
    if (column && column.type && column.type === 'Hierarchy') {
      const userData = JSON.parse(localStorage.get('modules'));
      if (userData && userData.hierarchy) {
        let hierarchyData = userData.hierarchy.map((userHierarchy) => {
          return this.renameProperty(userHierarchy, 'label', 'title', 'value', 'key');
        });
        this.setState({ hierarchyData });
      }
    } else {
      const siteId = localStorage.get('currentSite');
      const accessToken = localStorage.get('accessToken');
      let objectHeader = {};
      if (tableName && tableName.table && Array.isArray(tableName.table)) {
        this.setState({
          optionList: tableName.table
        });
        return tableName.table;
      }
      if (tableName && tableName.table && tableName.table.url && !tableName.table.method) {
        objectHeader = {
          method: 'GET',
          url: `${tableName.table.url}?SiteId=${siteId}`,
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        };
      } else if (
        tableName &&
        tableName.table &&
        tableName.table.url &&
        tableName.table.method &&
        tableName.table.payload &&
        tableName.table.method === 'POST'
      ) {
        objectHeader = {
          method: tableName.table.method,
          url: `${tableName.table.url}?SiteId=${siteId}`,
          headers: {
            Authorization: `Bearer ${accessToken}`
          },
          data: tableName.table.payload
        };
      } else {
        objectHeader = {
          method: 'POST',
          url: `/api/master/dropdown?SiteId=${siteId}`,
          headers: {
            Authorization: `Bearer ${accessToken}`
          },
          data: {
            tableName: tableName.table
          }
        };
      }
      return axios(objectHeader)
        .then((response) => {
          if (Array.isArray(response.data)) {
            let optionList = response.data;
            this.setState({
              optionList
            });
            if (
              optionList[0] &&
              column.option &&
              column.option.value &&
              initalRender &&
              initalRender === true
            ) {
              this.props.handleFieldChange(
                optionList[0][column.option.value],
                {
                  optionList,
                  initalRender: false
                },
                column
              );
            }
          }
        })
        .catch(() => {
          return [];
        });
    }
  };
  getData = (DeviceCode) => {
    let { column, optionList } = this.props;
    this.props.handleFieldChange(
      DeviceCode,
      {
        optionList,
        initalRender: false
      },
      column
    );
  };

  renderTreeNodes = (data) => {
    return (
      data &&
      Array.isArray(data) &&
      data.map((item) => {
        if (item.children && item.children.length > 0) {
          return (
            <TreeNode
              title={
                <div
                // onClick={() => this.getData(item)}
                >
                  {item.title}
                </div>
              }
              key={item.key}
              dataRef={item}
            >
              {this.renderTreeNodes(item.children)}
            </TreeNode>
          );
        }
        return (
          <TreeNode
            key={item.key}
            title={<div onClick={() => this.getData(item.Code)}>{item.title}</div>}
            dataRef={item}
          />
        );
      })
    );
  };

  render() {
    let { column, record, optionList } = this.props;
    let { hierarchyData, isModalVisible, deviceList } = this.state;
    let filteredDevice =
      column && column.defaultValue && deviceList && Array.isArray(deviceList)
        ? deviceList.filter((device) => device.DeviceCode === column.defaultValue)
        : [];

    return (
      <div>
        {column && column.type && column.type !== 'Hierarchy' ? (
          <div>
            <span style={{ fontWeight: 'bold', color: 'white' }}>{column.title}</span>
            <Select
              value={column && column.defaultValue ? column.defaultValue : ''}
              //  defaultValue={optionList && optionList[0] && optionList[0].Name ? optionList[0].Name : ""}
              style={{ width: 320, margin: '0px 5px' }}
              onChange={(e) =>
                this.props.handleFieldChange(
                  e,
                  {
                    optionList,
                    initalRender: false
                  },
                  column
                )
              }
              onKeyPress={(e) => this.props.handleKeyPress(e, record.key)}
              placeholder={column.placeHolder || column.displayName}
            >
              {optionList &&
                optionList.map((data, index) => {
                  if (
                    column &&
                    column.option &&
                    column.option.display &&
                    column.option.value &&
                    data[column.option.value] &&
                    data[column.option.display]
                  ) {
                    return (
                      <Option key={index} value={data[column.option.value]}>
                        {data[column.option.display]}
                      </Option>
                    );
                  }
                  return (
                    <Option key={index} value={data}>
                      {data}
                    </Option>
                  );
                })}
            </Select>
          </div>
        ) : (
          <span
            style={{
              height: '40px',
              display: 'flex',
              justifyContent: 'flex-start',
              flexFlow: 'row wrap',
              marginTop: '10px'
            }}
          >
            <Input
              // value={column && column.defaultValue ? column.defaultValue : ''}
              value={
                filteredDevice &&
                Array.isArray(filteredDevice) &&
                filteredDevice[0] &&
                filteredDevice[0].Name
                  ? `${filteredDevice[0].AssetName ? filteredDevice[0].AssetName : ''}${
                      filteredDevice[0].Name ? `::${filteredDevice[0].Name}` : ''
                    }  `
                  : ''
              }
              size="small"
              style={{ width: 300, opacity: 1, height: '32px' }}
            />
            <Button
              type="primary"
              style={{ marginRight: '5px' }}
              onClick={() => {
                this.setState({ isModalVisible: !isModalVisible });
              }}
            >
              <UpSquareOutlined />
            </Button>
            <Modal
              title={'Select Hierarchy'}
              className="hierarchyModal"
              visible={this.state.isModalVisible}
              bodyStyle={{ overflowY: 'scroll', height: '900px' }}
              footer={null}
              onCancel={() => {
                this.setState({
                  isModalVisible: false
                });
              }}
            >
              <div style={{ height: '70%', marginTop: '10px' }}>
                <Tree
                  showLine
                  defaultExpandAll={true}
                  switcherIcon={<DownloadOutlined />}
                  onExpand={this.onExpand}
                  value={column.defaultValue ? [column.defaultValue] : ''}
                >
                  {this.renderTreeNodes(hierarchyData)}
                </Tree>
              </div>
            </Modal>
          </span>
        )}
      </div>
    );
  }
}

export default SelectComponent;
