import styled from 'styled-components';
import { Tabs } from 'antd';


export const StyledApplication = styled.div`
  background: white;
  margin: 20px;
  padding: 30px;
  min-height: 750px;
  font-size:16px;
`;

export const StyledTabs = styled(Tabs)`
  .ant-tabs-tab {
    position: relative;
    display: inline-flex;
    align-items: center;
    padding: 12px 0;
    font-size: 14px;
    background: transparent;
    border: 0;
    outline: none;
    cursor: pointer;
  }

  .ant-tabs-tab-remove {
    flex: none;
    margin-right: -4px;
    margin-left: 8px;
    font-size: 12px;
    background: #1a3b5c;
    border: none;
    outline: none;
    cursor: pointer;
    transition: all 0.3s;
    color: white !important;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${(props) => props.theme.form.tabs.color};
    text-shadow: 0 0 0.25px currentcolor;
  }
  .checkTab {
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    opacity: 0.5;
    cursor: not-allowed !important;
    pointer-events: none !important;
  }
`;
export const StyledTheme = styled.div`
  width: 100px;
  height: 100px;
  // border-radius: 3px;
  // border: 1px solid black;
`;
