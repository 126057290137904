import { Button, Input, Table, message } from 'antd';
import * as XLSX from 'xlsx';
import React, { Component } from 'react';

import { StyledApplication } from './CSS/style';
import './CSS/style.css';
import { getTableListFromAPI, uploadJsonData } from './Utils/apicalls';
import { DownloadOutlined, SearchOutlined, UploadOutlined } from '@ant-design/icons';
import localStorage from '../../../../../utils/localStorage';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { getCurrentLanguage, getTranslationCache } from '../../../../../selectors/language';
import translation from './Utils/translation';
import Highlighter from 'react-highlight-words';

class InHouseRegistration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSource: [],
      translationList: {
        'Hole Number': '穴番号',
        'Gun Powder Quantity': '薬量',
        Design: '設計',
        Actual: '実績',
        'No Of Explosives with Detonators': '親ダイ',
        'No Of Explosives without Detonators': '増ダイ'
      }
    };
  }
  componentDidMount() {
    this.getTableList();
  }

  getTableList = async () => {
    let dataSource = await getTableListFromAPI();
    this.setState({ dataSource });
  };

  onFileUpload = (e) => {
    e.preventDefault();
    console.log("e", e);
    var files = e.target.files;
    var f = files[0];
    var reader = new FileReader();

    reader.onload = async (e) => {
      var dataa = e.target.result;
      let readedData = XLSX.read(dataa, { type: 'binary' });
      const wsname = readedData.SheetNames[0];
      const ws = readedData.Sheets[wsname];
      const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });
      let data =
        dataParse &&
        Array.isArray(dataParse) &&
        dataParse
          .filter((e, i) => i > 2)
          .map((data) => {
            return {
              HoleNumber: data[0],
              NoOfExplosivesWithDetonators_Design: data[1],
              NoOfExplosivesWithOutDetonators_Design: data[2],
              NoOfExplosivesWithDetonators_Actual: data[3],
              NoOfExplosivesWithOutDetonators_Actual: data[4]
            };
          });
      let saveExcel = await uploadJsonData(data);
      console.log(saveExcel);
      if (saveExcel && saveExcel.message) {
        message.success(this.translation(saveExcel.message));
        this.getTableList();
      }
    };
    reader.readAsBinaryString(f);
  };

  translation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };
  handleReset = (clearFilters, confirm) => {
    clearFilters();
    this.handleSearch([], confirm);
  };
  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={translation(this.props.translationCache, "Search")}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          {translation(this.props.translationCache, "Search")}
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters, confirm)}
          size="small"
          style={{ width: 90 }}
        >
          {translation(this.props.translationCache, "Reset")}
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined, fontSize: '130%' }} />
    ),
    onFilter: (value, record) =>
      record &&
      (record[dataIndex] || record[dataIndex] === 0) &&
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text && text.toString()}
      />
    )
  });

  render() {
    let { dataSource } = this.state;
    let columns = [
      {
        title: this.translation('Hole Number'),
        dataIndex: 'HoleNumber',
        key: 'HoleNumber',
        ...this.getColumnSearchProps('HoleNumber')
      },
      {
        title: this.translation('Gun Powder Quantity'),
        dataIndex: 'GunPowderQuantity',
        key: 'GunPowderQuantity',
        children: [
          {
            title: this.translation('Design'),
            dataIndex: 'Design',
            key: 'Design',
            children: [
              {
                title: this.translation('No Of Explosives with Detonators'),
                dataIndex: 'NoOfExplosivesWithDetonators_Design',
                key: 'NoOfExplosivesWithDetonators_Design',
                ...this.getColumnSearchProps('NoOfExplosivesWithDetonators_Design'),
                render(text) {
                  return {
                    children: (
                      <div>
                        {text !== 'null' ? text : ''}
                      </div>
                    )
                  };
                }
              },
              {
                title: this.translation('No Of Explosives without Detonators'),
                dataIndex: 'NoOfExplosivesWithOutDetonators_Design',
                key: 'NoOfExplosivesWithOutDetonators_Design',
                ...this.getColumnSearchProps('NoOfExplosivesWithOutDetonators_Design'),
                render(text) {
                  return {
                    children: (
                      <div>
                        {text !== 'null' ? text : ''}
                      </div>
                    )
                  };
                }
              }
            ]
          },
          {
            title: this.translation('Actual'),
            dataIndex: 'Actual',
            key: 'Actual',
            children: [
              {
                title: this.translation('No Of Explosives with Detonators'),
                dataIndex: 'NoOfExplosivesWithDetonators_Actual',
                key: 'NoOfExplosivesWithDetonators_Actual',
                ...this.getColumnSearchProps('NoOfExplosivesWithDetonators_Actual'),
                render(text) {
                  return {
                    props: {
                      style: { background: "#828282" }
                    },
                    children: (
                      <div>
                        {text !== 'null' ? text : ''}
                      </div>
                    )
                  };
                }
              },
              {
                title: this.translation('No Of Explosives without Detonators'),
                dataIndex: 'NoOfExplosivesWithOutDetonators_Actual',
                key: 'NoOfExplosivesWithOutDetonators_Actual',
                ...this.getColumnSearchProps('NoOfExplosivesWithOutDetonators_Actual'),
                render(text) {
                  return {
                    props: {
                      style: { background: "#828282" }
                    },
                    children: (
                      <div>
                        {text !== 'null' ? text : ''}
                      </div>
                    )
                  };
                }
              }
            ]
          }
        ]
      }
    ];
    const siteId = localStorage.get('currentSite');
    return (
      <StyledApplication style={{ minHeight: window.innerHeight - 113 }}>
        <div className="headerCSS">{this.translation('In-House Standard Registration')}</div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            <input
              type="file"
              name="uploadfile"
              id="img"
              style={{ display: "none" }}
              onChange={this.onFileUpload}
            /><UploadOutlined style={{ marginRight: "2px" }} />
            <label style={{ cursor: "pointer" }} for="img">{this.translation('Click to upload excel file')}</label></div>

          <div style={{ margin: '5px 0px', fontWeight: '18px' }}>
            <DownloadOutlined style={{ marginRight: '2px' }} />
            <a
              target={'_blank'}
              style={{ color: '#262222' }}
              href={`https://okumura.bmecomosolution.com/api/sample/Okumura.xlsx?SiteId=${siteId}`}
              rel="noreferrer"
            >
              {this.translation('Download Default Template')}
            </a>
          </div>
        </div>
        <div>
          <Table
            className="downtimeTable"
            columns={columns}
            bordered={true}
            dataSource={dataSource}
          />
        </div>
      </StyledApplication>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  language: getCurrentLanguage(),
  translationCache: getTranslationCache()
});

export default connect(mapStateToProps)(InHouseRegistration);
